import type { PaginationColor } from './types'

export const color: Record<PaginationColor, string> = {
  primary: 'pagination-button--primary',
  secondary: 'pagination-button--secondary',
  info: 'pagination-button--info',
  success: 'pagination-button--success',
  warning: 'pagination-button--warning',
  error: 'pagination-button--error',
}
