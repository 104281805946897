import { RefOrGetter } from '@lasso/shared/types'
import { MaybeRefOrGetter, computed, toValue } from 'vue'
import { compareByOrder } from '@lasso/shared/utils'

import { VirtualTableColumn, VirtualTableColumns } from './types'

export const useVirtualTableColumns = <T extends Record<string, unknown>>({
  columns: columnsConfig,
  enabledColumns,
  columnsOrder,
}: {
  columns: MaybeRefOrGetter<VirtualTableColumns<T>>
  enabledColumns?: RefOrGetter<Array<keyof T>>
  columnsOrder?: RefOrGetter<Array<keyof T>>
}) => {
  const withHidden = (field: VirtualTableColumn<T>): VirtualTableColumn<T> => ({
    ...field,
    hidden: enabledColumns ? !toValue(enabledColumns).includes(field.id) : field.hidden,
  })

  const columns = computed((): VirtualTableColumns<T> => {
    const columns = toValue(columnsConfig).map(withHidden)

    if (columnsOrder) {
      columns.sort((a, b) => compareByOrder(a.id, b.id, toValue(columnsOrder)))
    }

    return columns
  })

  return {
    columns,
  }
}
