import { SliderThumbColor, SliderThumbShape, SliderThumbSize, SliderThumbVariant } from '../types/SliderThumbTypes'

export const color: Record<SliderThumbColor, string> = {
  base: 'slider-thumb-base',
  primary: 'slider-thumb-primary',
  secondary: 'slider-thumb-secondary',
}
export const variant: Record<SliderThumbVariant, string> = {
  solid: 'slider-thumb-solid',
}

export const size: Record<SliderThumbSize, string> = {
  md: 'slider-thumb-md',
}

export const shape: Record<SliderThumbShape, string> = {
  circle: 'slider-thumb-circle',
}
