import { IconSizeType, IconVariantType } from './types'

export const size: Record<IconSizeType, string> = {
  xs: 'icon-xs', // 12 px
  sm: 'icon-sm', // 16 px default
  md: 'icon-md', // 20 px
  lg: 'icon-lg', // 24 px
}

export const variant: Record<IconVariantType, string> = {
  filled: 'material-icons',
  outlined: 'material-icons-outlined',
  round: 'material-icons-round',
}
