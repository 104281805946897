<template>
  <Tooltip :placement="tooltipPlacement" offsetDistance="10" class="grow min-w-0">
    <template #default>
      <Chip
        variant="filled"
        :disabled="option.disabled"
        class="w-full"
        :size="withValue ? 'sm' : 'xs'"
        cancelable
        @cancel="$emit('deselect')"
      >
        <span ref="titleRef" class="truncate min-w-0">
          <Chip v-if="withValue" size="3xs" color="accent" variant="outlined" mr="1">{{ option.value }}</Chip>
          {{ option.label }}
        </span>
      </Chip>
    </template>
    <template v-if="titleOverflown" #content>
      {{ option.label }}
    </template>
  </Tooltip>
</template>

<script lang="ts" setup>
import { ComponentPublicInstance, shallowRef } from 'vue'

import { useElementOverflown } from '@lasso/shared/hooks'

import Tooltip from '../Tooltip/Tooltip.vue'

import Chip from '../Chip/Chip.vue'

import type { SelectOptionType } from '../Select/types'

import { TippyPlacement } from '../Tippy/types'

withDefaults(defineProps<{
  option: SelectOptionType
  tooltipPlacement?: TippyPlacement
  withValue?: boolean
}>(), {
  tooltipPlacement: 'left',
  withValue: false,
})

defineEmits<{
  'deselect': []
}>()

const titleRef = shallowRef<ComponentPublicInstance>()
const { overflown: titleOverflown } = useElementOverflown(titleRef)
</script>
