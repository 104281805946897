import { RefOrGetter } from '@lasso/shared/types'
import { computed, reactive, ref, toValue } from 'vue'

/**
 * Unifies how tables are shown across the app and ensures that user isn't stuck on a page when it's empty
 * (i.e. 5 rows per page, 5 results, but page is 2).
 *
 * @example
 * <!-- Table is hidden when empty -->
 * <ErrorState v-if="visible.error" />
 * <EmptyState v-if="visible.empty" />
 * <Box v-else>
 *   <TablePagination v-if="visible.pagination" :disabled="loading" />
 *   <Table :loading="loading" />
 * </Box>
 *
 * @example
 * <!-- Table is shown when empty -->
 * <Error v-if="visible.error">
 * <Box v-else-if="visible.data">
 *   <Table :loading="loading" />
 *   <TablePagination v-if="visible.pagination" :disabled="loading">
 * </Box>
 */
export const useTableVisibility = ({
  pageNumber, pagesTotal, error, loading, filtersApplied = ref(false),
  paginationOnFirstPage = false,
}: {
  pageNumber: RefOrGetter<number>
  pagesTotal: RefOrGetter<number>
  error: RefOrGetter<unknown>
  loading: RefOrGetter<boolean>
  filtersApplied?: RefOrGetter<boolean>
  paginationOnFirstPage?: boolean
}) => {
  const paginationLimit = paginationOnFirstPage ? 0 : 1
  const errorVisible = computed(() => Boolean(toValue(error)))
  const paginationVisible = computed(() => (toValue(pageNumber) !== 1 || toValue(pagesTotal) > paginationLimit) && !errorVisible.value)
  const dataVisibleInternal = computed(() => paginationVisible.value || toValue(pagesTotal) > 0 || toValue(loading))
  const emptyVisible = computed(() => !dataVisibleInternal.value && !errorVisible.value && !toValue(filtersApplied))
  const dataVisible = computed(() => (dataVisibleInternal.value || emptyVisible.value) && !errorVisible.value)

  return reactive({
    pagination: paginationVisible,
    data: dataVisible,
    error: errorVisible,
    empty: emptyVisible,
  })
}
