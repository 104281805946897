<template>
  <Box grow="grow">
    <ListItemButton @click="toggleIsOpen">
      <Box flex justify="between" alignItems="center" grow="grow">
        <Box>
          <slot />
        </Box>
        <Icon icon="add" size="xs" />
      </Box>
    </ListItemButton>
    <Box v-if="state.isOpen" pl="5">
      <slot name="dropdown" />
    </Box>
  </Box>
</template>

<script lang="ts" setup>
import { reactive } from 'vue'

import ListItemButton from '../ListItemButton/ListItemButton.vue'
import Box from '../Box/Box.vue'
import Icon from '../Icon/Icon.vue'

const emits = defineEmits(['click'])

const state = reactive({
  isOpen: false,
})

const toggleIsOpen = (e: Event) => {
  emits('click', e)
  state.isOpen = !state.isOpen
}
</script>
