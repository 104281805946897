<template>
  <Box
    :component="id ? 'label' : 'div'"
    flex
    :for="id"
    :class="labelClasses"
    justify="start"
    alignItems="center"
    class="gap-1"
  >
    <Typography :variant="variant" :color="labelColor">
      <slot v-bind="{ id }" />
    </Typography>
    <Typography v-if="required" color="error" :variant="variant" component="span">
      *
    </Typography>
  </Box>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import Box from '../../Box/Box.vue'
import Typography from '../../Typography/Typography.vue'

import type { InputLabelProps } from './types'

const props = withDefaults(defineProps<InputLabelProps>(), {
  hide: false,
  disabled: false,
  required: false,
  variant: 'form-label',
})

const labelClasses = computed(() => ({
  'sr-only': props.hide,
}))

const labelColor = computed(() => {
  if (props.disabled) {
    return 'disabled'
  }

  if (props.variant === 'form-label') {
    return 'textSecondaryLight'
  }

  return undefined
})
</script>
