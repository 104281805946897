<template>
  <InputText
    :modelValue="time"
    :disabled="disabled"
    type="time"
    width="122px"
    icon="schedule"
    class="input-time"
    pattern="[0-9]{2}:[0-9]{2}"
    @update:modelValue="onInput"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useVModel } from '@vueuse/core'

import { InputText } from '../../Input'

const props = defineProps<{
  dataTestId?: string
  modelValue: { hour: string; minute: string }
  disabled?: boolean
}>()
const emits = defineEmits<{
  'update:modelValue': [{ hour: number; minute: number }]
}>()

const timeInternal = useVModel(props, 'modelValue', emits)
const time = computed(() => `${timeInternal.value.hour}:${timeInternal.value.minute}`)

const onInput = (value: string) => {
  const [hour = '', minute = ''] = value.split(':')
  timeInternal.value = { hour, minute }
}
</script>

<style scoped src="./inputtimefield.styles.css" />
