<template>
  <Tooltip placement="top" class="min-w-0 max-w-full">
    <Chip
      size="3xs"
      class="max-w-full"
      :cancelable="!readonly"
      :disabled="disabled"
      @cancel="emit('delete')"
      @click.stop
    >
      <span ref="labelRef" class="truncate min-w-0">
        {{ value }}
      </span>
    </Chip>
    <template v-if="titleOverflown" #content>
      {{ value }}
    </template>
  </Tooltip>
</template>

<script setup lang="ts">
import { ComponentPublicInstance, shallowRef } from 'vue'
import { useElementOverflown } from '@lasso/shared/hooks'

import Tooltip from '../Tooltip/Tooltip.vue'
import { Chip } from '../Chip'

defineProps<{
  readonly: boolean
  disabled: boolean
  value: string
}>()

const emit = defineEmits<{
  delete: []
}>()

const labelRef = shallowRef<ComponentPublicInstance>()
const { overflown: titleOverflown } = useElementOverflown(labelRef)
</script>
