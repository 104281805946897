import type { ButtonColor, ButtonShape, ButtonSize, ButtonVariant } from './types'

export const color: Record<ButtonColor, string> = {
  default: '',
  primary: 'button-primary',
  secondary: 'button-secondary',
  info: 'button-info',
  success: 'button-success',
  warning: 'button-warning',
  error: 'button-error',
}

export const variant: Record<ButtonVariant, string> = {
  default: 'button-default',
  outlined: 'button-outlined',
  text: 'button-text',
  ghost: 'button-ghost',
}

export const size: Record<ButtonSize, string> = {
  xs: 'button-xs', // 24px
  sm: 'button-sm', // 28px
  md: 'button-md', // 32px // default
  lg: 'button-lg', // 36px
}

export const shape: Record<ButtonShape, string> = {
  none: '', // rounded by default
  square: 'button-square', // no radius
  pill: 'button-pill', // fully rounded
}
