<template>
  <button v-if="type === 'button'" :disabled="disabled" @click="handleLinkClick">
    <slot />
  </button>
  <a v-else :href="toInternal" @click="handleLinkClick">
    <slot />
  </a>
</template>

<script lang="ts" setup>
import { computed, useAttrs } from 'vue'

import { navigateToAppUrl, urlIsAbsolute, urlJoin } from '@lasso/shared/utils'

import type { LinkBaseProps } from './types'

const props = withDefaults(defineProps<LinkBaseProps>(), {
  to: '',
  type: 'link',
  disabled: false,
})

const emits = defineEmits(['click'])

const attrs = useAttrs()

const handleClick = async (e: MouseEvent) => {
  emits('click', e)
}

const toInternal = computed(() => {
  return urlIsAbsolute(props.to) ? props.to : urlJoin(location.origin, process.env.BASE_URL!, props.to)
})

const handleLinkClick = async (e: MouseEvent) => {
  e.preventDefault()
  if (props.disabled) {
    return
  }
  await handleClick(e)
  if (props.to && props.type === 'link') {
    if (attrs.target === '_blank') {
      window.open(props.to, '_blank')
      return
    }
    navigateToAppUrl(props.to)
  }
}
</script>
