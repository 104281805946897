<template>
  <li class="progress-step">
    <Icon
      :icon="stepIcon"
      size="md"
      variant="outlined"
      :color="iconColor"
      class="progress-step__icon"
    />
    <Divider
      :color="dividerColor"
      :direction="stepsListState.direction"
      class="progress-step__divider"
    />
    <div
      v-if="!stepsListState.hideLabel"
      class="progress-step__label"
    >
      <slot>
        <Typography variant="caption1" color="textSecondary">
          {{ label }}
        </Typography>
      </slot>
    </div>
  </li>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'
import { computed, inject } from 'vue'

import Icon from '../Icon/Icon.vue'
import Divider from '../Divider/Divider.vue'
import Typography from '../Typography/Typography.vue'

import type { ProgressStepsListProps } from '../ProgressStepsList/types'

import type { ProgressStepItemProps, ProgressStepItemStatus } from './types'

const props: ProgressStepItemProps = defineProps({
  status: {
    type: String as PropType<ProgressStepItemStatus>,
    default: 'pending',
    validator: (status: ProgressStepItemStatus) => ['active', 'error', 'completed', 'pending'].includes(status),
  },
  tooltip: {
    type: String,
    default: null,
  },
  label: {
    type: String,
    default: '',
  },
})

const stepsListState = inject<ProgressStepsListProps>('StepsListState', {})

const stepIcon = computed(() => {
  const statusToIcon: Record<ProgressStepItemStatus, string> = {
    active: 'radio_button_checked',
    completed: 'check_circle',
    error: 'error_outline',
    pending: 'radio_button_unchecked',
  }

  return statusToIcon[props.status!]
})

const iconColor = computed(() => {
  if (props.status === 'error') {
    return 'error'
  }

  if (props.status === 'pending') {
    return 'textSecondaryLight'
  }

  return stepsListState.color
})

const dividerColor = computed(() => props.status !== 'completed' ? undefined : stepsListState.color)
</script>
