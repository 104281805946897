<template>
  <div class="dropdown w-full" :class="containerClasses">
    <label tabindex="0">
      <slot :actions="actions" :isOpen="isOpen" />
    </label>
    <div ref="dropdown" tabindex="0" class="dropdown-content" :class="dropdownClasses">
      <slot name="dropdown" :actions="actions" :isOpen="isOpen" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'
import { computed, ref, watch } from 'vue'

import { checkForUndefined } from '../../utils'

import type { DropdownPosition, DropdownProps } from './types'
import { positions } from './classes'

const props: DropdownProps = defineProps({
  position: {
    type: String as PropType<DropdownPosition>,
    default: 'end',
    validator: (position: DropdownPosition) => !!positions[position],
  },
  hover: {
    type: Boolean,
    default: false,
  },
  rounded: {
    type: Boolean,
    default: false,
  },
})

const dropdown = ref(null)
const isOpen = ref(false)

const containerClasses = computed(() => {
  const position = checkForUndefined(props.position, positions)

  return {
    [position]: !!props.position,
    'dropdown-hover': props.hover, // open on dropdown on hover
    // 'dropdown-open': isOpen.value, // force open dropdown
  }
})

const dropdownClasses = computed(() => {
  const rounded = props.rounded ? 'rounded-full' : 'rounded'

  return {
    [rounded]: true,
  }
})

watch(dropdown, (newDropdown) => {
  if (newDropdown) {
    const element: HTMLElement = newDropdown
    if (element && element.classList.contains('dropdown-open')) {
      isOpen.value = true
    }
    else {
      isOpen.value = false
    }
  }
})

// TODO: find a way to detect dropdown state
const handleDropdown = (action: 'toggle' | 'open' | 'close') => {
  if (dropdown.value) {
    const element: HTMLElement = dropdown.value
    if (action === 'toggle') {
      element.classList.toggle('dropdown-open')
    }
    if (action === 'open') {
      element.classList.add('dropdown-open')
    }
    if (action === 'close') {
      element.classList.remove('dropdown-open')
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur()
      }
    }
  }
}

const actions = {
  toggle: () => handleDropdown('toggle'),
  open: () => handleDropdown('open'),
  close: () => handleDropdown('close'),
}
</script>
