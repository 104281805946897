<template>
  <div class="logo">
    <img v-if="src" :src="src" :alt="alt" :class="logoClasses">
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { checkForUndefined } from '../../utils'

import type { LogoPropsType } from './types'
import { sizes } from './classes'

const props = withDefaults(defineProps<LogoPropsType>(), {
  size: 'sm',
  src: '',
  alt: '',
})

const logoClasses = computed(() => {
  const size = checkForUndefined(props.size, sizes)

  return {
    [size]: props.size,
  }
})
</script>

<style src="./logo.styles.css" scoped />
