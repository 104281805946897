<template>
  <slot v-if="isLoading" name="loading">
    Loading...
  </slot>
  <slot v-else-if="!isLoading && hasError" name="error">
    Oops something went wrong...
  </slot>
  <slot v-else />
</template>

<script lang="ts" setup>
import { provide } from 'vue'

import type { ViewSwitchPropTypes } from './types'

const props: ViewSwitchPropTypes = defineProps({
  active: {
    type: [String, Number],
    default: null,
  },
  hasError: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
})

provide('viewSwitchProps', props)
</script>
