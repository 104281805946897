export const variant = {
  text: 'tab-text',
  outlined: 'tab-outlined',
}

export const size = {
  md: 'tab-md',
  lg: 'tab-lg',
}

export const color = {
  default: 'tab-default',
  primary: 'tab-primary',
  error: 'tab-error',
}
