<template>
  <Tippy
    ref="tippyRef"
    :trigger="['mouseenter', 'focus']"
    :class="tooltipClasses"
    :disabled="!hasContent()"
    :style="styles"
    :placement="placement"
    :offsetDistance="offsetDistance"
    px="2"
    py="2"
    arrow
    :tag="tag ?? (inline ? 'span' : 'div')"
  >
    <template #default="scope">
      <slot v-bind="scope">
        <Box :component="inline ? 'span' : 'div'">
          <Icon
            v-if="withIcon"
            icon="info"
            :size="iconSize"
            :color="iconColor"
            variant="outlined"
          />
        </Box>
      </slot>
    </template>
    <template #content="scope">
      <Box flex>
        <Typography variant="tooltip1" color="textPrimary" wordBreak="anywhere">
          <slot name="content" v-bind="scope">
            {{ content }}
          </slot>
        </Typography>
      </Box>
    </template>
  </Tippy>
</template>

<script lang="ts" setup>
import { computed, ref, shallowRef, useSlots } from 'vue'
import type { PropType } from 'vue'

import { ComponentExposed } from '@lasso/shared/types'

import { whenever } from '@vueuse/core'

import type { IconSizeType } from '../Icon/types'
import type { TypographyColor } from '../Typography/types'

import Tippy from '../Tippy/Tippy.vue'
import Icon from '../Icon/Icon.vue'
import Box from '../Box/Box.vue'
import Typography from '../Typography/Typography.vue'

import { size as iconSizes } from '../Icon/classes'
import { color as iconColors } from '../Typography/classes'

import { type TippyInstance, TippyPlacement } from '../Tippy/types'

import type { TooltipProps } from './types'

const props: TooltipProps = defineProps({
  iconColor: {
    type: String as PropType<TypographyColor>,
    default: 'textSecondary',
    validator: (color: TypographyColor) => !!iconColors[color],
  },
  iconSize: {
    type: String as PropType<IconSizeType>,
    default: 'xs',
    validator: (size: IconSizeType) => !!iconSizes[size],
  },
  content: {
    type: String,
    default: '',
  },
  withCursor: {
    type: Boolean,
    default: false,
  },
  withIcon: {
    type: Boolean,
    default: false,
  },
  minWidth: {
    type: String,
    default: undefined,
  },
  placement: {
    type: String as PropType<TippyPlacement>,
  },
  inline: {
    type: Boolean,
    default: false,
  },
  tag: {
    type: String,
    default: undefined,
  },
  offsetDistance: {
    type: [Number, String],
    default: 6,
  },
})

const slots = useSlots()
const hasContent = () => Boolean(props.content || slots.content)
const tooltipClasses = computed(() => ({
  'cursor-help': props.withCursor,
}))

const styles = computed(() => {
  return {
    minWidth: props.minWidth,
  }
})

const tippyRef = shallowRef<ComponentExposed<typeof Tippy>>()
const tippyInstance = ref<TippyInstance | null>(null)

whenever(tippyRef, (tippyRef) => {
  tippyInstance.value = tippyRef.tippyInstance
})

defineExpose({
  tippyInstance,
})
</script>
