<template>
  <Dropdown :position="position" :hover="hover">
    <InputText
      v-bind="$attrs"
      :name="name"
      :modelValue="modelValue"
      :dataTestId="dataTestId"
      :variant="variant"
      :size="size"
      autocomplete="off"
      :placeholder="placeholder"
      @input="handleInput"
    />
    <template #dropdown="scope">
      <Paper
        :data-test-id="`${name}-dropdown`"
        class="overflow-y-scroll"
        disablePadding
        :maxHeight="maxHeight"
        :width="width"
        shadow="lg"
      >
        <slot name="dropdown" v-bind="scope" />
      </Paper>
    </template>
  </Dropdown>
</template>

<script lang="ts" setup>
import Paper from '../Paper/Paper.vue'
import type { DropdownPosition } from '../Dropdown/types'

import Dropdown from '../Dropdown/Dropdown.vue'

import type * as InputWrapperType from '../Input/InputWrapper/types'
import InputText from '../Input/InputText/InputText.vue'
import { InputWrapperVariant } from '../Input/InputWrapper/types'

withDefaults(defineProps<{
  name: string
  position?: DropdownPosition
  variant?: InputWrapperVariant
  size?: InputWrapperType.InputWrapperSize
  modelValue: string | number
  hover?: boolean
  maxHeight?: string
  width?: string
  placeholder?: string
  dataTestId?: string
}>(), {
  position: 'end',
  variant: 'default',
  size: 'lg',
  hover: false,
  placeholder: '',
})

const emits = defineEmits<{
  'update:modelValue': [string | number]
  input: [e: Event]
}>()

defineOptions({
  inheritAttrs: false,
})

const handleInput = (e: any) => {
  emits('update:modelValue', e?.target.value)
  emits('input', e)
}
</script>
