<template>
  <Box flex alignItems="center" class="input-wrapper" :class="inputWrapperClasses">
    <slot />
  </Box>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import Box from '../../Box/Box.vue'
import { checkForUndefined } from '../../../utils'

import { InputWrapperProps } from './types'
import * as classes from './classes'

const props = withDefaults(defineProps<InputWrapperProps>(), {
  disabled: false,
  focused: false,
  error: false,
  withValue: false,
  variant: 'default',
  size: 'lg',
})

const inputWrapperClasses = computed(() => {
  const variant = checkForUndefined(props.variant, classes.variant)
  const size = checkForUndefined(props.size, classes.size)
  const { disabled, focused, error, withValue } = props

  return {
    [variant]: !!props.variant,
    [size]: !!props.size,
    'disabled': disabled,
    'focused': focused,
    'error': error,
    'with-value': withValue,
  }
})
</script>

<style src="./inputWrapper.styles.css" scoped></style>
