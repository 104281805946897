import type { IndicatorPosition } from './types'

export const position: Record<IndicatorPosition, string> = {
  'top-start': 'indicator-top indicator-start',
  'top-center': 'indicator-top indicator-center',
  'top-end': 'indicator-top indicator-end',
  'middle-start': 'indicator-middle indicator-start',
  'middle-center': 'indicator-middle indicator-center',
  'middle-end': 'indicator-middle indicator-end',
  'bottom-start': 'indicator-bottom indicator-start',
  'bottom-center': 'indicator-bottom indicator-center',
  'bottom-end': 'indicator-bottom indicator-end',
}
