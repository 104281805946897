// https://stackoverflow.com/a/3969760
export default class Timer {
  startedAt: number
  delay: number
  callback: () => void
  timer: number

  constructor(callback: () => void, delay: number) {
    this.startedAt = Date.now()
    this.callback = callback
    this.delay = delay

    this.timer = window.setTimeout(callback, delay)
  }

  pause() {
    this.stop()
    this.delay -= Date.now() - this.startedAt
  }

  resume() {
    this.stop()
    this.startedAt = Date.now()
    this.timer = window.setTimeout(this.callback, this.delay)
  }

  stop() {
    window.clearTimeout(this.timer)
  }
}
