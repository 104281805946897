<template>
  <div
    :class="s.row"
    :style="{ height: `${rowHeight}px` }"
    @mouseenter="toggleHovered(true)"
    @mouseleave="toggleHovered(false)"
  >
    <VirtualTableCell
      v-for="(column, columnIndex) in columns"
      :key="column.id"
      v-bind="getCellProps(column, columnIndex)"
      v-model:selected="selectedInternal"
    />
  </div>
</template>

<script setup lang="ts" generic="T extends Record<string, unknown>, RowKey extends KeyOf<T>">
import { useToggle, useVModel } from '@vueuse/core'
import { Ref, useCssModule } from 'vue'
import { ComponentProps, KeyOf } from '@lasso/shared/types'

import { VirtualTableColumn, VirtualTableColumnWidths } from './types'
import VirtualTableCell from './VirtualTableCell.vue'

const props = defineProps<{
  row: T
  rowKey: RowKey
  rowIndex: number
  rowHeight: number
  columns: Array<Required<VirtualTableColumn<T>>>
  columnWidths: VirtualTableColumnWidths<T>
  sticky: number
  loading: boolean
  checkbox: boolean
  checkboxDisabled: boolean
  selected: Array<T[RowKey]>
  scrollX: number
}>()

const emit = defineEmits<{
  'update:selected': [Array<T[RowKey]>]
}>()

const selectedInternal = useVModel(props, 'selected', emit) as Ref<Array<T[RowKey]>>

const s = useCssModule()

const [hovered, toggleHovered] = useToggle()

const getCellProps = (
  column: Required<VirtualTableColumn<T>>,
  cellIndex: number,
): Omit<ComponentProps<typeof VirtualTableCell<T, RowKey>>, 'selected'> => {
  return {
    row: props.row,
    rowKeyValue: props.row[props.rowKey],
    rowIndex: props.rowIndex,
    rowHeight: props.rowHeight,
    columns: props.columns,
    column,
    columnIndex: cellIndex,
    columnWidths: props.columnWidths,
    sticky: props.sticky,
    loading: props.loading,
    checkbox: props.checkbox,
    checkboxDisabled: props.checkboxDisabled,
    scrollX: props.scrollX > 0,
    hovered: hovered.value,
  }
}
</script>

<style module>
.row {
  display: flex;
  flex-direction: row;
  min-width: 0;
}
</style>
