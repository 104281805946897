<template>
  <Tippy
    ref="tippyRef"
    trigger="manual"
    :hideOnClick="false"
    @show="handleShow"
    @hidden="handleHide"
    @clickOutside="handleClickOutside"
  >
    <Box flex :wrap="wrap" alignItems="start" :spaceX="spaceX" :spaceY="spaceY">
      <FormControl :id="startDateId" v-slot="{ id }" :label="startLabel" :errorText="startError" width="auto" maxWidth="256px" :required="startRequired">
        <Box flex alignItems="center">
          <InputDateField
            :id="id"
            v-model="startDate"
            :class="{ 'left-input': displayTime }"
            :dateFormat="dateFormat"
            :placeholder="startPlaceholder"
            disableValidation
            :disabled="startDateDisabled"
            @focus="handleFocus('start')"
            @blur="handleBlur($event, 'start')"
          />
          <Tooltip :content="timeTooltip">
            <InputTimeField
              v-if="displayTime"
              v-model="startTime"
              :class="{ 'right-input': displayTime }"
              placeholder="12:00 AM"
              :disabled="startTimeDisabled || startDateDisabled"
              disableValidation
            />
          </Tooltip>
        </Box>
      </FormControl>

      <FormControl v-if="displayEndDate" :id="endDateId" v-slot="{ id }" :label="endLabel" :errorText="endError" width="auto" maxWidth="256px" :required="endRequired">
        <Box flex alignItems="center">
          <InputDateField
            :id="id"
            v-model="endDate"
            :class="{ 'left-input': displayTime }"
            :dateFormat="dateFormat"
            :placeholder="endPlaceholder"
            disableValidation
            :disabled="endDateDisabled"
            @focus="handleFocus('end')"
            @blur="handleBlur($event, 'end')"
          />
          <Tooltip :content="timeTooltip">
            <InputTimeField
              v-if="displayTime"
              v-model="endTime"
              :class="{ 'right-input': displayTime }"
              placeholder="11:59 PM"
              :disabled="endTimeDisabled || endDateDisabled"
              disableValidation
            />
          </Tooltip>
        </Box>
      </FormControl>
    </Box>

    <template #content>
      <DatePicker
        v-if="isShown"
        v-bind="$attrs"
        noPadding
        :dateFormat="dateFormat"
        mode="period"
        :min="min"
        :max="max"
        :disabled="startDateDisabled && endDateDisabled"
        :disabledPeriods="disabledPeriods"
        :period="datePeriod"
        :periodChooseDate="periodChooseDate"
        :initialMonth="initialMonth"
        @periodChoose="handlePeriodSelect"
      />
    </template>
  </Tippy>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { DateTime } from 'luxon'
import { useVModel } from '@vueuse/core'

import Box from '../../Box/Box.vue'
import { BoxSpacing, BoxWrap } from '../../Box/types'

import FormControl from '../../FormControl/FormControl.vue'
import Tooltip from '../../Tooltip/Tooltip.vue'
import Tippy from '../../Tippy/Tippy.vue'

import DatePicker from '../../DatePicker/DatePicker.vue'
import { DateFormatTypes, DisabledPeriod, PeriodDate } from '../../DatePicker/types'

import InputDateField from '../InputDateField/InputDateField.vue'
import InputTimeField from '../InputTimeField/InputTimeField.vue'

import { getHour, getMinute, isEstDateBeforeToday, isInRange } from '../utils'

const props = withDefaults(defineProps<{
  dataTestId?: string
  startDateId?: string
  endDateId?: string
  modelValue: { start: DateTime | null; end: DateTime | null }
  displayTime?: boolean
  displayEndDate?: boolean
  startDateDisabled?: boolean
  endDateDisabled?: boolean
  startTimeDisabled?: boolean
  endTimeDisabled?: boolean
  startLabel?: string
  endLabel?: string
  startPlaceholder?: string
  endPlaceholder?: string
  locale?: string
  dateFormat?: DateFormatTypes
  min?: DateTime | undefined
  max?: DateTime | undefined
  disabledPeriods?: DisabledPeriod[]
  startError?: string
  endError?: string
  timeTooltip?: string
  startRequired?: boolean
  endRequired?: boolean
  spaceX?: BoxSpacing
  spaceY?: BoxSpacing
  wrap?: BoxWrap
}>(), {
  displayTime: false,
  displayEndDate: true,
  startDateDisabled: false,
  endDateDisabled: false,
  startTimeDisabled: false,
  endTimeDisabled: false,
  startLabel: '',
  endLabel: '',
  startPlaceholder: '',
  endPlaceholder: '',
  locale: 'en-US',
  dateFormat: 'MM/dd/yyyy',
  disabledPeriods: () => [],
  startError: '',
  endError: '',
  timeTooltip: '',
  startDateId: 'startDate',
  endDateId: 'endDate',
  startRequired: false,
  endRequired: false,
  spaceX: '6',
  spaceY: '6',
  wrap: 'wrap',
})

const emits = defineEmits<{
  'update:modelValue': [{ start: DateTime | null; end: DateTime | null }]
  show: []
  hide: []
}>()

defineOptions({
  inheritAttrs: false,
})

const isShown = ref(false)
const modelValueInternal = useVModel(props, 'modelValue', emits)
const periodChooseDate = ref<PeriodDate>('start')
const tippyRef = ref<InstanceType<typeof Tippy> | null>(null)
const initialMonth = computed(() => {
  return periodChooseDate.value === 'start' ? modelValueInternal.value?.start : modelValueInternal.value?.end
})

const datePeriod = computed(() => {
  const { start, end } = modelValueInternal.value
  if (start && end) {
    return [start, end]
  }
  else if (start) {
    return [start]
  }
  else {
    return undefined
  }
})

// update only date, keep time the same
const setDate = (oldDate: DateTime, newDate: DateTime | null) => {
  if (!newDate) {
    return oldDate
  }

  const { day, month, year } = newDate
  return oldDate.set({ day, month, year })
}

const handlePeriodSelect = (newDate: DateTime[]) => {
  let { start: newStartDate, end: newEndDate } = modelValueInternal.value

  if (newStartDate) {
    newStartDate = setDate(newStartDate, newDate[0]!)
  }
  else {
    newStartDate = newDate[0]!
  }

  if (periodChooseDate.value === 'start' && isEstDateBeforeToday(newStartDate)) {
    // If we choose start date in the past, then set time to 00:00
    newStartDate = newStartDate.set({ hour: 0, minute: 0, millisecond: 0 })
  }

  if (newEndDate) {
    newEndDate = setDate(newEndDate, newDate[1]!)
  }
  else {
    newEndDate = newDate[1]!
    periodChooseDate.value = 'end'
  }

  modelValueInternal.value = { start: newStartDate, end: newEndDate }
}

const handleDateInput = (newDateString: string, oldDateTime: DateTime | null) => {
  let myOldDate = oldDateTime
  if (!newDateString) {
    return myOldDate
  }

  const formattedDay = DateTime.fromFormat(newDateString, props.dateFormat, { locale: props.locale })
  if (!myOldDate) {
    myOldDate = formattedDay
  }

  if (formattedDay.isValid) {
    if (isInRange(formattedDay, props.min, props.max)) {
      const newDate = setDate(myOldDate, formattedDay)
      return newDate
    }
  }

  return myOldDate
}

const handleBlur = (blurEvent: Event, position: 'start' | 'end') => {
  const inputValue = (blurEvent.target as HTMLInputElement).value
  if (inputValue === '' || inputValue.length < props.dateFormat.length) {
    if (position === 'start') {
      modelValueInternal.value = { start: null, end: modelValueInternal.value.end }
    }
    else {
      modelValueInternal.value = { start: modelValueInternal.value.start, end: null }
    }
  }
}

const startDate = computed({
  get: () => {
    return modelValueInternal.value.start?.toFormat(props.dateFormat) || ''
  },
  set: (newDate: string) => {
    let { start } = modelValueInternal.value
    start = handleDateInput(newDate, start)
    modelValueInternal.value = { start, end: modelValueInternal.value.end }
  },
})

const endDate = computed({
  get: () => {
    return modelValueInternal.value.end?.toFormat(props.dateFormat) || ''
  },
  set: (newDate: string) => {
    let { end } = modelValueInternal.value
    end = handleDateInput(newDate, end)
    modelValueInternal.value = { start: modelValueInternal.value.start, end }
  },
})

const startTime = computed({
  get: () => {
    return { hour: getHour(modelValueInternal.value.start), minute: getMinute(modelValueInternal.value.start) }
  },
  set: ({ hour, minute }) => {
    if (!hour || !minute) {
      return
    }

    const start = (modelValueInternal.value.start ?? DateTime.local()).set({ hour: parseInt(hour), minute: parseInt(minute) })
    modelValueInternal.value = { start, end: modelValueInternal.value.end }
  },
})

const endTime = computed({
  get: () => {
    return { hour: getHour(modelValueInternal.value.end), minute: getMinute(modelValueInternal.value.end) }
  },
  set: ({ hour, minute }) => {
    if (!hour || !minute) {
      return
    }

    const end = (modelValueInternal.value.start ?? DateTime.local()).set({ hour: parseInt(hour), minute: parseInt(minute) })
    modelValueInternal.value = { start: modelValueInternal.value.start, end }
  },
})

const handleShow = () => {
  emits('show')
  isShown.value = true
}
const handleHide = () => {
  emits('hide')
  isShown.value = false
}
const handleFocus = (type: PeriodDate) => {
  periodChooseDate.value = type
  tippyRef.value?.tippyInstance?.show()
}

const handleClickOutside = () => {
  tippyRef.value?.tippyInstance?.hide()
}
</script>

<style scoped src="./inputdaterange.styles.css" />
