export const colors = {
  default: 'alert-base',
  info: 'alert-info',
  success: 'alert-success',
  warning: 'alert-warning',
  error: 'alert-error',
}

export const icons = {
  info: 'info_outline',
  success: 'check_circle_outline',
  warning: 'warning_amber',
  error: 'info_outline',
}
