<template>
  <tr :class="{ hover: hoverable, active: selected }" @mouseover="onMouseOver()" @mouseleave="onMouseLeave()">
    <TableCell
      v-for="(column, cellIndex) in tableColumns"
      :key="cellIndex"
      :row="row as any"
      :rowIndex="index"
      :column="column"
      :cellIndex="cellIndex"
      :sticky="isCellSticky(cellIndex)"
      :stickyPosition="getCellStickyPosition(cellIndex)"
      :hovered="hovered"
      :loading="loading"
      :active="active"
      :checkbox="checkbox"
      :checkboxDisabled="checkboxDisabled"
      :selected="selected"
      :align="checkForUndefined(column.align, aligns)"
      :skeleton="skeleton"
      :isTotal="isTotal"
      :variant="variant"
      :isFirstInRow="cellIndex === 0"
      :isLastInRow="cellIndex === tableColumns.length - 1"
      :countValue="countValue"
      @click="emit('click')"
      @select="emit('select')"
    >
      <template v-if="$slots[column.id]" #[column.id]="slotProps">
        <slot :name="column.id" v-bind="slotProps" />
      </template>
      <template v-if="$slots[`hovered_${column.id}`]" #[`hovered_${column.id}`]="slotProps">
        <slot :name="`hovered_${column.id}`" v-bind="slotProps" />
      </template>
    </TableCell>
  </tr>
</template>

<script setup lang="ts" generic="T extends Record<string, unknown>">
import { computed, ref } from 'vue'

import type { TableBaseVariant } from '../TableBase'

import { checkForUndefined } from '../../utils'

import { TableColumns, TableSkeleton } from './types'
import { aligns } from './classes'
import TableCell from './TableCell.vue'

const props = withDefaults(defineProps<{
  row: Partial<T>
  index: number
  tableColumns: TableColumns<T>
  hoverable: boolean
  active?: boolean
  stickyFirstColumn: boolean
  stickyFirstColumns?: number
  columnsOffsetWidths: number[]
  checkbox?: boolean
  checkboxDisabled?: boolean
  selected?: boolean
  selectedTableRows?: string[]
  skeleton: TableSkeleton
  loading: boolean
  isTotal?: boolean
  idKey: string
  variant: TableBaseVariant
  countValue?: number
}>(), {
  active: false,
  selected: false,
  checkbox: false,
  checkboxDisabled: false,
  isTotal: false,
  selectedTableRows: () => [],
})

const emit = defineEmits<{
  click: []
  select: []
  'row:mouseOver': []
  'row:mouseLeave': []
}>()

const hovered = ref(false)
const selected = computed(() => Boolean(props.row.id && props.selectedTableRows.includes(String(props.row[props.idKey]))))

const isCellSticky = (cellIndex: number) => {
  return (props.stickyFirstColumn && cellIndex === 0)
    || (typeof props.stickyFirstColumns === 'number' && cellIndex < props.stickyFirstColumns)
}

const getCellStickyPosition = (cellIndex: number) => {
  return props.columnsOffsetWidths.slice(0, cellIndex).reduce((acc, width) => acc + width, 0)
}

const onMouseOver = () => {
  hovered.value = true
  emit('row:mouseOver')
}

const onMouseLeave = () => {
  hovered.value = false
  emit('row:mouseLeave')
}
</script>
