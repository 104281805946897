import { KeyOf, RefOrGetter } from '@lasso/shared/types'
import { Ref, toValue } from 'vue'

import { VirtualTableColumn, VirtualTableSorting } from './types'
import { virtualTableSortDirections } from './consts'

export const useColumnSorting = <T extends Record<string, unknown>>({ sorting, defaultSorting }: {
  sorting: Ref<VirtualTableSorting<KeyOf<T>> | undefined>
  defaultSorting: RefOrGetter<VirtualTableSorting<KeyOf<T>> | undefined>
}) => {
  const getIsSorted = (column: Required<VirtualTableColumn<T>>) => {
    return column.id === sorting.value?.sortColumn && sorting.value?.sortDirection !== 'none'
  }

  const getSortingIconName = (column: Required<VirtualTableColumn<T>>) => {
    if (!sorting.value || column.id !== sorting.value.sortColumn || sorting.value.sortDirection === 'none') {
      return 'unfold_more'
    }

    return sorting.value.sortDirection === 'asc'
      ? 'arrow_upward'
      : 'arrow_downward'
  }

  const getNewSorting = (column: Required<VirtualTableColumn<T>>): VirtualTableSorting<KeyOf<T>> => {
    const currentSorting = (sorting.value && column.id === sorting.value.sortColumn)
      ? sorting.value?.sortDirection
      : 'none'
    const _defaultSorting = toValue(defaultSorting)

    let nextSortingIndex = virtualTableSortDirections.indexOf(currentSorting) + 1

    if (nextSortingIndex >= virtualTableSortDirections.length) {
      nextSortingIndex = 0
    }

    const nextSorting = virtualTableSortDirections[nextSortingIndex]!

    if (_defaultSorting && column.id === _defaultSorting.sortColumn) {
      if (nextSorting === 'none' && _defaultSorting.sortDirection === 'none') {
        return _defaultSorting
      }
      else if (nextSorting === 'none') {
        return {
          sortColumn: column.id,
          sortDirection: virtualTableSortDirections[nextSortingIndex + 1] ?? 'none',
        }
      }
      else {
        return {
          sortColumn: column.id,
          sortDirection: nextSorting,
        }
      }
    }
    else if (nextSorting === 'none' && _defaultSorting) {
      return _defaultSorting
    }

    return {
      sortColumn: column.id,
      sortDirection: nextSorting,
    }
  }

  const onSort = (column: Required<VirtualTableColumn<T>>) => {
    if (column.sortable) {
      sorting.value = getNewSorting(column)
    }
  }

  return {
    getIsSorted,
    getSortingIconName,
    getNewSorting,
    onSort,
  }
}
