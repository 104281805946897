<template>
  <Typography variant="caption1">
    {{ range }} of {{ total }}
  </Typography>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import Typography from '../Typography/Typography.vue'

const props = withDefaults(defineProps<{
  pageNumber?: number
  pageSize: number
  total: number
  noRangeStart?: boolean
}>(), {
  pageNumber: 1,
  noRangeStart: false,
})

const rangeStart = computed(() => (props.pageNumber - 1) * props.pageSize + 1)
const rangeEnd = computed(() => Math.min(props.pageNumber * props.pageSize, props.total))
const range = computed(() => {
  return props.noRangeStart
    ? String(rangeEnd.value)
    : `${rangeStart.value}-${rangeEnd.value}`
})
</script>
