<template>
  <div :class="s.foot" :style="{ height: `${rowHeight}px` }">
    <div
      v-for="(column, columnIndex) in columns"
      :key="column.id"
      :class="[s.footCell, {
        [s.sticky!]: isColumnSticky(columnIndex),
        [s.lastSticky!]: isLastStickyColumn(columnIndex),
        [s.lastStickyDivider!]: isLastStickyColumn(columnIndex) && lastStickyDivider,
      }]"
      :style="{
        minWidth: column.width,
        width: `${getColumnWidth(column, columnIndex)}px`,
        left: getColumnStickyOffset(columnIndex),
      }"
    >
      <Skeleton v-if="loading" height="1rem" />
      <div v-else :class="s.footCellInner" class="typography-body2 text-textPrimary">
        <slot :name="column.id" v-bind="getColumnSlotProps(column, columnIndex)">
          <Typography display="block" variant="inherit" whiteSpace="nowrap" color="inherit" truncate>
            {{ getColumnTotal(column) }}
          </Typography>
        </slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts" generic="T extends Record<string, unknown>">
import { computed, useCssModule } from 'vue'

import { KeyOf } from '@lasso/shared/types'

import { Typography } from '../Typography'

import { Skeleton } from '../Skeleton'

import { VirtualTableColumn, VirtualTableColumnSlotProps, VirtualTableColumnWidths } from './types'
import { useColumnsProps } from './useColumnsProps'

const props = defineProps<{
  totals: Partial<T>
  rowHeight: number
  columns: Array<Required<VirtualTableColumn<T>>>
  columnWidths: VirtualTableColumnWidths<T>
  sticky: number
  loading: boolean
  scrollX: boolean
  checkbox: boolean
}>()

const s = useCssModule()

const {
  getColumnWidth,
  isColumnSticky,
  getColumnStickyOffset,
} = useColumnsProps({
  columns: () => props.columns,
  columnWidths: () => props.columnWidths,
  sticky: () => props.sticky,
  checkbox: () => props.checkbox,
})

const getColumnTotal = (column: Required<VirtualTableColumn<T>>): string => {
  const value = props.totals[column.id]

  if (value === undefined) {
    return ''
  }

  return column.modifier(value as Exclude<T[KeyOf<T>], undefined>, props.totals as T, true)
}

const getColumnSlotProps = (
  column: Required<VirtualTableColumn<T>>,
  columnIndex: number,
): VirtualTableColumnSlotProps<T> => {
  return {
    rowIndex: 0,
    row: props.totals as T,
    column,
    columnIndex,
    value: getColumnTotal(column),
    rawValue: props.totals[column.id]!,
    hovered: false,
  }
}

const lastStickyDivider = computed(() => props.sticky && props.scrollX)
const isLastStickyColumn = (columnIndex: number) => props.sticky && columnIndex === props.sticky - 1
</script>

<style module>
.foot {
  display: flex;
  bottom: 0;
  position: absolute;
}

.footCell {
  @apply px-4 bg-base-100 border-t border-base-400;
  white-space: nowrap;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  min-width: 0;
  align-items: center;
  box-sizing: border-box;
}

.footCellInner {
  overflow: hidden;
  text-overflow: ellipsis;
}

.sticky {
  position: sticky;
  z-index: 1;
}

.lastSticky {
  @apply border-r;
  border-right-color: transparent;
}

.lastStickyDivider {
  @apply border-base-400;
}
</style>
