import { toValue } from 'vue'
import { RefOrGetter } from '@lasso/shared/types'

import { VirtualTableColumn, VirtualTableColumnWidths } from './types'

export const useColumnsProps = <T extends Record<string, unknown>>({ columns, columnWidths, sticky, checkbox }: {
  columns: RefOrGetter<Array<Required<VirtualTableColumn<T>>>>
  columnWidths: RefOrGetter<VirtualTableColumnWidths<T>>
  sticky: RefOrGetter<number>
  checkbox: RefOrGetter<boolean>
}) => {
  const getColumnWidth = (column: Required<VirtualTableColumn<T>>, columnIndex: number): number => {
    const width = toValue(columnWidths)[column.id] ?? column.width

    return (columnIndex === 0 && toValue(checkbox)) ? width + 28 : width
  }

  const isColumnSticky = (columnIndex: number) => {
    return toValue(sticky) >= columnIndex + 1
  }

  const getColumnOffset = (columnIndex: number) => {
    let offset = 0
    const _columns = toValue(columns)

    for (let i = 0; i < Math.min(columnIndex, _columns.length); i++) {
      offset += getColumnWidth(_columns[i]!, i)
    }

    return offset
  }

  const getColumnStickyOffset = (columnIndex: number) => {
    if (!isColumnSticky(columnIndex)) {
      return undefined
    }

    return `${getColumnOffset(columnIndex)}px`
  }

  return {
    getColumnWidth,
    isColumnSticky,
    getColumnOffset,
    getColumnStickyOffset,
  }
}
