<template>
  <Box flex spaceX="2" alignItems="center">
    <slot name="chip" />
    <Tooltip placement="right" class="grow min-w-0" trigger="manual">
      <template #default="{ show, hide }">
        <Box flex spaceX="2" alignItems="center">
          <Box flex alignItems="center" grow="grow" minWidth="0px">
            <Link
              v-if="clickable"
              ref="titleRef"
              :class="titleClasses"
              :target="target"
              :color="hovered ? 'secondary' : 'base'"
              :to="href ?? undefined"
              @click="emit('click')"
              @mouseenter="show()"
              @mouseleave="hide()"
            >
              <slot />
            </Link>
            <div
              v-else
              ref="titleRef"
              :class="titleClasses"
              @mouseenter="show()"
              @mouseleave="hide()"
            >
              <slot />
            </div>
          </Box>
          <slot name="extra" />
          <Box
            v-if="hovered && $slots.actions"
            flex
            spaceX="2"
            alignItems="center"
          >
            <slot name="actions" />
          </Box>
          <Box v-if="$slots.dropdown">
            <DropdownList
              :width="dropdownWidth"
              :contentDataTestId="dropdownDataTestId"
              @click.stop
            >
              <template #default="{ state }">
                <ButtonIcon
                  icon="more_vert"
                  :class="{ invisible: !hovered && !state.isVisible }"
                />
              </template>
              <template #dropdown="tippyInstance">
                <slot name="dropdown" v-bind="tippyInstance" />
              </template>
            </DropdownList>
          </Box>
        </Box>
      </template>
      <template v-if="overflown || $slots.tooltip" #content>
        <slot v-if="!$slots.tooltip" />
        <slot name="tooltip" />
      </template>
    </Tooltip>
  </Box>
</template>

<script setup lang="ts">
import { ComponentPublicInstance, computed, shallowRef } from 'vue'
import { useElementOverflown } from '@lasso/shared/hooks'

import Box from '../../Box/Box.vue'
import DropdownList from '../../DropdownList/DropdownList.vue'
import ButtonIcon from '../../ButtonIcon/ButtonIcon.vue'
import Tooltip from '../../Tooltip/Tooltip.vue'
import Link from '../../Link/Link.vue'

const props = withDefaults(
  defineProps<{
    clickable?: boolean
    hovered?: boolean
    href?: string | null
    dropdownWidth?: string
    dropdownDataTestId?: string
    target?: string
    fullWidth?: boolean
  }>(),
  {
    clickable: false,
    hovered: false,
    dropdownWidth: '120px',
  },
)

const emit = defineEmits<{
  click: []
}>()

const titleRef = shallowRef<ComponentPublicInstance>()
const { overflown } = useElementOverflown(titleRef)

const titleClasses = computed(() => {
  const { fullWidth } = props

  return {
    'truncate': true,
    'max-w-full': true,
    'inline-block': true,
    'grow': fullWidth ? 'grow' : undefined,
  }
})
</script>
