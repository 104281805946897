<template>
  <VirtualScroller
    v-if="loading || rows.length > 0"
    ref="scrollerRef"
    :class="s.table"
    :items="items"
    :listClass="s.tableInner"
    :itemClass="s.rowWrapper"
    :itemSize="rowHeight"
    :updateInterval="updateInterval"
    keyField="id"
  >
    <template #before>
      <VirtualTableHead
        v-model:columnWidths="columnWidthsInternal"
        v-model:sorting="sortingInternal"
        v-model:selected="allSelected"
        :defaultSorting="defaultSorting"
        :rowHeight="rowHeight"
        :columns="columnsInternal"
        :sticky="sticky"
        :checkbox="checkbox"
        :checkboxDisabled="checkboxDisabled || loading"
        :scrollX="scrollX"
      >
        <template v-for="slot in getSlotNames('head')" #[slot.id]="slotProps">
          <slot :name="slot.name as any" v-bind="slotProps" />
        </template>
      </VirtualTableHead>
    </template>
    <template #default="{ item, index }">
      <VirtualTableRow
        v-if="loading || !isEmpty(item.data)"
        v-model:selected="selectedInternal"
        :row="item.data as T"
        :rowKey="rowKey as any"
        :rowIndex="index"
        :rowHeight="rowHeight"
        :columns="columnsInternal"
        :columnWidths="columnWidthsInternal"
        :sticky="sticky"
        :loading="loading"
        :checkbox="checkbox"
        :checkboxDisabled="checkboxDisabled || loading"
        :scrollX="scrollX"
      />
    </template>
    <template v-if="!isEmpty(totals)" #after>
      <VirtualTableFoot
        :totals="totals as Partial<T>"
        :rowHeight="rowHeight"
        :columns="columnsInternal"
        :columnWidths="columnWidthsInternal"
        :sticky="sticky"
        :loading="loading"
        :scrollX="scrollX > 0"
        :checkbox="checkbox"
      >
        <template v-for="slot in getSlotNames('total')" #[slot.id]="slotProps">
          <slot :name="slot.name as any" v-bind="slotProps" />
        </template>
      </VirtualTableFoot>
    </template>
  </VirtualScroller>
  <div v-else :class="s.emptyState">
    <EmptyState :message="placeholder" />
  </div>
</template>

<script setup lang="ts" generic="T extends Record<string, unknown>, RowKey extends KeyOf<T>">
import { ComponentPublicInstance, Ref, computed, ref, useCssModule, watch } from 'vue'
import { unrefElement, useScroll, useVModel } from '@vueuse/core'
import { ComponentExposed, KeyOf } from '@lasso/shared/types'
import { isEmpty, range } from 'lodash-es'
import { arrayIncludes, objKeys } from '@lasso/shared/utils'

import { VirtualScroller } from '../VirtualScroller'

import { EmptyState } from '../EmptyState'

import {
  VirtualTableColumn, VirtualTableColumnSlotProps,
  VirtualTableColumnWidths,
  VirtualTableExposed,
  VirtualTableSize, VirtualTableSorting,
} from './types'
import VirtualTableRow from './VirtualTableRow.vue'
import VirtualTableHead from './VirtualTableHead.vue'
import VirtualTableFoot from './VirtualTableFoot.vue'

const props = withDefaults(defineProps<{
  columns: Array<VirtualTableColumn<T>>
  columnWidths?: VirtualTableColumnWidths<T>
  columnWidthDefault?: number
  rows: T[]
  rowKey: RowKey
  totals?: Partial<T>
  minHeight?: string
  maxHeight?: string
  size?: VirtualTableSize
  sorting?: VirtualTableSorting<KeyOf<T>>
  defaultSorting?: VirtualTableSorting<KeyOf<T>>
  sticky?: number
  updateInterval?: number
  loading?: boolean
  skeleton?: number
  checkbox?: boolean
  checkboxDisabled?: boolean
  selected?: Array<T[RowKey]>
  placeholder?: string
}>(), {
  columnWidths: () => ({}),
  maxHeight: '630px',
  size: 'md',
  sticky: 0,
  columnWidthDefault: 150,
  totals: () => ({}),
  updateInterval: 0,
  loading: false,
  skeleton: 10,
  selected: () => [],
  checkbox: false,
  checkboxDisabled: false,
  placeholder: 'No results found',
})

const emit = defineEmits<{
  'update:columnWidths': [Record<string, string>]
  'update:sorting': [VirtualTableSorting<KeyOf<T>>]
  'update:selected': [Array<T[RowKey]>]
}>()

const slots = defineSlots<
  & { [K in KeyOf<T> as `head_${K}`]?: () => any }
  & { [K in KeyOf<T> as `total_${K}`]?: (props: VirtualTableColumnSlotProps<T, K>) => any }
>()

type Slot<K extends string> = {
  name: `${K}_${KeyOf<T>}`
  id: KeyOf<T>
}

const s = useCssModule()

const columnWidthsInternal = useVModel(props, 'columnWidths', emit, { passive: true }) as Ref<VirtualTableColumnWidths<T>>
const sortingInternal = useVModel(props, 'sorting', emit)
const selectedInternal = useVModel(props, 'selected', emit) as Ref<Array<T[RowKey]>>

const buildColumn = (column: VirtualTableColumn<T>): Required<VirtualTableColumn<T>> => {
  return {
    description: '',
    chip: null,
    hidden: false,
    sortable: false,
    resizable: false,
    width: props.columnWidthDefault,
    modifier: String,
    component: null,
    componentProps: props => props,
    ...column,
  }
}

const columnsInternal = computed((): Array<Required<VirtualTableColumn<T>>> => {
  return props.columns.filter(column => !column.hidden).map(buildColumn)
})

const getSlotNames = <K extends 'head' | 'total'>(prefix: K): Array<Slot<K>> => {
  const slotKeys = objKeys(slots)
  return columnsInternal.value
    .map((column): Slot<K> => ({
      name: `${prefix}_${column.id}`,
      id: column.id,
    }))
    .filter(slot => arrayIncludes(slotKeys, slot.name))
}

const items = computed((): Array<{ id: T[RowKey]; data: T | Record<string, never> }> => {
  if (props.rows.length === 0 && props.loading) {
    return range(props.skeleton).map(index => ({ id: index as T[RowKey], data: {} }))
  }

  return props.rows.map(row => ({ id: row[props.rowKey], data: row }))
})

const rowHeight = computed(() => props.size === 'sm' ? 36 : 48)
const rowHeightPx = computed(() => `${rowHeight.value}px`)

const scrollerRef = ref<ComponentExposed<typeof VirtualScroller> & ComponentPublicInstance>()

watch(() => props.rows.length, (newValue, oldValue) => {
  if (newValue < oldValue) {
    scrollerRef.value?.scrollToPosition(0)
  }
})

const allSelected = computed({
  get: () => props.rows.length > 0 && props.rows.every(row => props.selected.includes(row[props.rowKey])),
  set: (value) => {
    selectedInternal.value = value ? props.rows.map(row => row[props.rowKey]) : []
  },
})

const { x: scrollX } = useScroll(() => unrefElement(scrollerRef.value))

defineExpose<VirtualTableExposed>({
  scrollToTop: () => scrollerRef.value?.scrollToPosition(0),
  scrollToBottom: () => scrollerRef.value?.scrollToItem(items.value.length - 1),
  scrollToRow: index => scrollerRef.value?.scrollToItem(index),
  scrollToPosition: position => scrollerRef.value?.scrollToPosition(position),
})
</script>

<style module>
.table {
  max-height: v-bind(maxHeight);
  outline: none;
}

.emptyState {
  align-content: center;
}

.tableInner {
  overflow: visible !important;
}

.table :global(.vue-recycle-scroller__slot) {
  position: sticky;
  z-index: 1;
  height: v-bind(rowHeightPx);
}

.table :global(.vue-recycle-scroller__slot:first-child) {
  top: 0;
}

.table :global(.vue-recycle-scroller__slot:not(:first-child)) {
  bottom: 0;
}

.rowWrapper {
  width: auto !important;
}
</style>
