import { computed, onMounted, ref } from 'vue'
import { memoize } from 'lodash-es'

const detect = memoize(() => {
  const div = document.createElement('div')
  div.setAttribute('contenteditable', 'PLAINTEXT-ONLY')

  return div.contentEditable === 'plaintext-only'
})

export const useSupportsPlaintextOnly = () => {
  const supportsPlaintextOnly = ref(false)
  const contenteditableValue = computed(() => supportsPlaintextOnly.value ? 'plaintext-only' : 'true')

  onMounted(() => {
    supportsPlaintextOnly.value = detect()
  })

  return { supportsPlaintextOnly, contenteditableValue }
}
