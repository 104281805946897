export const variants = {
  fill: 'progress-radial-fill border-2',
}

export const colors = {
  primary: 'text-primary progress-radial-primary',
  secondary: 'text-secondary progress-radial-secondary',
  info: 'text-info progress-radial-info',
  success: 'text-success progress-radial-success',
  warning: 'text-warning progress-radial-warning',
  error: 'text-error progress-radial-error',
}
