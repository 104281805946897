<template>
  <div class="circular-progress">
    <svg
      fill="transparent"
      :class="circularProgressClasses"
      stroke="currentColor"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="50" cy="50" r="40" shape-rendering="geometricPrecision" />
    </svg>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import * as typographyClasses from '../Typography/classes'

import { checkForUndefined } from '../../utils'

import * as classes from './classes'
import { CircularProgressProps } from './types'

const props = withDefaults(defineProps<CircularProgressProps>(), {
  size: 'sm',
  color: 'inherit',
})

const circularProgressClasses = computed(() => {
  const color = checkForUndefined(props.color, typographyClasses.color)
  const size = checkForUndefined(props.size, classes.size)

  return {
    [size]: !!props.size,
    [color]: !!props.color,
  }
})
</script>

<style src="./circularProgress.styles.css" scoped />
