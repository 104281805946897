<template>
  <Paper
    variant="contained"
    shadow="lg"
    :color="color"
    :rounded="square ? 'rounded' : 'xl'"
    :hover="hover"
  >
    <Box flex wrap="nowrap" :alignItems="alignItems" justify="between" :class="infoCardBoxClasses">
      <Box flex :alignItems="alignItems" grow="grow">
        <Box v-if="$slots.left" shrink="shrink-0" pr="2">
          <slot name="left" />
        </Box>
        <Box flex direction="col" justify="between" spaceY="1" grow="grow" shrink="shrink">
          <Box>
            <slot name="title">
              {{ title }}
            </slot>
          </Box>
          <Box>
            <slot name="subtitle">
              {{ subtitle }}
            </slot>
          </Box>
        </Box>
      </Box>
      <Box v-if="$slots.right" shrink="shrink-0" pl="2">
        <slot name="right" />
      </Box>
    </Box>
  </Paper>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'
import { computed } from 'vue'

import type { PaperColor } from '../../components/Paper/types'

import Box from '../Box/Box.vue'
import Paper from '../Paper/Paper.vue'

import type { InfoCardAlignItems, InfoCardPropTypes } from './types'

const props: InfoCardPropTypes = defineProps({
  color: {
    type: String as PropType<PaperColor>,
    default: 'base',
  },
  alignItems: {
    type: String as PropType<InfoCardAlignItems>,
    default: 'center',
  },
  disablePY: {
    type: Boolean,
    default: false,
  },
  disablePX: {
    type: Boolean,
    default: false,
  },
  square: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: '',
  },
  subtitle: {
    type: String,
    default: '',
  },
  hover: {
    type: Boolean,
    default: false,
  },
})

const infoCardBoxClasses = computed(() => {
  return {
    'py-4': !props.disablePY,
    'px-6': !props.disablePX,
  }
})
</script>
