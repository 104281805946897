<template>
  <VueDraggableNext
    v-model="modelValueInternal"
    v-bind="propsInternal"
    class="w-full h-full"
    @change="emit('change', $event)"
    @add="handleAdd"
    @remove="handleRemove"
    @update="handleUpdate"
    @end="handleEnd"
    @choose="handleChoose"
    @unchoose="handleUnchoose"
    @sort="handleSort"
  >
    <slot />
  </VueDraggableNext>
</template>

<script setup lang="ts" generic="T">
import { useVModel } from '@vueuse/core'

import { computed } from 'vue'

import { omit } from 'lodash-es'

import { SortableEvent } from 'sortablejs'

import Box from '../Box/Box.vue'

import { VueDraggableNext } from '../vendor/VueDraggableNext'

import { DraggableEmits, DraggableProps } from './types'

const props = withDefaults(defineProps<DraggableProps<T>>(), {
  handle: '[data-role="handle"]',
  preventOnFilter: true,
  sort: true,
  removeCloneOnHide: true,
  animation: 200,

  boxDisplay: 'flex',
  boxDirection: 'col',
})

const emit = defineEmits<DraggableEmits<T>>()

defineSlots<{
  default(props: {}): any
}>()

const modelValueInternal = useVModel(props, 'modelValue', emit)

const propsInternal = computed(() => {
  // Remove undefined values because they will override the defaults in Sortable.js
  const {
    boxProps,
    boxDisplay,
    boxDirection,
    spaceX,
    spaceY,
    clone,
    move,
    ...sortableOptions
  } = omit(props, 'modelValue')
  return {
    tag: Box,
    clone,
    move,
    componentData: {
      display: boxDisplay,
      direction: boxDirection,
      spaceX,
      spaceY,
      ...boxProps,
    },
    sortableOptions,
  }
})

const handleAdd = (data: SortableEvent) => emit('add', data)
const handleRemove = (data: SortableEvent) => emit('remove', data)
const handleUpdate = (data: SortableEvent) => emit('update', data)
const handleEnd = (data: SortableEvent) => emit('end', data)
const handleChoose = (data: SortableEvent) => emit('choose', data)
const handleUnchoose = (data: SortableEvent) => emit('unchoose', data)
const handleSort = (data: SortableEvent) => emit('sort', data)
</script>
