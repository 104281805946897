import type {
  GridAlignContent,
  GridAlignItems,
  GridAlignSelf,
  GridFlow,
  GridJustifyItems,
  GridJustifySelf,
  GridSize,
  GridSpacing,
  GridSpan,
} from './types'

export const justifyItems: Record<GridJustifyItems, any> = {
  start: 'justify-items-start',
  end: 'justify-items-end',
  center: 'justify-items-center',
  stretch: 'justify-items-stretch',
}

export const alignSelf: Record<GridAlignSelf, any> = {
  auto: 'self-auto',
  start: 'self-start',
  center: 'self-center',
  end: 'self-end',
  baseline: 'self-baseline',
  stretch: 'self-stretch',
}

export const justifySelf: Record<GridJustifySelf, any> = {
  auto: 'justify-self-auto',
  start: 'justify-self-start',
  center: 'justify-self-center',
  end: 'justify-self-end',
  stretch: 'justify-self-stretch',
}

export const alignItems: Record<GridAlignItems, any> = {
  start: 'items-start',
  center: 'items-center',
  end: 'items-end',
  baseline: 'items-baseline',
  stretch: 'items-stretch',
}

export const alignContent: Record<GridAlignContent, any> = {
  start: 'content-start',
  center: 'content-center',
  end: 'content-end',
  between: 'content-between',
  around: 'content-around',
  evenly: 'content-evenly',
  baseline: 'content-baseline',
}

export const flow: Record<GridFlow, any> = {
  'col': 'grid-flow-col',
  'row': 'grid-flow-row',
  'dense': 'grid-flow-dense',
  'row-dense': 'grid-flow-row-dense',
  'col-dense': 'grid-flow-col-dense',
}

export const colSpan: Record<GridSpan, string> = {
  auto: 'col-auto',
  1: 'col-span-1',
  2: 'col-span-2',
  3: 'col-span-3',
  4: 'col-span-4',
  5: 'col-span-5',
  6: 'col-span-6',
  7: 'col-span-7',
  8: 'col-span-8',
  9: 'col-span-9',
  10: 'col-span-10',
  11: 'col-span-11',
  12: 'col-span-12',
}

export const rowSpan: Record<GridSpan, string> = {
  auto: 'row-auto',
  1: 'row-span-1',
  2: 'row-span-2',
  3: 'row-span-3',
  4: 'row-span-4',
  5: 'row-span-5',
  6: 'row-span-6',
  7: 'row-span-7',
  8: 'row-span-8',
  9: 'row-span-9',
  10: 'row-span-10',
  11: 'row-span-11',
  12: 'row-span-12',
}

export const start: Record<GridSpan, string> = {
  auto: 'col-start-auto',
  1: 'col-start-1',
  2: 'col-start-2',
  3: 'col-start-3',
  4: 'col-start-4',
  5: 'col-start-5',
  6: 'col-start-6',
  7: 'col-start-7',
  8: 'col-start-8',
  9: 'col-start-9',
  10: 'col-start-10',
  11: 'col-start-11',
  12: 'col-start-12',
}

export const end: Record<GridSpan, string> = {
  auto: 'col-end-auto',
  1: 'col-end-1',
  2: 'col-end-2',
  3: 'col-end-3',
  4: 'col-end-4',
  5: 'col-end-5',
  6: 'col-end-6',
  7: 'col-end-7',
  8: 'col-end-8',
  9: 'col-end-9',
  10: 'col-end-10',
  11: 'col-end-11',
  12: 'col-end-12',
}
export const xs: Record<GridSize, any> = {
  none: 'xs:grid-cols-none',
  1: 'xs:grid-cols-1',
  2: 'xs:grid-cols-2',
  3: 'xs:grid-cols-3',
  4: 'xs:grid-cols-4',
  5: 'xs:grid-cols-5',
  6: 'xs:grid-cols-6',
  7: 'xs:grid-cols-7',
  8: 'xs:grid-cols-8',
  9: 'xs:grid-cols-9',
  10: 'xs:grid-cols-10',
  11: 'xs:grid-cols-11',
  12: 'xs:grid-cols-12',
}

export const sm: Record<GridSize, any> = {
  none: 'sm:grid-cols-none',
  1: 'sm:grid-cols-1',
  2: 'sm:grid-cols-2',
  3: 'sm:grid-cols-3',
  4: 'sm:grid-cols-4',
  5: 'sm:grid-cols-5',
  6: 'sm:grid-cols-6',
  7: 'sm:grid-cols-7',
  8: 'sm:grid-cols-8',
  9: 'sm:grid-cols-9',
  10: 'sm:grid-cols-10',
  11: 'sm:grid-cols-11',
  12: 'sm:grid-cols-12',
}

export const md: Record<GridSize, any> = {
  none: 'md:grid-cols-none',
  1: 'md:grid-cols-1',
  2: 'md:grid-cols-2',
  3: 'md:grid-cols-3',
  4: 'md:grid-cols-4',
  5: 'md:grid-cols-5',
  6: 'md:grid-cols-6',
  7: 'md:grid-cols-7',
  8: 'md:grid-cols-8',
  9: 'md:grid-cols-9',
  10: 'md:grid-cols-10',
  11: 'md:grid-cols-11',
  12: 'md:grid-cols-12',
}

export const lg: Record<GridSize, any> = {
  none: 'lg:grid-cols-none',
  1: 'lg:grid-cols-1',
  2: 'lg:grid-cols-2',
  3: 'lg:grid-cols-3',
  4: 'lg:grid-cols-4',
  5: 'lg:grid-cols-5',
  6: 'lg:grid-cols-6',
  7: 'lg:grid-cols-7',
  8: 'lg:grid-cols-8',
  9: 'lg:grid-cols-9',
  10: 'lg:grid-cols-10',
  11: 'lg:grid-cols-11',
  12: 'lg:grid-cols-12',
}

export const xl: Record<GridSize, any> = {
  none: 'xl:grid-cols-none',
  1: 'xl:grid-cols-1',
  2: 'xl:grid-cols-2',
  3: 'xl:grid-cols-3',
  4: 'xl:grid-cols-4',
  5: 'xl:grid-cols-5',
  6: 'xl:grid-cols-6',
  7: 'xl:grid-cols-7',
  8: 'xl:grid-cols-8',
  9: 'xl:grid-cols-9',
  10: 'xl:grid-cols-10',
  11: 'xl:grid-cols-11',
  12: 'xl:grid-cols-12',
}

export const xxl: Record<GridSize, any> = {
  none: '2xl:grid-cols-none',
  1: '2xl:grid-cols-1',
  2: '2xl:grid-cols-2',
  3: '2xl:grid-cols-3',
  4: '2xl:grid-cols-4',
  5: '2xl:grid-cols-5',
  6: '2xl:grid-cols-6',
  7: '2xl:grid-cols-7',
  8: '2xl:grid-cols-8',
  9: '2xl:grid-cols-9',
  10: '2xl:grid-cols-10',
  11: '2xl:grid-cols-11',
  12: '2xl:grid-cols-12',
}

export const gap: Record<GridSpacing, any> = {
  none: 'gap-0',
  0.5: 'gap-0.5',
  1: 'gap-1',
  2: 'gap-2',
  3: 'gap-3',
  4: 'gap-4',
  5: 'gap-5',
  6: 'gap-6',
  7: 'gap-7',
  8: 'gap-8',
  9: 'gap-9',
  10: 'gap-10',
  11: 'gap-11',
  12: 'gap-12',
  14: 'gap-14',
  16: 'gap-16',
  24: 'gap-24',
  32: 'gap-32',
  48: 'gap-48',
}

export const gapX: Record<GridSpacing, any> = {
  none: 'gap-x-0',
  0.5: 'gap-x-0.5',
  1: 'gap-x-1',
  2: 'gap-x-2',
  3: 'gap-x-3',
  4: 'gap-x-4',
  5: 'gap-x-5',
  6: 'gap-x-6',
  7: 'gap-x-7',
  8: 'gap-x-8',
  9: 'gap-x-9',
  10: 'gap-x-10',
  11: 'gap-x-11',
  12: 'gap-x-12',
  14: 'gap-x-14',
  16: 'gap-x-16',
  24: 'gap-x-24',
  32: 'gap-x-32',
  48: 'gap-x-48',
}

export const gapY: Record<GridSpacing, any> = {
  none: 'gap-y-0',
  0.5: 'gap-y-0.5',
  1: 'gap-y-1',
  2: 'gap-y-2',
  3: 'gap-y-3',
  4: 'gap-y-4',
  5: 'gap-y-5',
  6: 'gap-y-6',
  7: 'gap-y-7',
  8: 'gap-y-8',
  9: 'gap-y-9',
  10: 'gap-y-10',
  11: 'gap-y-11',
  12: 'gap-y-12',
  14: 'gap-y-14',
  16: 'gap-y-16',
  24: 'gap-y-24',
  32: 'gap-y-32',
  48: 'gap-y-48',
}
