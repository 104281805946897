import type {
  TypographyAlignment,
  TypographyColor,
  TypographyElement,
  TypographyFont,
  TypographyTruncateRow,
  TypographyVariant,
  TypographyWhiteSpace,
  TypographyWordBreak,
} from './types'

export const variant: Record<TypographyVariant, string> = {
  'inherit': '',
  'h1': 'typography-h1',
  'lato-h1': 'typography-lato-h1',
  'h2': 'typography-h2',
  'lato-h2': 'typography-lato-h2',
  'h3': 'typography-h3',
  'lato-h3': 'typography-lato-h3',
  'h4': 'typography-h4',
  'lato-h4': 'typography-lato-h4',
  'h5': 'typography-h5',
  'lato-h5': 'typography-lato-h5',
  'h6': 'typography-h6',
  'lato-h6': 'typography-lato-h6',
  'form-label': 'typography-form-label',
  'body1': 'typography-body1',
  'body2': 'typography-body2',
  'subtitle1': 'typography-subtitle1',
  'lato-subtitle1': 'typography-lato-subtitle1',
  'subtitle2': 'typography-subtitle2',
  'caption1': 'typography-caption1',
  'caption2': 'typography-caption2',
  'number1': 'typography-number1',
  'number2': 'typography-number2',
  'overline1': 'typography-overline1',
  'overline2': 'typography-overline2',
  'tooltip1': 'typography-tooltip1',
  'tooltip2': 'typography-tooltip2',
}

export const tag: Record<TypographyElement, string> = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  div: 'div',
  span: 'span',
  p: 'p',
}

export const color: Record<TypographyColor, string> = {
  inherit: 'text-inherit',
  primary: 'text-primary',
  secondary: 'text-secondary',
  info: 'text-info',
  success: 'text-success',
  warning: 'text-warning',
  error: 'text-error',
  disabled: 'text-textDisabled',
  textPrimary: 'text-textPrimary',
  textSecondary: 'text-textSecondary',
  textSecondaryLight: 'text-textSecondaryLight',
}

export const font: Record<TypographyFont, string> = {
  // primary: 'lasso-typography-font-dmsans',
  secondary: 'lasso-typography-font-lato',
}

export const alignment: Record<TypographyAlignment, string> = {
  left: 'text-left',
  center: 'text-center',
  right: 'text-right',
  justify: 'text-justify',
}

export const truncateRow: Record<TypographyTruncateRow, string> = {
  one: 'line-clamp-1',
  two: 'line-clamp-2',
  three: 'line-clamp-3',
}

// https://developer.mozilla.org/en-US/docs/Web/CSS/white-space
export const whiteSpace: Record<TypographyWhiteSpace, string> = {
  'inherit': 'whitespace-inherit',
  'normal': 'whitespace-normal', // Use whitespace-normal to cause text to wrap normally within an element. Newlines and spaces will be collapsed.
  'nowrap': 'whitespace-nowrap', // Use whitespace-nowrap to prevent text from wrapping within an element. Newlines and spaces will be collapsed.
  'pre-wrap': 'whitespace-pre-wrap',
}

// https://developer.mozilla.org/en-US/docs/Web/CSS/word-break
export const wordBreak: Record<TypographyWordBreak, string> = {
  anywhere: 'break-anywhere', // Use overflow-wrap: anywhere to break words that are too long.
  normal: 'break-normal', // Use break-normal to only add line breaks at normal word break points.
  words: 'break-words', // Use break-words to add line breaks mid-word if needed.
  all: 'break-all', // Use break-all to add line breaks whenever necessary, without trying to preserve whole words.
  keep: 'break-keep',
}
