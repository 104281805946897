<template>
  <span v-if="highlightIndex >= 0" class="highlighted" v-html="html" />
  <span v-else>{{ label }}</span>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { escape } from 'lodash-es'

import type { HighlightProps } from './types'

const props: HighlightProps = defineProps({
  highlight: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
})

const labelSafe = computed(() => {
  if (props.label) {
    return escape(props.label.toString())
  }
  return ''
})

const highlightIndex = computed(() => {
  if (props.highlight && labelSafe.value) {
    return labelSafe.value.toLocaleLowerCase().indexOf(props.highlight.toLocaleLowerCase())
  }
  return -1
})
const highlightLength = computed(() => props.highlight?.trim().length || 0)

const labelBefore = computed(() => labelSafe.value?.substring(0, highlightIndex.value) || '')
const labelAfter = computed(() => {
  return labelSafe.value?.substring(highlightIndex.value + highlightLength.value, labelSafe.value?.length) || ''
})

const labelHighlighted = computed(() => {
  return labelSafe.value?.substring(highlightIndex.value, highlightIndex.value + highlightLength.value) || ''
})

// we need to render nodes without spaces between it, so we cannot do this in template
const html = computed(() => {
  return `${labelBefore.value}<em>${labelHighlighted.value}</em>${labelAfter.value}`
})
</script>

<style scoped>
.highlighted :deep(em) {
  @apply not-italic font-bold;
}
</style>
