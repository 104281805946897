export const variant = {
  text: 'tabs-text',
  outlined: 'tabs-outlined',
}

export const size = {
  md: 'tabs-md',
  lg: 'tabs-lg',
}

export const color = {
  default: 'tabs-default',
  primary: 'tabs-primary',
}
