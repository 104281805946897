import { SliderTrackColor, SliderTrackContainerSize, SliderTrackVariant } from '../types/SliderTrackTypes'

export const color: Record<SliderTrackColor, string> = {
  base: 'slider-track-base',
  primary: 'slider-track-primary',
  secondary: 'slider-track-secondary',
}

export const variant: Record<SliderTrackVariant, string> = {
  solid: 'slider-track-solid',
}

export const startFill = {
  disabled: 'hsl(var(--base-500))',
  base: 'hsl(var(--base-600))',
  primary: 'hsl(var(--p))',
  secondary: 'hsl(var(--s))',
}

export const endFill = {
  disabled: 'hsl(var(--b3))',
  base: 'hsl(var(--b3))',
  primary: 'hsl(var(--primary-light))',
  secondary: 'hsl(var(--secondary-light))',
}

export const containerSize: Record<SliderTrackContainerSize, string> = {
  md: 'slider-track-container-md',
}
