<template>
  <Box class="chip" :class="chipClasses" :maxWidth="maxWidth" @click="onClickInternal">
    <slot name="start">
      <Icon v-if="startIcon" :icon="startIcon" />
    </slot>
    <slot>
      <span class="chip-label">
        {{ label }}
      </span>
    </slot>
    <slot name="end">
      <Icon v-if="endIcon" class="ml-auto" :icon="endIcon" />
      <Icon v-if="cancelable" class="ml-auto chip-icon-cancelable" icon="cancel" @click.stop="onCancelInternal" />
      <Icon v-if="selectable" class="ml-auto" icon="keyboard_arrow_down" />
    </slot>
  </Box>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { checkForUndefined } from '../../utils'

import Icon from '../Icon/Icon.vue'
import Box from '../Box/Box.vue'

import type { ChipProps } from './types'
import { colors, sizes, variants } from './classes'

const props = withDefaults(defineProps<ChipProps>(), {
  variant: 'default',
  color: 'default',
  size: 'xs',
  disabled: false,
  selectable: false,
  cancelable: false,
  clickable: false,
  block: false,
})

const emits = defineEmits<{
  cancel: []
  click: []
}>()

const onCancelInternal = () => {
  if (!props.disabled) {
    emits('cancel')
  }
}

const onClickInternal = () => {
  if (!props.disabled && props.clickable) {
    emits('click')
  }
}

const chipClasses = computed(() => {
  const color = checkForUndefined(props.color, colors)
  const size = checkForUndefined(props.size, sizes)
  const variant = checkForUndefined(props.variant, variants)

  return {
    [color]: !!props.color,
    [size]: !!props.size,
    [variant]: !!props.variant,
    'chip-block': props.block,
    'chip-disabled': props.disabled,
    'chip-clickable': props.clickable,
    'chip-cancelable': props.cancelable,
  }
})
</script>

<style scoped src="./chip.styles.css" />
