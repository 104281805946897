export const sizes = {
  xs: 'radio-xs',
  sm: 'radio-sm h-[18px] w-[18px]',
  md: 'radio-md',
  lg: 'radio-lg',
}

export const colors = {
  primary: 'radio-primary',
  secondary: 'radio-secondary',
}
