import { Fragment } from 'vue'

// Nested slots render extraneous text nodes, so ignore them
const getHtmlElementFromNode = ({ el }) => el.nodeType === Node.TEXT_NODE ? el.nextSibling : el

const contextSymbol = Symbol('VueDraggableNext context symbol')
const addContext = (domElement, context) => (domElement[contextSymbol] = context)
const getContext = domElement => domElement[contextSymbol]

class ComponentStructure {
  constructor({
    $slots,
    getKey,
    tag,
    realList,
  }) {
    this.realList = realList
    this.tag = tag
    this.defaultNodes = this._computeNodes({ $slots, getKey })
  }

  render(h, attributes) {
    const { tag, defaultNodes } = this
    return h(tag, attributes, () => defaultNodes)
  }

  updated() {
    const { defaultNodes, realList } = this
    defaultNodes.forEach((node, index) => {
      addContext(getHtmlElementFromNode(node), {
        element: realList[index],
        index,
      })
    })
  }

  getUnderlyingVm(domElement) {
    return getContext(domElement)
  }

  getVmIndexFromDomIndex(domIndex, element) {
    const { defaultNodes } = this
    const domChildren = element.children
    const domElement = domChildren.item(domIndex)

    if (domElement === null) {
      return defaultNodes.length
    }
    const context = getContext(domElement)
    if (context) {
      return context.index
    }

    if (defaultNodes.length === 0) {
      return 0
    }
    const firstDomListElement = getHtmlElementFromNode(defaultNodes[0])
    const indexFirstDomListElement = [...domChildren].findIndex(
      element => element === firstDomListElement,
    )
    return domIndex < indexFirstDomListElement ? 0 : defaultNodes.length
  }

  _computeNodes({ $slots }) {
    const normalizedList = this.realList || []

    if (!$slots.default) {
      throw new Error('draggable element must have a default slot')
    }

    // Find non-fragment children
    let defaultNodes = $slots.default()

    while (defaultNodes.length === 1 && defaultNodes[0].type === Fragment) {
      defaultNodes = defaultNodes[0].children
    }

    if (defaultNodes.length !== normalizedList.length) {
      throw new Error('Default slot must render one child per item')
    }

    return defaultNodes
  }
}

export { ComponentStructure }
