<template>
  <Box :component="computedTag" :class="typographyClasses" :display="display">
    <slot />
  </Box>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'
import { computed } from 'vue'

import Box from '../Box/Box.vue'
import * as boxClasses from '../Box/classes'
import type { BoxDisplay } from '../Box/types'

import { checkForUndefined } from '../../utils'

import type * as Types from './types'
import * as classes from './classes'

import './typography.styles.css'

const props: Types.TypographyProps = defineProps({
  tag: {
    type: String as PropType<Types.TypographyElement>,
    default: 'span',
  },
  variant: {
    type: String as PropType<Types.TypographyVariant>,
    default: 'body1',
    validator: (variant: Types.TypographyVariant) => typeof classes.variant[variant] === 'string',
  },
  color: {
    type: String as PropType<Types.TypographyColor>,
    default: 'inherit',
    validator: (color: Types.TypographyColor) => !!classes.color[color],
  },
  font: {
    // font primary is DMSANS by default,
    // font secondary is LATO
    type: String as PropType<Types.TypographyFont>,
    default: null,
    validator: (font: Types.TypographyFont) => !!classes.font[font],
  },
  alignText: {
    type: String as PropType<Types.TypographyAlignment>,
    default: 'left',
    validator: (align: Types.TypographyAlignment) => !!classes.alignment[align],
  },
  // Text wrapping/truncation
  truncateRow: {
    type: String as PropType<Types.TypographyTruncateRow>,
    default: null,
    validator: (row: Types.TypographyTruncateRow) => !!classes.truncateRow[row],
  },
  display: {
    type: String as PropType<BoxDisplay>,
    default: undefined,
    validator: (display: BoxDisplay) => !!boxClasses.display[display],
  },
  // See classes.ts for description
  whiteSpace: {
    type: String as PropType<Types.TypographyWhiteSpace>,
    default: 'normal',
    validator: (whiteSpace: Types.TypographyWhiteSpace) => !!classes.whiteSpace[whiteSpace],
  },
  // See classes.ts for description
  wordBreak: {
    type: String as PropType<Types.TypographyWordBreak>,
    default: 'words',
    validator: (wordBreak: Types.TypographyWordBreak) => !!classes.wordBreak[wordBreak],
  },
  // Works only for Box with block property. Tag span is inline by default, so it need to be used with display="block" prop or changed to another tag.
  truncate: {
    type: Boolean,
    default: false,
  },
  clip: {
    type: Boolean,
    default: false,
  },
  // Text transform
  uppercase: {
    type: Boolean,
    default: false,
  },
  capitalize: {
    type: Boolean,
    default: false,
  },
  lowercase: {
    type: Boolean,
    default: false,
  },
  bold: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const computedTag = computed(() => {
  const tag = checkForUndefined(props.tag, classes.tag) || 'span'
  return tag as Types.TypographyElement
})
const typographyClasses = computed(() => {
  const variant = checkForUndefined(props.variant, classes.variant)
  const color = props.disabled ? classes.color.disabled : checkForUndefined(props.color, classes.color)
  const font = checkForUndefined(props.font, classes.font)
  const align = checkForUndefined(props.alignText, classes.alignment)
  const truncateRow = checkForUndefined(props.truncateRow, classes.truncateRow)
  const whiteSpace = checkForUndefined(props.whiteSpace, classes.whiteSpace)
  const wordBreak = checkForUndefined(props.wordBreak, classes.wordBreak)

  return {
    [variant]: props.variant,
    [color]: props.color,
    [font]: props.font,
    [align]: props.alignText,
    [truncateRow]: props.truncateRow,
    [whiteSpace]: props.whiteSpace,
    [wordBreak]: props.wordBreak,
    'font-medium': props.bold,
    'uppercase': props.uppercase,
    'lowercase': props.lowercase,
    'capitalize': props.capitalize,
    'truncate': props.truncate,
    'text-clip overflow-hidden': props.clip,
  }
})
</script>
