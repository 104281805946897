import { MaybeRefOrGetter, onBeforeUnmount, ref, toValue, watch } from 'vue'

/**
 * Used to avoid animation on template rendering and re-rendering for components like Checkbox and Radio.
 */
export const useDaisyUiAnimationCrutch = <T>(value: MaybeRefOrGetter<T>) => {
  const isAnimationDisabled = ref(true)

  const daisyUiAnimationDuration = 200
  let timeoutId: ReturnType<typeof setTimeout> | null = null

  watch(() => toValue(value), () => {
    isAnimationDisabled.value = false
    timeoutId = setTimeout(() => {
      isAnimationDisabled.value = true
    }, daisyUiAnimationDuration)
  })

  onBeforeUnmount(() => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
  })

  return {
    isAnimationDisabled,
  }
}
