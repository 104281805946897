<template>
  <div class="slider-track-container" :class="containerClasses">
    <div ref="trackRef" class="slider-track" :class="trackClasses" />
    <slot />
  </div>
</template>

<script setup lang="ts">
import { VNodeRef, computed, onMounted, ref, watch } from 'vue'

import { checkForUndefined } from '../../utils'

import { track } from './classes'
import { setTrackColor } from './utils'
import { SliderTrackProps } from './types/SliderTrackTypes'

const props = withDefaults(defineProps<SliderTrackProps>(), {
  color: 'base',
  upperProgress: 0,
  lowerProgress: undefined,
  disabled: false,
})
const trackRef = ref<VNodeRef | undefined>(undefined)

const containerClasses = computed(() => {
  const containerSize = checkForUndefined(props.containerSize, track.containerSize)

  return {
    [containerSize]: !!props.containerSize,
  }
})

const trackClasses = computed(() => {
  const color = checkForUndefined(props.color, track.color)

  return {
    [color]: !!props.color && !props.disabled,
    'slider-track-disabled': props.disabled,
  }
})

watch([() => props.upperProgress, () => props.lowerProgress], () => {
  setTrackColor(trackRef, {
    color: props.color,
    lowerProgress: props.lowerProgress,
    upperProgress: props.upperProgress,
    isDisabled: props.disabled,
  })
})

onMounted(() => {
  setTrackColor(trackRef, {
    color: props.color,
    lowerProgress: props.lowerProgress,
    upperProgress: props.upperProgress,
    isDisabled: props.disabled,
  })
})
</script>

<style src="./styles/track.styles.css" scoped />
