<template>
  <Box flex justify="start" alignItems="center" spaceX="1">
    <Icon :icon="icon" size="xs" :color="color" />

    <Typography variant="caption1" :color="color">
      <slot>
        {{ message }}
      </slot>
    </Typography>
  </Box>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import Box from '../Box/Box.vue'
import Icon from '../Icon/Icon.vue'
import Typography from '../Typography/Typography.vue'
import { TypographyColor } from '../Typography/types'

const props = withDefaults(defineProps<{
  variant?: 'default' | 'error' | 'disabled'
  message?: ''
}>(), {
  variant: 'default',
})

const color = computed((): TypographyColor => {
  switch (props.variant) {
    case 'error':
      return 'error'
    case 'disabled':
      return 'disabled'
    default:
      return 'textSecondaryLight'
  }
})

const icon = computed(() => props.variant === 'error' ? 'error_outline' : 'info_outline')
</script>
