import type { BadgeColor, BadgeShape, BadgeSize, BadgeVariant } from './types'

export const color: Record<BadgeColor, string> = {
  primary: 'badge-primary',
  secondary: 'badge-secondary',
  ghost: 'badge-ghost',
  info: 'badge-info',
  success: 'badge-success',
  warning: 'badge-warning',
  error: 'badge-error',
}

export const variant: Record<BadgeVariant, string> = {
  outline: 'badge-outline bg-base-100',
}

export const size: Record<BadgeSize, string> = {
  xs: 'badge-xs',
  sm: 'badge-sm',
  md: 'badge-md',
  lg: 'badge-lg',
}

export const shape: Record<BadgeShape, string> = {
  square: 'rounded',
}
