export const color = {
  default: 'toggle-default',
  secondary: 'toggle-secondary',
  error: 'toggle-error',
  success: 'toggle-success',
  primary: 'toggle-primary',
  warning: 'toggle-warning',
}

export const offColor = {
  default: 'toggle-off-default',
  secondary: 'toggle-off-secondary',
  error: 'toggle-off-error',
  success: 'toggle-off-success',
  primary: 'toggle-off-primary',
  warning: 'toggle-off-warning',
}

export const size = {
  xs: 'toggle-xs',
  sm: 'toggle-sm',
  md: 'toggle-md',
  lg: 'toggle-lg',
}

export const variant = {
  solid: 'toggle-solid',
  outline: 'toggle-outline',
}
