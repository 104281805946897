<template>
  <InputWrapper
    :disabled="disabled"
    :withValue="Boolean(modelValue)"
    :focused="focused"
    :error="errorInternal"
  >
    <textarea
      :id="id"
      v-model="modelValueInternal"
      class="textarea"
      :name="name"
      :cols="cols"
      :rows="rows"
      :disabled="disabled"
      :class="classes"
      :placeholder="placeholder"
      @focus="onFocus"
      @blur="onBlur"
    />
  </InputWrapper>
</template>

<script setup lang="ts">
import { computed, inject, ref } from 'vue'

import { useVModel } from '@vueuse/core'

import InputWrapper from '../Input/InputWrapper/InputWrapper.vue'

const props = withDefaults(defineProps<{
  modelValue: string
  id?: string
  name?: string
  cols?: string | number
  rows?: string | number
  disabled?: boolean
  error?: boolean
  resize?: 'x' | 'y' | boolean
  placeholder?: string
}>(), {
  resize: false,
})

const emit = defineEmits<{
  'update:modelValue': [string]
  focus: [FocusEvent]
  blur: [FocusEvent]
}>()
const formControlHasError = inject('formControlHasError', ref(false))
const errorInternal = computed(() => formControlHasError.value || props.error || false)

const modelValueInternal = useVModel(props, 'modelValue', emit)

const focused = ref(false)
const resizeClasses = new Map<boolean | string, string>([
  [true, 'resize'],
  [false, 'resize-none'],
  ['x', 'resize-x'],
  ['y', 'resize-y'],
])

const classes = computed(() => [
  'textarea',
  resizeClasses.get(props.resize),
  {
    'w-full': props.cols === undefined,
  },
])

const onFocus = (event: FocusEvent) => {
  focused.value = true
  emit('focus', event)
}

const onBlur = (event: FocusEvent) => {
  focused.value = false
  emit('blur', event)
}
</script>

<style scoped>
.textarea {
  padding: 0 !important;
}
</style>
