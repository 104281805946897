<template>
  <TableBase
    :id="id || null"
    ref="tableRef"
    :variant="variant"
    :zebra="zebra"
    class="table-layout"
    :class="{
      'variant-default': variant === 'default',
      'variant-dense': variant === 'dense',
      'variant-leaderboard': variant === 'leaderboard',
    }"
    :tableLayout="tableLayout"
    :tableMinWidth="tableMinWidth"
    @click="onClickInside()"
  >
    <template #header>
      <TableHead
        ref="tableHeadRef"
        v-model:allSelected="allSelected"
        :sorting="sorting"
        :hasData="data.length > 0"
        :tableColumns="tableColumns"
        :stickyFirstColumn="stickyFirstColumn"
        :stickyFirstColumns="stickyFirstColumns"
        :columnsOffsetWidths="columnsOffsetWidths"
        :checkbox="checkbox && !noAllCheckbox"
        :checkboxDisabled="checkboxDisabled"
        :tableId="id"
        :variant="variant"
        @sort="onSort($event)"
        @search="onSearch($event)"
      >
        <template v-for="slot in getSlotNames('head')" #[slot.id]="slotProps">
          <slot :name="slot.name" v-bind="slotProps" />
        </template>
      </TableHead>
    </template>

    <template #body>
      <slot name="body">
        <tr v-if="$slots.aboveRows && !loading" class="empty-state-row">
          <td :colspan="fields.length">
            <slot name="aboveRows" />
          </td>
        </tr>
        <template v-if="data.length || loading">
          <template v-if="loading && data.length === 0">
            <TableRow
              v-for="index in skeletonRows"
              :key="index"
              :index="index"
              :row="{}"
              :idKey="idKey as string"
              :tableColumns="tableColumns"
              :stickyFirstColumn="stickyFirstColumn"
              :stickyFirstColumns="stickyFirstColumns"
              :columnsOffsetWidths="columnsOffsetWidths"
              :hoverable="hoverable"
              :skeleton="skeleton"
              :variant="variant"
              loading
            />
          </template>
          <template v-else>
            <TableRow
              v-for="(row, index) in data"
              :key="index"
              :index="index"
              :row="row"
              :idKey="idKey as string"
              :tableColumns="tableColumns"
              :hoverable="hoverable"
              :stickyFirstColumn="stickyFirstColumn"
              :stickyFirstColumns="stickyFirstColumns"
              :columnsOffsetWidths="columnsOffsetWidths"
              :checkbox="checkbox"
              :checkboxDisabled="isCheckboxDisabled(index)"
              :active="isRowActive(row)"
              :selected="isRowSelected(row)"
              :selectedTableRows="selectedTableRows"
              :loading="loading"
              :skeleton="skeleton"
              :variant="variant"
              :countValue="counter ? countFrom + index : undefined"
              @click="onClickRow(row)"
              @select="onSelectRow(row)"
              @row:mouseOver="onMouseOver(row)"
              @row:mouseLeave="onMouseLeave(row)"
            >
              <template v-for="slot in getSlotNames('')" #[slot.id]="slotProps">
                <slot :name="slot.name" v-bind="slotProps" />
              </template>
            </TableRow>
          </template>
        </template>
        <tr v-else-if="!hideEmptyRow" class="empty-state-row">
          <td :colspan="fields.length" class="empty-state-td">
            <Typography variant="body2">
              {{ placeholder }}
            </Typography>
          </td>
        </tr>
      </slot>
    </template>

    <template v-if="$slots.footer || totals" #footer>
      <TableRow
        v-if="totals"
        :index="0"
        :row="totals"
        :idKey="idKey as string"
        :tableColumns="tableColumns"
        :hoverable="hoverable"
        :stickyFirstColumn="stickyFirstColumn"
        :stickyFirstColumns="stickyFirstColumns"
        :columnsOffsetWidths="columnsOffsetWidths"
        :loading="loading"
        :skeleton="skeleton"
        :variant="variant"
        isTotal
      >
        <template v-for="name in getSlotNames('total')" #[name.id]="slotProps">
          <slot :name="name.name" v-bind="slotProps" />
        </template>
      </TableRow>
      <slot name="footer" v-bind="{ tableColumnsCount, loading, stickyFirstColumn }" />
    </template>
  </TableBase>
</template>

<script setup lang="ts" generic="T extends Record<string, unknown>">
import { ComponentPublicInstance, Ref, computed, ref, shallowRef, toRef, watch } from 'vue'
import { onClickOutside, useElementSize } from '@vueuse/core'
import { uniq } from 'lodash-es'
import { arrayIncludes, nextFrame, objKeys } from '@lasso/shared/utils'
import { KeyOf } from '@lasso/shared/types'

import TableBase from '../TableBase/TableBase.vue'
import Typography from '../Typography/Typography.vue'

import type { TableBaseLayout, TableBaseVariant } from '../TableBase'

import TableHead from './TableHead.vue'
import TableRow from './TableRow.vue'

import {
  TableColumn,
  TableColumns,
  TableEmits,
  TableSkeleton,
  TableSlots, TableSortingOptional,
} from './types'

const props = withDefaults(defineProps<{
  id?: string
  fields: TableColumns<T>
  totals?: Partial<T>
  sorting?: TableSortingOptional<KeyOf<T>>
  defaultSorting?: TableSortingOptional<KeyOf<T>>
  data: T[]
  hoverable?: boolean
  variant?: TableBaseVariant
  zebra?: boolean
  checkbox?: boolean
  checkboxDisabled?: boolean | number[]
  noAllCheckbox?: boolean
  loading?: boolean
  stickyFirstColumn?: boolean
  stickyFirstColumns?: number
  skeleton?: TableSkeleton
  selected?: string[]
  hideEmptyRow?: boolean
  placeholder?: string
  idKey?: keyof T
  tableLayout?: TableBaseLayout
  columnBaseWidth?: string
  counter?: boolean
  countFrom?: number
}>(), {
  id: '',
  hoverable: false,
  zebra: false,
  variant: 'default',
  checkbox: false,
  checkboxDisabled: false,
  noAllCheckbox: false,
  loading: false,
  stickyFirstColumn: false,
  hideEmptyRow: false,
  skeleton: () => ({
    rows: 1,
    height: 4,
  }),
  selected: () => [],
  placeholder: 'No results found',
  idKey: 'id',
  counter: false,
  countFrom: 1,
})

const emits = defineEmits<TableEmits<T>>()
const slots = defineSlots<TableSlots<T>>()

const tableColumns = computed((): TableColumns<T> => {
  return props.fields
    .filter(column => !column.hidden)
    .map(column => ({
      ...column,
      search: column.search ?? '',
      clickable: column.clickable ?? true,
    }))
})

const tableColumnsCount = computed(() => tableColumns.value.length)

type Slot = {
  name: string
  id: KeyOf<T>
}

const getSlotNames = (prefix: '' | 'head' | 'total'): Array<Slot> => {
  const slotKeys = objKeys(slots)
  return tableColumns.value
    .map((column): Slot => ({
      name: prefix ? `${prefix}_${column.id}` : String(column.id),
      id: column.id,
    }))
    .filter(slot => arrayIncludes(slotKeys, slot.name))
}

const activeRow: Ref<T | null> = ref(null)
const tableRef = shallowRef<ComponentPublicInstance | null>(null)
const tableHeadRef = shallowRef<ComponentPublicInstance | null>(null)

const { width: tableHeadWidth } = useElementSize(tableHeadRef)

const columnsOffsetWidths = ref<number[]>([])

const recalculateWidths = () => {
  const thEls = Array.from<HTMLElement>(tableHeadRef.value?.$el.querySelectorAll('th') ?? [])
  columnsOffsetWidths.value = thEls.map(th => th.offsetWidth)
}

watch([tableHeadWidth, tableColumns], async () => {
  await nextFrame()
  recalculateWidths()
}, { immediate: true })

const selectedTableRows = ref<string[]>([...props.selected])

watch(() => props.selected, (newValue) => {
  selectedTableRows.value = [...newValue]
})

const tableMinWidth = computed(() => {
  if (props.tableLayout !== 'fixed') {
    return undefined
  }

  const columnsWidths = tableColumns.value.map(column => column.width ?? props.columnBaseWidth ?? '0px')

  return `calc(${columnsWidths.join(' + ')})`
})

const getNewSorting = (column: TableColumn<T>): TableSortingOptional<KeyOf<T>> => {
  const directionSequence = ['none', 'desc', 'asc'] as const

  const currentSorting = column.id === props.sorting?.sortColumn
    ? (props.sorting?.sortDirection || 'none')
    : 'none'

  let nextSortingIndex = directionSequence.indexOf(currentSorting) + 1

  if (nextSortingIndex >= directionSequence.length) {
    nextSortingIndex = 0
  }

  const nextSorting = directionSequence[nextSortingIndex]!

  if (column.id === props.defaultSorting?.sortColumn) {
    if (nextSorting === 'none' && props.defaultSorting.sortDirection === 'none') {
      return props.defaultSorting!
    }
    else if (nextSorting === 'none') {
      return {
        sortColumn: column.id,
        sortDirection: directionSequence[nextSortingIndex + 1]!,
      }
    }
    else {
      return {
        sortColumn: column.id,
        sortDirection: nextSorting,
      }
    }
  }
  else if (nextSorting === 'none' && props.defaultSorting) {
    return props.defaultSorting
  }

  return {
    sortColumn: column.id,
    sortDirection: nextSorting,
  }
}

const onSort = (column: TableColumn<T>) => {
  emits('update:sorting', getNewSorting(column))
}

const onClickRow = (row: T) => {
  activeRow.value = row
}

const focusedInsideTable = ref(false)

const onClickInside = () => {
  if (!focusedInsideTable.value) {
    focusedInsideTable.value = true
    emits('focus')
  }
}

onClickOutside(toRef(() => tableRef.value?.$el?.querySelector('tbody')), () => {
  if (focusedInsideTable.value) {
    focusedInsideTable.value = false
    activeRow.value = null
    emits('blur')
  }
})

const onSelectRow = (row: T) => {
  if (row?.[props.idKey]) {
    if (!selectedTableRows.value.includes(String(row[props.idKey]))) {
      selectedTableRows.value.push(String(row[props.idKey]))
    }
    else {
      selectedTableRows.value = selectedTableRows.value.filter(id => id !== String(row[props.idKey]))
    }
  }

  emits('select', row)
  emits('update:selected', selectedTableRows.value)
}

const allSelected = computed({
  get: () => !props.loading && props.data.every(row => selectedTableRows.value.includes(String(row[props.idKey]))),
  set: (value) => {
    // Only select/deselect currently present rows to allow selecting rows on multiple pages
    if (value) {
      selectedTableRows.value = uniq([
        ...selectedTableRows.value,
        ...props.data.map(row => String(row[props.idKey])),
      ])
    }
    else {
      selectedTableRows.value = selectedTableRows.value.filter(rowId => !props.data.find(row => String(row[props.idKey]) === rowId))
    }

    emits('update:selected', selectedTableRows.value)
  },
})

const onSearch = ({ column, search }: {
  column: TableColumn<T>
  search: string
}) => {
  emits('search', { column: column.id, search })
}

const skeletonRows = computed(() => props.skeleton?.rows || 1)

const onMouseOver = (row: T) => {
  emits('row:mouseOver', row)
}

const onMouseLeave = (row: T) => {
  emits('row:mouseLeave', row)
}

const isRowActive = (row: T) => {
  return row[props.idKey] === activeRow.value?.[props.idKey]
}

const isRowSelected = (row: T) => {
  return Boolean(row[props.idKey]) && selectedTableRows.value.includes(String(row[props.idKey]))
}

const isCheckboxDisabled = (index: number) => {
  return Array.isArray(props.checkboxDisabled) ? props.checkboxDisabled.includes(index) : props.checkboxDisabled
}
</script>

<style scoped>
.table-layout {
  @apply rounded-none;
}

.table-layout :deep(.table th:first-child) {
  position: static;
}

.table-layout .empty-state-row {
  @apply border-b;
}

.table-layout .empty-state-td {
  @apply py-0 h-[54px];
}

.table-layout :deep(.table :where(thead, tfoot) :where(th, td)) {
  @apply bg-base-100 normal-case text-14 font-normal;
}

.table-layout :deep(.table :where(:first-child) :where(:first-child) :where(th, td)) {
  @apply rounded-none;
}

.table-layout :deep(.table :where(th, td)) {
  @apply rounded-none;
}

.table-layout :deep(.table :where(th)) {
  @apply rounded-none align-bottom;
}

.variant-default.table-layout :deep(.table tr.active td),
.variant-default.table-layout :deep(.table tr.hover:hover td),
.variant-dense.table-layout :deep(.table tr.active td),
.variant-dense.table-layout :deep(.table tr.hover:hover td) {
  @apply bg-base-200;
}

.variant-leaderboard.table-layout :deep(.table tr.active td),
.variant-leaderboard.table-layout :deep(.table tr.hover:hover td) {
  @apply bg-transparent;
}

.variant-leaderboard.table-layout :deep(.table tr.active .table-cell-inner),
.variant-leaderboard.table-layout :deep(.table tr.hover:hover .table-cell-inner) {
  @apply bg-base-200;
}

.variant-default.table-layout :deep(.table thead th),
.variant-default.table-layout :deep(.table tbody td),
.variant-dense.table-layout :deep(.table thead th),
.variant-dense.table-layout :deep(.table tbody td) {
  @apply border-b border-divider;
}

.variant-leaderboard.table-layout :deep(.table thead th),
.variant-leaderboard.table-layout :deep(.table tbody td) {
  @apply border-none;
}
</style>
