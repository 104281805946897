import { arrayIncludes, arrayify } from '@lasso/shared/utils'

import { KeyOf } from '@lasso/shared/types'

import { TableColumns } from './types'

export const filterTableFields = <T extends Record<string, unknown>, K extends KeyOf<T>>(
  fields: TableColumns<T>,
  key: K | K[],
) => {
  const keys = arrayify(key)

  return fields.filter(field => !arrayIncludes(keys, field.id)) as unknown as TableColumns<Omit<T, K>>
}
