<template>
  <Tippy
    ref="tippyRef"
    class="dropdown-list"
    :offsetDistance="offsetDistance"
    openDelay="100"
    closeDelay="100"
    p="0"
    px="0"
    py="0"
    :maxWidth="width"
    :placement="placement"
    :trigger="trigger"
    @show="onShow"
    @hidden="onHide"
    @clickOutside="onClickOutside"
    @afterUpdate="onAfterUpdate"
  >
    <template #default="tippyInstance">
      <slot v-bind="tippyInstance" />
    </template>
    <template v-if="$slots.dropdown" #content="tippyInstance">
      <Box flex class="dropdown" :width="width" :data-test-id="contentDataTestId">
        <List
          ref="contentWrapper"
          class="dropdown-list-content"
          disablePY
          disablePX
          color="base"
          textColor="textPrimary"
          activeColor="base"
        >
          <slot name="dropdown" v-bind="tippyInstance" />
        </List>
      </Box>
    </template>
  </Tippy>
</template>

<script lang="ts" setup>
import { ComponentPublicInstance, PropType, nextTick, onUnmounted, ref } from 'vue'

import { unrefElement, useScroll } from '@vueuse/core'

import List from '../List/List.vue'
import Box from '../Box/Box.vue'

import Tippy from '../Tippy/Tippy.vue'
import type { TippyPlacement, TippySingleTrigger } from '../Tippy/types'

defineProps({
  // TODO: allow width: auto
  width: {
    type: String,
  },
  offsetDistance: {
    type: String,
    default: '4',
  },
  placement: {
    type: String as PropType<TippyPlacement>,
  },
  trigger: {
    type: [String, Array] as PropType<TippySingleTrigger | TippySingleTrigger[]>,
    default: 'click',
  },
  contentDataTestId: {
    type: String,
    default: undefined,
  },
})

const emits = defineEmits(['hide', 'show', 'click-outside'])
const tippyRef = ref()
const contentWrapper = ref<ComponentPublicInstance>()

const scrollToTop = () => {
  nextTick().then(() => contentWrapper.value?.$el.scrollTo(0, 0))
}

const getChildListItems = () => {
  return Array.from(contentWrapper.value?.$el.querySelectorAll('li') ?? []) as HTMLButtonElement[]
}

const focusNext = (nextIndex: (current: number, size: number) => number) => {
  const childListItems = getChildListItems().filter(el => !el.disabled)
  if (childListItems.length) {
    const currentIndex = childListItems.findIndex(el => document.activeElement === el.firstChild)
    const index = nextIndex(currentIndex, childListItems.length)
    const item = childListItems[index]

    if (item) {
      (item.firstChild as HTMLElement)?.focus()
    }
  }
}

const focusDown = () => {
  focusNext((index, size) => {
    if (index >= 0 && index < size - 1) {
      return index + 1
    }
    return 0
  })
}

const focusUp = () => {
  focusNext((index, size) => {
    if (index >= 1) {
      return index - 1
    }
    return size - 1
  })
}

/*
  Select current DropdownItem on Enter click
 */
const onEnter = () => {
  const childListItems = getChildListItems().filter(el => !el.disabled)
  if (childListItems.length) {
    const currentIndex = childListItems.findIndex(el => document.activeElement === el.firstChild)
    const item = childListItems[currentIndex]

    if (item) {
      (childListItems[currentIndex]!.firstChild as HTMLElement)?.click()
      tippyRef.value.$el.blur()
    }
  }
}

const onKeydown = (event: KeyboardEvent): void => {
  switch (event.key) {
    case 'ArrowUp':
      focusUp()
      event.preventDefault()
      break

    case 'ArrowDown':
      focusDown()
      event.preventDefault()
      break

    case 'Enter':
      onEnter()
      event.preventDefault()
      break

    default:
      break
  }
}

const onShow = () => {
  window.addEventListener('keydown', onKeydown)
  emits('show')
}

const onHide = () => {
  window.removeEventListener('keydown', onKeydown)
  emits('hide')
}

onUnmounted(() => {
  window.removeEventListener('keydown', onKeydown)
})

const toggle = () => {
  tippyRef.value?.tippyInstance?.toggle()
}
const hide = () => {
  tippyRef.value?.tippyInstance?.hide()
}
const show = () => {
  tippyRef.value?.tippyInstance?.show()
}

const onClickOutside = () => {
  emits('click-outside')
}

// Tippy is rerendered on every update to slot content or props
// Preserve scroll position
const { x, y } = useScroll(() => unrefElement(contentWrapper))

const onAfterUpdate = () => {
  contentWrapper.value?.$el.scrollTo(x.value, y.value)
}

defineExpose({
  toggle,
  hide,
  show,
  scrollToTop,
})
</script>

<style scoped>
.dropdown-list {
  @apply flex w-full relative;
}

.dropdown-list-content {
  @apply w-full max-h-[300px] overflow-y-auto text-left bg-base-100;
}

.dropdown {
  @apply overflow-x-hidden rounded;
}
</style>
