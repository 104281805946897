<template>
  <Box flex :direction="inline ? 'row' : 'col'" spaceY="4" spaceX="4" justify="center" alignItems="center" p="2">
    <Box flex spaceX="2">
      <Icon icon="warning" color="error" />
      <Typography color="error" style="line-height: 1" bold wordBreak="anywhere">
        {{ errorInternal.title }} {{ errorInternal.message }}
      </Typography>
    </Box>
    <Button v-if="onBack" color="error" startIcon="arrow_back" @click="onBack()">
      Back
    </Button>
    <Button v-if="onRetry" color="error" startIcon="refresh" @click="onRetry()">
      Retry
    </Button>
    <slot>
      <Button v-if="onAction" :color="actionColor" :startIcon="actionIcon" @click="onAction()">
        {{ actionText }}
      </Button>
    </slot>
  </Box>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import Box from '../Box/Box.vue'
import Button from '../Button/Button.vue'
import Icon from '../Icon/Icon.vue'
import Typography from '../Typography/Typography.vue'

import { ButtonColor } from '../Button/types'

import { ErrorStateFormatter, useErrorStateFormatter } from './useErrorStateFormatter'

const props = defineProps<{
  error: unknown
  inline?: boolean
  onBack?: () => void
  onRetry?: () => void
  onAction?: () => void
  actionColor?: ButtonColor
  actionIcon?: string
  actionText?: string
}>()

defineEmits<{
  back: []
  retry: []
  action: []
}>()

const defaultFormatter: ErrorStateFormatter = (error) => {
  if (error && typeof error === 'object') {
    if ('message' in error) {
      return { title: String(error.message), message: '' }
    }
  }

  return { title: String(error), message: '' }
}

const formatter = useErrorStateFormatter() ?? defaultFormatter

const errorInternal = computed(() => formatter(props.error))
</script>
