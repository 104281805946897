<template>
  <input
    :id="id"
    v-model="modelValueInternal"
    :disabled="disabled"
    type="radio"
    :value="value"
    :name="name"
    class="radio border-2"
    :class="radioClasses"
  >
</template>

<script lang="ts" setup generic="T">
import { computed } from 'vue'

import { useVModel } from '@vueuse/core'

import { useDaisyUiAnimationCrutch } from '../../hooks'
import { checkForUndefined } from '../../utils'

import { colors, sizes } from './classes'
import { RadioProps } from './types'

const props = withDefaults(defineProps<RadioProps<T>>(), {
  disabled: false,
  color: 'secondary',
  size: 'sm',
})

const emit = defineEmits<{
  'update:modelValue': [T]
}>()

const modelValueInternal = useVModel(props, 'modelValue', emit)

const { isAnimationDisabled } = useDaisyUiAnimationCrutch(() => props.modelValue)

const radioClasses = computed(() => {
  const color = checkForUndefined(props.color, colors)
  const size = checkForUndefined(props.size, sizes)

  return {
    [color]: !!props.color,
    [size]: !!props.size,
    'no-animation': isAnimationDisabled.value,
  }
})
</script>

<style scoped>
.radio:checked {
  box-shadow: 0 0 0 3px currentcolor inset, 0 0 0 3px currentcolor inset;
}

.radio:not(:checked) {
  @apply border-divider;
}
</style>
