<template>
  <input
    :value="valueRef"
    type="range"
    class="slider-thumb"
    :class="thumbClasses"
    :disabled="disabled"
    :max="max"
    :min="min"
    :step="step"
    @input="handleInput"
  >
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { checkForUndefined } from '../../utils'

import { thumb } from './classes'
import { SliderThumbProps } from './types/SliderThumbTypes'

const props = withDefaults(defineProps<SliderThumbProps>(), {
  variant: 'solid',
  color: 'base',
  size: 'md',
  shape: 'circle',
  shouldUpdate: () => true,
})

const emits = defineEmits(['update:modelValue'])

const thumbClasses = computed(() => {
  const variant = checkForUndefined(props.variant, thumb.variant)
  const color = checkForUndefined(props.color, thumb.color)
  const size = checkForUndefined(props.size, thumb.size)
  const shape = checkForUndefined(props.shape, thumb.shape)

  return {
    [variant]: !!props.variant,
    [color]: !!props.color && !props.disabled,
    [size]: !!props.size,
    [shape]: !!props.shape,
    'slider-thumb-disabled': props.disabled,
  }
})

const valueRef = ref(props.modelValue)

const handleInput = (event: Event) => {
  const target = event.target as HTMLInputElement
  const shouldUpdate = props.shouldUpdate(+target.value, valueRef.value, event)

  if (shouldUpdate) {
    valueRef.value = +target.value
    emits('update:modelValue', valueRef.value, event)
  }

  target.value = `${valueRef.value}`

  // if (shouldUpdate) {
  // }
}
</script>

<style src="./styles/thumb.styles.css" scoped />
