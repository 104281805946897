<template>
  <FocusTrap :escapeDeactivates="false" :initialFocus="initialFocus" :active="focusTrapActive" delayInitialFocus>
    <div :class="[s.modal, s[`variant-${variant}`]]" @keydown.esc="onClose()">
      <div :class="s.backdrop" data-role="backdrop" />
      <div
        :class="s.contentWrapper"
        role="dialog"
        data-role="contentWrapper"
        tabindex="0"
        @click.self="onClose()"
      >
        <div
          :class="[s.content, s[`size-${size}`], {
            [s.fitContent!]: fitContent,
            [s.padded!]: !noPadding,
          }]"
          data-role="content"
        >
          <slot name="header">
            <div v-if="title" :class="s.title">
              <Typography variant="h6" color="textPrimary">
                {{ title }}
              </Typography>

              <ButtonIcon icon="close" @click="onClose()" />
            </div>
          </slot>

          <div :class="s.contentInner">
            <slot />
          </div>

          <div v-if="$slots.actions" :class="s.actions">
            <slot name="actions" />
          </div>
        </div>
      </div>
    </div>
  </FocusTrap>
</template>

<script setup lang="ts">
import { FocusTrap } from 'focus-trap-vue'
import { computed, useCssModule } from 'vue'

import { useScrollLock } from '@lasso/shared/hooks'

import { debouncedRef, useMounted } from '@vueuse/core'

import ButtonIcon from '../ButtonIcon/ButtonIcon.vue'
import Typography from '../Typography/Typography.vue'

const {
  variant,
  noSelfClose = false,
  size = 'md',
  fitContent = false,
  noPadding = false,
  title = '',
} = defineProps<{
  variant: 'modal' | 'drawer'
  noSelfClose?: boolean
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  initialFocus?: string | (() => HTMLElement | false)
  fitContent?: boolean
  noPadding?: boolean
  title?: string
}>()

const emit = defineEmits<{
  'close': []
}>()

const modelValue = defineModel<boolean>({ required: true })

const s = useCssModule()

useScrollLock(modelValue)

const initialFocusDelay = computed(() => {
  switch (variant) {
    case 'modal':
      return 0
    case 'drawer':
      return 300
  }
})

const focusTrapActive = debouncedRef(useMounted(), initialFocusDelay)

const onClose = () => {
  emit('close')

  if (!noSelfClose) {
    modelValue.value = false
  }
}
</script>

<style module>
.modal {
  position: fixed;
  inset: 0;
  z-index: 9999;
}

.backdrop {
  @apply bg-base-overlay;
  position: absolute;
  inset: 0;
}

.contentWrapper {
  position: absolute;
  inset: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  overscroll-behavior: contain;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.content {
  @apply bg-base-100 flex flex-col ;
  position: relative;
  box-sizing: border-box;
  cursor: default;
  overflow: hidden;

  /* Ensure content can be scrolled to the top */
  margin: auto 0;
}

.content:not(.fitContent) {
  flex-grow: 1;
}

.contentInner {
  @apply flex flex-col gap-4 text-14 tracking-15;
}

.title {
  @apply flex items-center justify-between gap-1;
}

.actions {
  @apply flex flex-wrap gap-2;
}

/* Variant modal */
.modal.variant-modal .contentWrapper {
  @apply p-6;
  justify-content: center;
}

.modal.variant-modal .content {
  @apply rounded-2xl shadow-2xl gap-4;
}

.modal.variant-modal .content.padded {
  @apply px-6 py-4;
}

.modal.variant-modal .contentInner {
  @apply gap-4;
}

.modal.variant-modal .actions {
  @apply justify-end;
}

/* Variant drawer */
.modal.variant-drawer .contentWrapper {
  @apply gap-6;
  justify-content: end;
}

.modal.variant-drawer .content {
  @apply gap-6;
  min-height: 100%;
}

.modal.variant-drawer .content.padded {
  @apply px-8 py-11;
}

.modal.variant-drawer .contentInner {
  @apply gap-6;
}

.modal.variant-drawer .actions {
  @apply justify-between;
}

/* Sizes modal */
.modal.variant-modal .content:is(.size-xs, .size-sm, .size-md) {
  max-width: 384px;
}

.modal.variant-modal .content.size-lg {
  max-width: 576px;
}

.modal.variant-modal .content.size-xl {
  max-width: 874px;
}

/* Sizes drawer */
.modal.variant-drawer .content.size-xs {
  max-width: 240px;
}

.modal.variant-drawer .content.size-sm {
  max-width: 320px;
}

.modal.variant-drawer .content.size-md {
  max-width: 384px;
}

.modal.variant-drawer .content.size-lg {
  max-width: 576px;
}

.modal.variant-drawer .content.size-xl {
  max-width: 874px;
}
</style>
