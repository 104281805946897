<template>
  <Box class="w-full" flex col spaceY="1">
    <Autocomplete2
      v-model="modelValueInternal"
      v-model:searchQuery="searchQueryInternal"
      :search="search"
      :placeholder="placeholder"
      :options="options"
      :icon="icon"
      :error="error"
      :loading="loading"
      :canLoadMore="canLoadMore"
      :withValue="withValue"
      :noOptionsMessage="noOptionsMessage"
      :objectValue="objectValue"
      variant="default"
      :multiple="true"
      hideSelectedValues
    />
    <Box v-if="selectedOptions.length > 0" flex col spaceY="1">
      <SelectedOption
        v-for="option in selectedOptions"
        :key="option.value"
        :option="option"
        :withValue="withValue"
        @deselect="deselectOption(option)"
      />
    </Box>
  </Box>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useVModel } from '@vueuse/core'

import { objFromEntries, truthy } from '@lasso/shared/utils'

import Box from '../Box/Box.vue'
import Autocomplete2 from '../Autocomplete2/Autocomplete.vue'

import type { SelectOptionType, SelectOptionValueType } from '../Select/types'

import SelectedOption from './SelectedOption.vue'

const props = withDefaults(defineProps<{
  modelValue: SelectOptionValueType[]
  search?: () => Promise<unknown>
  searchQuery?: string
  placeholder?: string
  options: SelectOptionType[]
  icon?: string
  error?: boolean
  loading?: boolean
  canLoadMore?: boolean
  withValue?: boolean
  noOptionsMessage?: string
  objectValue?: boolean
}>(), {
  placeholder: 'Search',
  icon: 'search',
  searchQuery: '',
})

const emit = defineEmits<{
  'update:modelValue': [SelectOptionValueType[]]
  'update:searchQuery': [string]
}>()

const modelValueInternal = useVModel(props, 'modelValue', emit)
const searchQueryInternal = useVModel(props, 'searchQuery', emit, { passive: true })

const isObjectValue = (value: SelectOptionValueType): value is SelectOptionType<any> => typeof value === 'object'
const getOptionValue = (optionValue: SelectOptionType['value']) => isObjectValue(optionValue) ? optionValue.value : optionValue

const optionsMap = computed((): Record<string, SelectOptionType> => {
  return objFromEntries(props.options.map(option => [
    getOptionValue(option.value),
    option] as const))
})

const selectedOptions = computed(() => {
  if (modelValueInternal.value.every(isObjectValue)) {
    return modelValueInternal.value
  }
  else {
    return modelValueInternal.value
      .map(value => optionsMap.value[getOptionValue(value)])
      .filter(truthy)
  }
})

const deselectOption = (option: SelectOptionType) => {
  modelValueInternal.value = modelValueInternal.value.filter(value => getOptionValue(value) !== getOptionValue(option.value))
}
</script>
