export const sizes = {
  xxs: 'w-6 h-6 text-xs',
  xs: 'w-8 h-8 text-xs',
  sm: 'w-12 h-12 text-sm',
  md: 'w-16 h-16',
  lg: 'w-20 h-20 text-lg',
}

export const colors = {
  neutral: 'ring-1 ring-neutral',
  primary: 'ring-1 ring-primary',
  secondary: 'ring-1 ring-secondary',
  success: 'ring-1 ring-success',
  warning: 'ring-1 ring-warning',
  error: 'ring-1 ring-error',
  info: 'ring-1 ring-info',
}

export const clickableClass
  = 'cursor-pointer select-none hover:shadow-md hover:scale-105 active:scale-100 active:shadow-none'
