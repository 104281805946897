<template>
  <div class="btn-group" :class="buttonGroupClasses">
    <slot />
  </div>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'
import { computed } from 'vue'

import type { ButtonGroupDirectionType, ButtonPropTypes } from './types'
import { directions } from './classes'

const props: ButtonPropTypes = defineProps({
  direction: {
    type: String as PropType<ButtonGroupDirectionType>,
    default: 'row',
    validator: (direction: ButtonGroupDirectionType) => !!directions[direction],
  },
})

const buttonGroupClasses = computed(() => {
  const { direction } = props

  return {
    [directions[direction]]: true,
  }
})
</script>
