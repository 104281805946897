const manageAndEmit = ['Start', 'Add', 'Remove', 'Update', 'End']
const emit = ['Choose', 'Unchoose', 'Sort', 'Filter', 'Clone']
const manage = ['Move']

const events = {
  manage,
  manageAndEmit,
  emit,
}

export { events }
