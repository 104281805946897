export const abbreviate = (sentence: string): string => {
  if (!sentence)
    return 'NA'

  const [first, second] = sentence.match(/\b([A-Za-z])/g) || []
  const firstLetter = (first || '').toUpperCase()
  const secondLetter = (second || '').toUpperCase()

  return `${firstLetter}${secondLetter}`
}
