import type {
  ListItemButtonActiveColor,
  ListItemButtonColor,
  ListItemButtonShape,
  ListItemButtonTextColor,
} from './types'

export const textColor: Record<ListItemButtonTextColor, string> = {
  textPrimary: 'text-textPrimary',
  textSecondary: 'text-textSecondary',
  textSecondaryLight: 'text-textSecondaryLight',
}

export const activeColor: Record<ListItemButtonActiveColor, string> = {
  base: 'bg-base-500',
  primary: 'bg-primary',
  secondary: 'bg-secondary',
  neutral: 'bg-neutral',
  info: 'bg-info',
}

export const color: Record<ListItemButtonColor, string> = {
  base: 'hover:bg-base-500 hover:text-base-content focus:bg-base-500 active:bg-base-500',
  primary: 'hover:bg-primary hover:text-primary-content active:bg-primary-focus',
  secondary: 'hover:bg-secondary hover:text-secondary-content active:bg-secondary-focus',
  neutral: 'hover:bg-neutral hover:text-neutral-content active:bg-neutral-focus',
  info: 'hover:bg-info hover:text-info-content active:bg-info-focus',
}

export const shape: Record<ListItemButtonShape, string> = {
  default: 'rounded',
  rounded: 'rounded-full',
  square: 'rounded-none',
}
