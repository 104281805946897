import {
  DenormalizedTree,
  NormalizedTree,
  useNormalizedTree,
  useNormalizedTreeHelpers,
} from '@lasso/shared/hooks'
import { createInjectionState } from '@lasso/shared/utils'

export type UseDraggableNestedState<T extends DenormalizedTree<T>> =
  Omit<ReturnType<typeof useNormalizedTree<T>>, 'normalizeTree' | 'denormalizeTree'>

export type InjectDraggableNestedState = <T extends DenormalizedTree<T>>() => UseDraggableNestedState<T>

const [provideState, injectState] = createInjectionState((state: any) => state)

export const useDraggableNestedState: InjectDraggableNestedState = injectState

export const provideDraggableNestedState = (normalizedTree: NormalizedTree<any>) => {
  provideState({
    normalizedTree,
    ...useNormalizedTreeHelpers(normalizedTree),
  } satisfies UseDraggableNestedState<any>)
}

export const useProvideDraggableNestedState: typeof useNormalizedTree = (options) => {
  const hookInstance = useNormalizedTree(options)

  return provideState(hookInstance)
}
