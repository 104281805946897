import { Ref, computed, ref, shallowRef, watch } from 'vue'

import { FileModel } from './types'

// TODO: rework FileUpload so this wouldn't be necessary
export const useFileUpload = ({
  fileModel = ref(null),
  formData = shallowRef(null),
}: {
  fileModel?: Ref<FileModel | null>
  formData?: Ref<FormData | null>
} = {}) => {
  watch(fileModel, (fileModel) => {
    if (!fileModel) {
      formData.value = null
    }
  })

  const file = computed<File | null>(() => {
    if (!formData.value) {
      return null
    }

    const file = Array.from(formData.value.values())[0]

    return file instanceof File ? file : null
  })

  const handleFileUpload = (_formData: FormData) => {
    formData.value = _formData
  }

  return { file, formData, fileModel, handleFileUpload }
}
