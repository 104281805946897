<template>
  <div
    :class="[s.cell, {
      [s.sticky!]: columnSticky,
      [s.lastSticky!]: lastStickyColumn,
      [s.lastStickyDivider!]: lastStickyColumn && lastStickyDivider,
      [s.hovered!]: hovered,
    }]"
    :style="{
      minWidth: `${column.width}px`,
      width: `${columnWidth}px`,
      height: `${rowHeight}px`,
      left: stickyOffset,
    }"
  >
    <div v-if="checkbox && columnIndex === 0" :class="s.checkboxWrapper">
      <Checkbox
        v-model="selectedInternal"
        :value="rowKeyValue"
        :disabled="checkboxDisabled"
      />
    </div>

    <Skeleton v-if="loading" height="1rem" />
    <div v-else :class="s.cellInner" class="typography-body2 text-textPrimary">
      <component :is="column.component" v-if="column.component" v-bind="columnComponentProps" />
      <Typography v-else display="block" variant="inherit" whiteSpace="nowrap" color="inherit" truncate>
        {{ value }}
      </Typography>
    </div>
  </div>
</template>

<script setup lang="ts" generic="T extends Record<string, unknown>, RowKey extends KeyOf<T>">
import { useVModel } from '@vueuse/core'
import { Ref, computed, useCssModule } from 'vue'

import { KeyOf } from '@lasso/shared/types'

import { Typography } from '../Typography'
import { Skeleton } from '../Skeleton'

import Checkbox from '../Checkbox/Checkbox.vue'

import { VirtualTableColumn, VirtualTableColumnSlotProps, VirtualTableColumnWidths } from './types'
import { useColumnsProps } from './useColumnsProps'

const props = defineProps<{
  row: T
  rowKeyValue: T[RowKey]
  rowIndex: number
  rowHeight: number
  columns: Array<Required<VirtualTableColumn<T>>>
  column: Required<VirtualTableColumn<T>>
  columnIndex: number
  columnWidths: VirtualTableColumnWidths<T>
  sticky: number
  loading: boolean
  checkbox: boolean
  checkboxDisabled: boolean
  selected: Array<T[RowKey]>
  scrollX: boolean
  hovered: boolean
}>()

const emit = defineEmits<{
  'update:selected': [Array<T[RowKey]>]
}>()

const s = useCssModule()

const selectedInternal = useVModel(props, 'selected', emit) as Ref<any>

const {
  getColumnWidth,
  isColumnSticky,
  getColumnStickyOffset,
} = useColumnsProps({
  columns: () => props.columns,
  columnWidths: () => props.columnWidths,
  sticky: () => props.sticky,
  checkbox: () => props.checkbox,
})

const columnWidth = computed((): number => getColumnWidth(props.column, props.columnIndex))
const columnSticky = computed(() => isColumnSticky(props.columnIndex))
const stickyOffset = computed(() => getColumnStickyOffset(props.columnIndex))
const lastStickyDivider = computed(() => props.sticky && props.scrollX)
const lastStickyColumn = computed(() => props.sticky && props.columnIndex === props.sticky - 1)

const value = computed((): string => {
  const value = props.row[props.column.id]

  if (value === undefined) {
    return ''
  }

  return props.column.modifier(value as Exclude<T[KeyOf<T>], undefined>, props.row, false)
})

const slotProps = computed((): VirtualTableColumnSlotProps<T> => {
  return {
    rowIndex: props.rowIndex,
    row: props.row,
    column: props.column,
    columnIndex: props.columnIndex,
    value: value.value,
    rawValue: props.row[props.column.id],
    hovered: props.hovered,
  }
})

const columnComponentProps = computed(() => {
  return props.column.componentProps(slotProps.value)
})
</script>

<style module>
.cell {
  @apply px-4 bg-base-100 border-b border-base-400;
  white-space: nowrap;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  min-width: 0;
  align-items: center;
  box-sizing: border-box;
}

.cell.hovered {
  @apply bg-base-200;
}

:global(.vue-recycle-scroller__item-view:last-child) .cell {
  border-bottom-color: transparent;
}

.cellInner {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.sticky {
  position: sticky;
  z-index: 1;
}

.lastSticky {
  @apply border-r;
  border-right-color: transparent;
}

.lastStickyDivider {
  @apply border-base-400;
}

.checkboxWrapper {
  @apply mr-2;
  display: flex;
  align-items: center;
}
</style>
