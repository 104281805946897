<template>
  <ul :class="listClasses">
    <slot />
  </ul>
</template>

<script lang="ts" setup>
import { computed, provide, reactive } from 'vue'
import type { PropType } from 'vue'

import type {
  ListItemButtonActiveColor,
  ListItemButtonColor,
  ListItemButtonShape,
  ListItemButtonTextColor,
} from '../ListItemButton/types'

import * as classes from '../ListItemButton/classes'

import type { ListProps } from './types'

// TODO find a unique key for <li>
// Possibly generate new symbol for each list item

const props: ListProps = defineProps({
  textColor: {
    type: String as PropType<ListItemButtonTextColor>,
    default: undefined,
    validator: (color: ListItemButtonTextColor) => !!classes.textColor[color],
  },
  color: {
    type: String as PropType<ListItemButtonColor>,
    default: undefined,
    validator: (color: ListItemButtonColor) => !!classes.color[color],
  },
  activeColor: {
    type: String as PropType<ListItemButtonActiveColor>,
    default: undefined,
    validator: (color: ListItemButtonActiveColor) => !!classes.activeColor[color],
  },
  shape: {
    type: String as PropType<ListItemButtonShape>,
    default: 'square',
    validator: (shape: ListItemButtonShape) => !!classes.shape[shape],
  },
  // selected: {
  //   type: [String, Number],
  //   default: undefined,
  // },
  dense: {
    type: Boolean,
    default: false,
  },
  disablePY: {
    // If true, vertical padding is removed from the list.
    type: Boolean,
    default: false,
  },
  disablePX: {
    // If true, horizontal padding is removed from the list.
    type: Boolean,
    default: false,
  },
  rounded: {
    type: Boolean,
    default: false,
  },
  withDivider: {
    type: Boolean,
    default: false,
  },
})

const listClasses = computed(() => {
  return {
    'py-2': !props.disablePY,
    'px-2': !props.disablePX,
  }
})

const state = reactive({
  // selected: props.selected,
  color: props.color,
  activeColor: props.activeColor,
  textColor: props.textColor,
  rounded: props.rounded,
  disablePX: props.disablePX,
  dense: props.dense,
  shape: props.shape,
})

provide('ListState', state)
</script>
