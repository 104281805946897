<template>
  <Box ref="panel" :p="panelPadding" flex col :width="panelWidth">
    <Box flex row justify="between" alignItems="center">
      <ButtonIcon
        :class="{ invisible: hideBack }"
        icon="navigate_before"
        :disabled="disabled"
        @click="monthChange(-1)"
      />
      <Box flex row justify="center">
        <Link
          class="mr-1"
          :class="{ active: mode === PanelMode.MonthView }"
          :disabled="disabled"
          @click="monthClick"
        >
          <Typography data-test-id="month" variant="body1">
            {{ month.toFormat(monthFormat) }}
          </Typography>
        </Link>
        <Link
          :class="{ active: mode === PanelMode.YearView }"
          :disabled="disabled"
          @click="yearClick"
        >
          <Typography data-test-id="year" variant="body1">
            {{ month.toFormat(yearFormat) }}
          </Typography>
        </Link>
      </Box>
      <ButtonIcon
        :class="{ invisible: hideForward }"
        icon="keyboard_arrow_right"
        :disabled="disabled"
        @click="monthChange(1)"
      />
    </Box>
    <Weekdays
      v-if="mode === PanelMode.DayView"
      :locale="locale"
      :firstDayOfTheWeek="firstDayOfTheWeek"
    />
    <Box id="panel-content" py="4" flex col justify="center" height="258px">
      <Days
        v-if="mode === PanelMode.DayView"
        :locale="locale"
        :firstDayOfTheWeek="firstDayOfTheWeek"
        :month="month"
        :max="max"
        :min="min"
        :disabled="disabled"
        :disabledPeriods="disabledPeriods"
        :disabledWeekdays="disabledWeekdays"
        :selectedDays="selectedDays"
        :periodDays="periodDays"
        :periodPreview="periodPreview"
        @dayClick="dayClick"
        @dayHover="dayHover"
      />
      <Months
        v-if="mode === PanelMode.MonthView"
        :locale="locale"
        :modelValue="month"
        :max="max"
        :min="min"
        :disabled="disabled"
        @update:modelValue="setMonth"
      />
      <Years
        v-if="mode === PanelMode.YearView"
        class="overflow-y-auto"
        :locale="locale"
        :modelValue="month"
        :max="max"
        :min="min"
        :disabled="disabled"
        @update:modelValue="setYear"
      />
    </Box>
    <slot name="bottom">
      <Box flex direction="row" justify="between" alignItems="center">
        <Button
          v-if="!hideToday"
          size="md"
          variant="outlined"
          :disabled="disabled"
          @click="setToday"
        >
          Today
        </Button>
        <Typography v-if="diff" variant="body2" color="textSecondary">
          {{ diff }}
        </Typography>
      </Box>
    </slot>
  </Box>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { DateTime } from 'luxon'

import Box from '../../Box/Box.vue'
import Button from '../../Button/Button.vue'
import ButtonIcon from '../../ButtonIcon/ButtonIcon.vue'
import Typography from '../../Typography/Typography.vue'
import Link from '../../Link/Link.vue'
import { Days, Months, Weekdays, Years } from '../../DatePicker'
import type { DateFormatTypes, DisabledPeriod } from '../types'

import { PanelMode } from './PanelType'

const props = withDefaults(
  defineProps<{
    month: DateTime
    min?: DateTime
    max?: DateTime
    selectedDays?: DateTime[] | []
    firstDayOfTheWeek?: number
    periodDays?: DateTime[] | []
    periodPreview?: DateTime[] | []
    disabled?: boolean
    disabledPeriods?: DisabledPeriod[] | []
    disabledWeekdays?: number[] | []
    dateFormat?: DateFormatTypes
    locale?: string
    monthFormat?: string
    yearFormat?: string
    noPadding?: boolean
    hideForward?: boolean
    hideBack?: boolean
    hideToday?: boolean
  }>(),
  {
    locale: 'en-US',
    dateFormat: 'MM/dd/yyyy',
    selectedDays: () => [],
    periodDays: () => [],
    periodPreview: () => [],
    disabledPeriods: () => [],
    disabledWeekdays: () => [],
    monthFormat: 'MMMM',
    yearFormat: 'yyyy',
    noPadding: false,
    hideForward: false,
    hideBack: false,
    hideToday: false,
  },
)

const emits = defineEmits<{
  monthChange: [number]
  setMonth: [DateTime]
  setDate: [DateTime]
  setYear: [DateTime]
  dayClick: [DateTime]
  dayHover: [DateTime]
}>()

const panelWidth = '326px'
const panel = ref<InstanceType<typeof Box> | null>(null)
const prevPanelWidth = ref(panelWidth)
const mode = ref<PanelMode>(PanelMode.DayView)

const diff = computed(() => {
  if (props.dateFormat === 'MM/dd/yyyy' && props.periodDays.length > 1) {
    const start = props.periodDays[0]!.startOf('day')
    const end = props.periodDays[1]!.startOf('day')
    const diff = end.diff(start, 'days').days
    const days = Math.abs(diff) + 1

    return `${days} day${days > 1 ? 's' : ''} selected`
  }
  return ''
})

const panelPadding = computed(() => {
  return props.noPadding ? '0' : '4'
})

const defaultView = computed(() => {
  switch (props.dateFormat) {
    case 'MM/yyyy':
    case 'MM/yy':
      return PanelMode.MonthView
    case 'MM/dd/yyyy':
    default:
      return PanelMode.DayView
  }
})

const monthChange = (diff: number) => {
  emits('monthChange', diff)
}

const setMonth = (month: DateTime) => {
  mode.value = defaultView.value
  emits('setMonth', month)
}

const setToday = () => {
  const day = DateTime.local()
  emits('setMonth', day)
  emits('setDate', day)
  mode.value = defaultView.value
}

const setYear = (year: DateTime) => {
  mode.value = PanelMode.MonthView
  emits('setYear', year)
}

const dayClick = (date: DateTime) => {
  emits('dayClick', date)
}

const dayHover = (date: DateTime) => {
  emits('dayHover', date)
}

const monthClick = () => {
  if (!panel.value) {
    return
  }
  if (mode.value !== PanelMode.MonthView) {
    // keep width
    prevPanelWidth.value = panel.value.$el.style.width
    panel.value.$el.style.width = `${panel.value.$el.getBoundingClientRect().width}px`
    mode.value = PanelMode.MonthView
  }
  else {
    // relax width
    panel.value.$el.style.width = prevPanelWidth.value
    mode.value = defaultView.value
  }
}

const yearClick = () => {
  if (!panel.value) {
    return
  }
  if (mode.value !== PanelMode.YearView) {
    // keep width
    prevPanelWidth.value = panel.value.$el.style.width
    panel.value.$el.style.width = `${panel.value.$el.getBoundingClientRect().width}px`
    mode.value = PanelMode.YearView
  }
  else {
    // relax width
    panel.value.$el.style.width = prevPanelWidth.value
    mode.value = defaultView.value
  }
}

mode.value = defaultView.value
</script>
