<template>
  <Box component="label" class="checkbox-button" :class="{ active, disabled }">
    <Box flex direction="row" spaceX="2" alignItems="center">
      <Checkbox
        :id="id"
        v-model="checked"
        :color="checked ? 'secondary' : undefined"
        :value="value as any"
        :name="name"
        :disabled="disabled"
        :indeterminate="indeterminate"
      />

      <slot name="beforeTitle" />

      <Typography
        variant="body2"
        :disabled="disabled"
        color="textPrimary"
        whiteSpace="nowrap"
      >
        {{ title }}
      </Typography>

      <slot name="afterTitle" />

      <Icon
        v-if="icon || $slots.icon"
        :icon="icon"
        :variant="iconVariant"
        class="checkbox-button-icon"
      >
        <slot name="icon" />
      </Icon>
    </Box>
  </Box>
</template>

<script lang="ts" setup generic="T extends boolean | any[]">
import { computed } from 'vue'
import { looseIndexOf } from '@vue/shared'

import Typography from '../Typography/Typography.vue'
import Box from '../Box/Box.vue'
import Checkbox from '../Checkbox/Checkbox.vue'
import Icon from '../Icon/Icon.vue'

import { IconVariantType } from '../Icon/types'

const props = withDefaults(
  defineProps<{
    id?: string
    modelValue: T
    value?: T extends any[] ? T[number] : never
    title: string
    name?: string
    icon?: string
    iconVariant?: IconVariantType
    disabled?: boolean
    indeterminate?: boolean
  }>(),
  {
    id: undefined,
    value: undefined,
    name: undefined,
    icon: undefined,
    iconVariant: undefined,
    disabled: undefined,
    indeterminate: undefined,
  },
)

const emits = defineEmits<{
  'update:modelValue': [T]
}>()

const checked = computed({
  get: () => props.modelValue,
  set: (value) => {
    emits('update:modelValue', value)
  },
})

const active = computed(() => {
  if (Array.isArray(props.modelValue)) {
    return looseIndexOf(props.modelValue, props.value) !== -1
  }
  else {
    return props.modelValue
  }
})
</script>

<style scoped>
.checkbox-button {
  @apply inline-flex min-h-[36px] py-1.5 px-4 ring-1 ring-inset ring-divider rounded-[12px] whitespace-nowrap;
}

.checkbox-button .checkbox-button-icon {
  @apply text-textDisabled;
}

.checkbox-button:not(.disabled) {
  @apply cursor-pointer;
}

.checkbox-button:not(.disabled):hover {
  @apply ring-secondary-light-focus bg-secondary-light;
}

.checkbox-button:not(.disabled) .checkbox-button-icon {
  @apply text-textDisabled;
}

.checkbox-button.active {
  @apply ring-secondary-light-focus bg-secondary-light;
}

.checkbox-button.active .checkbox-button-icon {
  @apply text-textSecondary;
}

.checkbox-button.disabled {
  @apply pointer-events-none;
}
</style>
