<template>
  <Box flex spaceX="2" alignItems="center">
    <slot name="chip" />
    <Box flex spaceX="2" alignItems="center" grow="grow" minWidth="0px">
      <Box flex alignItems="center" grow="grow" minWidth="0px">
        <Link
          v-if="clickable"
          ref="titleRef"
          :class="titleClasses"
          :target="target"
          :color="hovered ? 'secondary' : 'base'"
          :to="href ?? undefined"
          @click="emit('click')"
        >
          <slot />
        </Link>
        <div
          v-else
          ref="titleRef"
          :class="titleClasses"
        >
          <slot />
        </div>
      </Box>
      <slot name="extra" />
      <Box
        v-if="hovered && $slots.actions"
        flex
        spaceX="2"
        alignItems="center"
      >
        <slot name="actions" />
      </Box>
      <Box v-if="$slots.dropdown">
        <DropdownList
          :width="dropdownWidth"
          :contentDataTestId="dropdownDataTestId"
          @click.stop
        >
          <template #default="{ state }">
            <ButtonIcon
              v-show="hovered || state.isVisible"
              icon="more_vert"
            />
          </template>
          <template #dropdown="tippyInstance">
            <slot name="dropdown" v-bind="tippyInstance" />
          </template>
        </DropdownList>
      </Box>
    </Box>
  </Box>
</template>

<script setup lang="ts">
import { ComponentPublicInstance, computed, shallowRef } from 'vue'

import Box from '../../Box/Box.vue'
import DropdownList from '../../DropdownList/DropdownList.vue'
import ButtonIcon from '../../ButtonIcon/ButtonIcon.vue'
import Link from '../../Link/Link.vue'

const props = withDefaults(
  defineProps<{
    clickable?: boolean
    hovered?: boolean
    href?: string | null
    dropdownWidth?: string
    dropdownDataTestId?: string
    target?: string
    fullWidth?: boolean
  }>(),
  {
    clickable: false,
    hovered: false,
    dropdownWidth: '120px',
  },
)

const emit = defineEmits<{
  click: []
}>()

const titleRef = shallowRef<ComponentPublicInstance>()

const titleClasses = computed(() => {
  const { fullWidth } = props

  return {
    'truncate': true,
    'max-w-full': true,
    'inline-block': true,
    'grow': fullWidth ? 'grow' : undefined,
  }
})
</script>
