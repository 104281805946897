import { ToastColor } from '@lasso/shared/hooks'

import { TypographyColor } from '../Typography/types'

export const colors: Record<ToastColor, string> = {
  base: 'bg-base-300',
  success: 'bg-success-light',
  error: 'bg-error-light',
  warning: 'bg-warning-light',
  info: 'bg-info-light',
}

export const icons: Record<ToastColor, string> = {
  base: 'star',
  success: 'check_circle_outline',
  error: 'sentiment_dissatisfied',
  warning: 'warning_amber',
  info: 'info',
}

export const iconColors: Record<ToastColor, TypographyColor> = {
  base: 'textSecondary',
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info',
}
