<template>
  <Box flex direction="col">
    <Transitions name="fade" mode="out-in">
      <Box
        v-if="!modelValue"
        v-bind="$attrs"
        component="label"
        flex
        alignItems="center"
        justify="center"
        direction="col"
        spaceY="3"
        :height="dropzoneHeight"
        :class="dropBoxClasses"
        @dragover.prevent="handleDragOver"
        @drop.prevent="handleFileDrop"
        @dragleave="handleDragLeave"
      >
        <input
          ref="fileInputElement"
          type="file"
          name="file"
          class="sr-only"
          :accept="accept"
          :disabled="disabled"
          @change="handleFileSelect"
        >
        <Box flex>
          <Typography variant="h6" color="textPrimary">
            Drag your file here, or&nbsp;
          </Typography>
          <Typography variant="h6" :color="color" class="cursor-pointer">
            browse
          </Typography>
        </Box>
        <Typography variant="caption1" color="textSecondaryLight">
          {{ supportedFilesText }}
        </Typography>
      </Box>
      <Box v-else flex direction="col" spaceY="4">
        <slot name="preview" />
        <Box
          p="2"
          flex
          spaceX="2"
          alignItems="center"
          class="rounded border border-divider"
        >
          <Icon icon="file_present" variant="outlined" />
          <Box flex spaceX="2" alignItems="baseline" flexGrow="flex-1">
            <Typography variant="caption1" color="textSecondary">
              {{ modelValue.originalFileName }}
            </Typography>
            <Box
              v-if="loading"
              width="80px"
              height="8px"
              flex
              alignItems="center"
            >
              <ProgressBar :color="color" />
            </Box>
          </Box>
          <ButtonIcon
            icon="delete"
            iconVariant="outlined"
            :disabled="disabled"
            @click="handleDeleteButtonClick"
          />
        </Box>
      </Box>
    </Transitions>
  </Box>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import type { PropType } from 'vue'

import { useToast } from '@lasso/shared/hooks'

import Typography from '../Typography/Typography.vue'
import Box from '../Box/Box.vue'
import Icon from '../Icon/Icon.vue'
import ButtonIcon from '../ButtonIcon/ButtonIcon.vue'
import Transitions from '../Transitions/Transitions.vue'
import ProgressBar from '../ProgressBar/ProgressBar.vue'

import { checkForUndefined } from '../../utils'

import * as classes from './classes'

import type {
  FileModel,
  FileUploadColor,
  FileUploadProps,
} from './types'

const props: FileUploadProps = defineProps({
  modelValue: {
    type: Object as PropType<FileModel | null>,
    default: null,
  },
  color: {
    type: String as PropType<FileUploadColor>,
    default: 'primary',
  },
  allowedFileTypes: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
  dropzoneHeight: {
    type: String,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits<{
  (e: 'fileUpload', form: FormData): void
  (e: 'update:modelValue', value: FileModel | null): void
}>()

const toast = useToast()

const accept = computed(() =>
  props.allowedFileTypes ? props.allowedFileTypes.join(', ') : '',
)
const supportedFilesText = computed(() =>
  props.allowedFileTypes
    ? `Supports ${props.allowedFileTypes.map(fileName => fileName.slice(1).toUpperCase()).join(', ')}`
    : '',
)

const fileInputElement = ref<HTMLInputElement | null>(null)
const isDragging = ref(false)
const isExtensionAllowed = (fileName: string) => {
  return (
    !props.allowedFileTypes
    || new RegExp(
      `(${props.allowedFileTypes.join('|').replace(/\./g, '\\.')})$`,
    ).test(fileName)
  )
}

const uploadFile = (fileToUpload: File) => {
  const form = new FormData()
  form.append('file', fileToUpload)

  if (isExtensionAllowed(fileToUpload.name)) {
    emits('update:modelValue', {
      filePreviewJSON: '',
      filename: '',
      headers: [],
      headersJSON: '',
      originalFileName: fileToUpload.name,
    })
    emits('fileUpload', form)
  }
  else {
    toast.error('Invalid file type')
  }
}

const handleDragOver = () => {
  if (!props.disabled) {
    isDragging.value = true
  }
}

const handleDragLeave = () => {
  isDragging.value = false
}

const handleFileDrop = (event: DragEvent) => {
  isDragging.value = false

  if (
    !props.disabled
    && event.dataTransfer?.files
    && event.dataTransfer.files[0]
  ) {
    uploadFile(event.dataTransfer?.files[0])
  }
}

const handleFileSelect = () => {
  if (fileInputElement.value?.files && fileInputElement.value?.files[0]) {
    uploadFile(fileInputElement.value?.files[0])
  }
}

const handleDeleteButtonClick = () => {
  emits('update:modelValue', null)
}

const dropBoxClasses = computed(() => {
  const color = checkForUndefined(props.color, classes.colors)

  return {
    'bg-base-200 border-base-500': !isDragging.value,
    'border border-dashed rounded-xl transition-colors duration-200': true,
    [color]: !!props.color && isDragging.value,
  }
})
</script>
