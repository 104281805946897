import type { LinkAnimation, LinkColor, LinkVariant } from './types'

export const variant: Record<LinkVariant, string> = {
  default: 'link-default',
  underlined: 'link-underlined',
}

export const color: Record<Exclude<LinkColor, 'default'>, string> = {
  base: 'link-base',
  neutral: 'link-neutral',
  primary: 'link-primary',
  secondary: 'link-secondary',
}

export const animation: Record<LinkAnimation, string> = {
  none: 'link-animation-none',
  zoomOut: 'link-animation-zoom-out',
}
