<template>
  <Box width="100%" flex direction="row" alignItems="center" spaceX="2" class="input-icon" :class="{ disabled }">
    <Icon v-if="icon && !hide" :icon="icon" size="sm" class="start-input-icon" />

    <Box flex width="100%" grow="grow" wrap="nowrap" class="input-icon-inner" alignItems="center">
      <slot />
    </Box>

    <slot name="inputEnd">
      <Icon v-if="endIcon && !hide" :icon="endIcon" size="sm" class="end-input-icon" :class="endIconClasses" @click="$emit('clickEndIcon')" />
    </slot>
  </Box>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import Box from '../Box/Box.vue'

import Icon from '../Icon/Icon.vue'

const props = withDefaults(defineProps<{
  icon?: string
  endIcon?: string
  hide?: boolean
  disabled?: boolean
  onClickEndIcon?: () => void
}>(), {
  icon: '',
  endIcon: '',
  hide: false,
  disabled: false,
})

defineEmits<{
  'clickEndIcon': []
}>()

const endIconClasses = computed(() => {
  return {
    'cursor-pointer': props.onClickEndIcon,
  }
})
</script>

<style scoped>
.input-icon .start-input-icon,
.input-icon .end-input-icon {
  @apply text-textSecondaryLight select-none leading-none;
}
.input-icon.disabled .start-input-icon,
.input-icon.disabled .end-input-icon {
  @apply text-textDisabled;
}

.input-icon-inner {
  @apply gap-1 overflow-hidden;
}
</style>
