<template>
  <div v-if="active">
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { computed, inject } from 'vue'

import type { ViewSwitchPropTypes } from '../ViewSwitch/types'

import type { ViewSwitchItemPropTypes } from './types'

const props: ViewSwitchItemPropTypes = defineProps({
  value: {
    type: [String, Number],
    required: true,
  },
})

const viewSwitchProps = inject<ViewSwitchPropTypes>('viewSwitchProps', {})

const active = computed(() => viewSwitchProps.active === props.value)
</script>
