import type { InputWrapperSize, InputWrapperVariant } from './types'

export const variant: Record<InputWrapperVariant, string> = {
  default: 'input-wrapper-default',
  outlined: 'input-wrapper-outlined',
  ghost: 'input-wrapper-ghost',
}

export const size: Record<InputWrapperSize, string> = {
  sm: 'input-wrapper-sm',
  md: 'input-wrapper-md',
  lg: 'input-wrapper-lg',
}
