<template>
  <div class="auto-grid">
    <slot />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { AutoGridGap } from './types'
import { gapMap } from './consts'

const props = withDefaults(defineProps<{
  gap?: AutoGridGap
  rowGap?: AutoGridGap
  colGap?: AutoGridGap
  maxCol: string | number
  minColWidth: string
}>(), { gap: 'px' })

const gapCss = computed(() => {
  return (props.rowGap || props.colGap)
    ? `${gapMap[props.rowGap ?? props.gap]} ${gapMap[props.colGap ?? props.gap]}`
    : gapMap[props.gap]
})
const colGapCss = computed(() => gapMap[props.colGap ?? props.gap])
</script>

<style scoped>
.auto-grid {
    --grid-layout-gap: v-bind(gapCss);
    --grid-col-gap: v-bind(colGapCss);
    --grid-column-count: v-bind(maxCol);
    --grid-item--min-width: v-bind(minColWidth);
    --gap-count: calc(var(--grid-column-count) - 1);
    --total-gap-width: calc(var(--gap-count) * var(--grid-col-gap));
    --grid-item--max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(100%, max(var(--grid-item--min-width), var(--grid-item--max-width))), 1fr));
    grid-gap: var(--grid-layout-gap);
}
</style>
