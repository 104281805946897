<template>
  <LinkBase :to="to" :class="linkClasses" :disabled="disabled">
    <slot />
  </LinkBase>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { checkForUndefined } from '../../utils'
import LinkBase from '../LinkBase/LinkBase.vue'

import type { LinkProps } from './types'
import * as classes from './classes'

const props = withDefaults(defineProps<LinkProps>(), {
  to: '',
  variant: 'default',
  color: 'base',
  animation: 'none',
  fullWidth: false,
  hoverUnderline: false,
  disabled: false,
})

const linkClasses = computed(() => {
  const variant = checkForUndefined(props.variant, classes.variant)
  const color = checkForUndefined(props.color, classes.color)
  const animation = checkForUndefined(props.animation, classes.animation)

  return {
    [variant]: !!props.variant,
    [color]: !!props.color && !props.disabled,
    [animation]: !!props.animation,
    'link-hover': props.hoverUnderline,
    'w-full': props.fullWidth,
    'link-disabled': props.disabled,
  }
})
</script>

<style scoped src="./link.styles.css"></style>
