<template>
  <button :type="type" class="button" :class="buttonClasses" :disabled="disabled || loading" @click="onClick">
    <slot name="start">
      <CircularProgress v-if="loading" class="mr-1" />
      <Icon v-if="!loading && startIcon" :icon="startIcon" variant="round" class="mr-1" />
    </slot>
    <slot>
      {{ label }}
    </slot>
    <slot name="end">
      <Icon v-if="endIcon" :icon="endIcon" variant="round" class="ml-1" />
    </slot>
  </button>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { checkForUndefined } from '../../utils'

import Icon from '../Icon/Icon.vue'
import CircularProgress from '../CircularProgress/CircularProgress.vue'

import type { ButtonProps } from './types'
import * as classes from './classes'

const props = withDefaults(defineProps<ButtonProps>(), {
  type: 'button',
  variant: 'default',
  color: 'default',
  size: 'md',
  shape: 'none',
  isActive: false,
  fullWidth: false,
  isWide: false,
  disabled: false,
  loading: false,
  block: false,
})

const emits = defineEmits(['click'])

const buttonClasses = computed(() => {
  const { fullWidth, isActive, isWide, block } = props
  const color = checkForUndefined(props.color, classes.color)
  const variant = checkForUndefined(props.variant, classes.variant)
  const size = checkForUndefined(props.size, classes.size)
  const shape = checkForUndefined(props.shape, classes.shape)

  return {
    [color]: !!props.color,
    [size]: !!props.size,
    [variant]: !!props.variant,
    [shape]: !!props.shape,
    'button-active': isActive,
    'btn-block': fullWidth,
    'btn-wide': isWide,
    'button-block': block,
  }
})

const onClick = (e: Event) => {
  if (!props.disabled && !props.loading) {
    emits('click', e)
  }
}
</script>

<style scoped src="./button.styles.css" />
