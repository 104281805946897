<template>
  <DraggableNestedBase
    v-bind="{
      itemComponent,
      itemProps,
      group,
      convertItem,
      maxDepth,
      draggableProps,
      emptyInsertThreshold,
    }"
  />
</template>

<script setup lang="ts" generic="T extends { items?: T[]; }">
import { Ref } from 'vue'
import { useVModel } from '@vueuse/core'

import { DraggableNestedProps } from './types'

import { useProvideDraggableNestedState } from './useDraggableNestedState'
import DraggableNestedBase from './DraggableNestedBase.vue'

const props = withDefaults(defineProps<DraggableNestedProps<T>>(), {
  maxDepth: Infinity,
})

const emit = defineEmits<{
  'update:modelValue': [T]
}>()

const modelValueInternal = useVModel(props, 'modelValue', emit) as Ref<T>

useProvideDraggableNestedState(modelValueInternal)
</script>
