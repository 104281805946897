<template>
  <button type="button" class="button-icon" :class="buttonClasses" :disabled="disabled" @click="onClick">
    <slot>
      <Icon :icon="icon" :variant="iconVariant" />
    </slot>
  </button>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { checkForUndefined } from '../../utils'
import Icon from '../Icon/Icon.vue'

import type { ButtonIconProps } from './types'
import * as classes from './classes'

const props = withDefaults(defineProps<ButtonIconProps>(), {
  variant: 'text',
  color: 'default',
  size: '3xs',
  isActive: false,
  disabled: false,
  shape: 'none',
  iconVariant: 'round',
  block: false,
})

const emit = defineEmits(['click'])

const buttonClasses = computed(() => {
  const { isActive, block } = props
  const color = checkForUndefined(props.color, classes.color)
  const variant = checkForUndefined(props.variant, classes.variant)
  const size = checkForUndefined(props.size, classes.size)
  const shape = checkForUndefined(props.shape, classes.shape)

  return {
    [variant]: !!variant,
    [color]: !!color,
    [size]: !!size,
    [shape]: !!shape,
    'button-icon-active': isActive,
    'button-icon-block': block,
  }
})

const onClick = (e: Event) => {
  emit('click', e)
}
</script>

<style scoped src="./buttonIcon.styles.css" />
