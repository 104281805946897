export const colors = {
  default: 'chip-base',
  primary: 'chip-primary',
  secondary: 'chip-secondary',
  info: 'chip-info',
  accent: 'chip-accent',
  success: 'chip-success',
  warning: 'chip-warning',
  error: 'chip-error',
}

export const variants = {
  default: 'chip-default',
  outlined: 'chip-outlined',
  filled: 'chip-filled',
}

export const sizes = {
  '3xs': 'chip-3xs', // 16px
  '2xs': 'chip-2xs', // 20px
  'xs': 'chip-xs', // 24px
  'sm': 'chip-sm', // 28px
}
