<template>
  <Tooltip
    :content="tooltip"
    placement="top"
    class="tab-wrapper"
  >
    <a class="tab" :class="tabClasses" @click="handleTabClick">
      <slot />
    </a>
  </Tooltip>
</template>

<script lang="ts" setup>
import { computed, inject } from 'vue'

import { checkForUndefined } from '../../utils'

import type { TabsProvidedHandlers, TabsProvidedProps } from '../Tabs/types'

import Tooltip from '../Tooltip/Tooltip.vue'

import * as classes from './classes'

const props = defineProps<{
  value: string | number | symbol
  error?: boolean
  tooltip?: string
}>()
const tabsProps = inject<TabsProvidedProps>('tabsProps', {})
const tabsHandlers = inject<TabsProvidedHandlers>('tabsHandlers')

const active = computed(() => tabsProps?.active === props.value)

const handleTabClick = (e: Event) => {
  tabsHandlers?.handleActive({ event: e, value: props.value })
  return e
}

const tabClasses = computed(() => {
  const color = checkForUndefined(tabsProps.color, classes.color)
  const variant = checkForUndefined(tabsProps.variant, classes.variant)
  const size = checkForUndefined(tabsProps.size, classes.size)

  return {
    [color]: !!tabsProps.color,
    [size]: !!tabsProps.size,
    [variant]: !!tabsProps.variant,
    'tab-active': active.value,
    'tab-error': props.error,
  }
})
</script>

<style scoped src="./tabsItem.styles.css"></style>
