<template>
  <Box class="skeleton" :class="skeletonClasses" :width="width" :height="height" />
</template>

<script setup lang="ts">
import { computed } from 'vue'

import Box from '../Box/Box.vue'

import { checkForUndefined } from '../../utils'

import * as classes from './classes'
import type { SkeletonProps } from './types'

const props = withDefaults(defineProps<SkeletonProps>(), {
  width: '100%',
  height: '20px',
  fade: false,
  rounded: 'default',
})

const skeletonClasses = computed(() => {
  const rounded = checkForUndefined(props.rounded, classes.rounded)

  return {
    [rounded]: !!props.rounded,
    fade: props.fade,
  }
})
</script>

<style scoped src="./skeleton.styles.css" />
