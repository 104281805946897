<template>
  <span class="icon" :class="iconClasses">
    <slot>
      {{ icon }}
    </slot>
  </span>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { checkForUndefined } from '../../utils'

import { color as iconColors } from '../Typography/classes'

import type { IconPropTypes } from './types'
import * as classes from './classes'

const props = withDefaults(defineProps<IconPropTypes>(), {
  icon: '',
  color: 'inherit',
  size: 'sm',
  variant: 'filled',
})

const iconClasses = computed(() => {
  const color = checkForUndefined(props.color, iconColors)
  const size = checkForUndefined(props.size, classes.size)
  const variant = checkForUndefined(props.variant, classes.variant)

  return {
    [color]: !!props.color,
    [size]: !!props.size,
    [variant]: !!props.variant,
  }
})
</script>

<style scoped src="./icon.styles.css" />
