<template>
  <Box v-show="!disabled" ref="rootRef" flex justify="center">
    <slot v-if="!invisible">
      <CircularProgress :size="size" :color="color" />
    </slot>
  </Box>
</template>

<script setup lang="ts">
import { ComponentPublicInstance, computed, onActivated, onDeactivated, onUnmounted, ref, shallowRef, toRefs, watch } from 'vue'

import { whenever } from '@vueuse/core'

import { getScrollParent, truthy } from '@lasso/shared/utils'

import { useMutableWatchers } from '@lasso/shared/hooks'

import CircularProgress from '../CircularProgress/CircularProgress.vue'
import Box from '../Box/Box.vue'
import { CircularProgressSize } from '../CircularProgress/types'
import { TypographyColor } from '../Typography/types'

const props = withDefaults(
  defineProps<{
    fetchNextPage: () => Promise<unknown>
    threshold?: number
    disabled?: boolean
    invisible?: boolean
    size?: CircularProgressSize
    color?: TypographyColor
  }>(),
  {
    threshold: 100,
    disabled: false,
    invisible: false,
  },
)

const { disabled, threshold } = toRefs(props)

const loading = ref(false)
const active = ref(true)
const rootRef = ref<ComponentPublicInstance>()
const scrollEl = computed(() => {
  /* v8 ignore next 3 */
  if (!rootRef.value) {
    return null
  }

  const parent = getScrollParent(rootRef.value.$el)

  return (!parent || parent === document.documentElement) ? document : parent
})
const observer = shallowRef<IntersectionObserver | null>(null)

const onIntersecting = async () => {
  loading.value = true

  try {
    await props.fetchNextPage()
  }
  finally {
    loading.value = false
  }
}

const initObserver = (): void => {
  observer.value?.disconnect()

  observer.value = new IntersectionObserver(
    (entries) => {
      const intersecting = entries.some(({ isIntersecting }) => isIntersecting)

      if (intersecting) {
        void onIntersecting()
      }
    },
    {
      root: scrollEl.value,
      rootMargin: `${threshold.value}px`,
    },
  )
}

const onUpdate = (): void => {
  /* v8 ignore next 3 */
  if (!observer.value) {
    return
  }

  const el = rootRef.value?.$el
  const enabled = !disabled.value && !loading.value && active.value && truthy(el)

  if (enabled) {
    observer.value.observe(el)
  }
  else if (el) {
    observer.value.unobserve(el)
  }
}

const { setWatchers } = useMutableWatchers()

whenever(rootRef, () => {
  setWatchers([
    watch(
      threshold,
      () => {
        initObserver()
      },
      { flush: 'post', immediate: true },
    ),

    watch(
      [disabled, loading, active, observer],
      () => {
        onUpdate()
      },
      {
        // Ensure intersection observer is re-triggered
        // when e.g. loading is changed to true and back in a single tick
        flush: 'sync',
        immediate: true,
      },
    ),
  ])
})

onUnmounted(() => {
  observer.value?.disconnect()
})

onActivated(() => {
  active.value = true
})

onDeactivated(() => {
  active.value = false
})
</script>
