<template>
  <Box component="label" class="radio-button" :class="{ active, disabled }">
    <Box flex direction="row" spaceX="2" alignItems="center">
      <Radio
        :id="id"
        v-model="checked"
        class="radio-button-input"
        :color="checked ? 'secondary' : undefined"
        :value="value as any"
        :name="name"
        :disabled="disabled"
      />

      <slot name="beforeTitle" />

      <Typography variant="body2" :disabled="disabled" color="textPrimary" whiteSpace="nowrap">
        {{ title }}
      </Typography>

      <slot name="afterTitle" />

      <Icon v-if="icon || $slots.icon" :icon="icon" :variant="iconVariant" class="radio-button-icon">
        <slot name="icon" />
      </Icon>
    </Box>
  </Box>
</template>

<script lang="ts" setup generic="T">
import { computed } from 'vue'

import Typography from '../Typography/Typography.vue'
import Box from '../Box/Box.vue'
import Radio from '../Radio/Radio.vue'
import Icon from '../Icon/Icon.vue'

import { IconVariantType } from '../Icon/types'

const props = withDefaults(defineProps<{
  id?: string
  modelValue: T
  value?: T
  title: string
  name?: string
  icon?: string
  iconVariant?: IconVariantType
  disabled?: boolean
}>(), {
  id: undefined,
  value: undefined,
  title: undefined,
  name: undefined,
  icon: undefined,
})

const emits = defineEmits<{
  'update:modelValue': [T]
}>()

const checked = computed({
  get: () => props.modelValue,
  set: (value) => {
    emits('update:modelValue', value)
  },
})

const active = computed(() => props.modelValue === props.value)
</script>

<style scoped>
.radio-button {
  @apply inline-flex min-h-[36px] py-1.5 px-4 ring-1 ring-inset ring-divider rounded-[12px] whitespace-nowrap;
}

.radio-button .radio-button-icon {
  @apply text-textDisabled;
}

.radio-button:not(.disabled) {
  @apply cursor-pointer;
}

.radio-button:not(.disabled):hover {
  @apply ring-secondary-light-focus bg-secondary-light;
}

.radio-button:not(.disabled) .radio-button-icon {
  @apply text-textDisabled;
}

.radio-button.active {
  @apply ring-secondary-light-focus bg-secondary-light;
}

.radio-button.active .radio-button-icon {
  @apply text-textSecondary;
}

.radio-button.disabled {
  @apply pointer-events-none;
}

.radio-button-input:disabled {
  opacity: 1 !important;
}
</style>
