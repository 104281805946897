import { CircularProgressSize } from './types'

export const size: Record<CircularProgressSize, string> = {
  'xs': 'circular-progress-xs', // 12px
  'sm': 'circular-progress-sm', // 16px // default
  'md': 'circular-progress-md', // 20px
  'lg': 'circular-progress-lg', // 24px
  'xl': 'circular-progress-xl', // 28px
  '2xl': 'circular-progress-2xl', // 32px
  '3xl': 'circular-progress-3xl', // 36px
  '4xl': 'circular-progress-4xl', // 40px
  '5xl': 'circular-progress-5xl', // 44px
  '6xl': 'circular-progress-6xl', // 48px
}
