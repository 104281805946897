<template>
  <Box flex col class="slider-container" width="100%">
    <SliderTrack :disabled="disabled" :upperProgress="progress" :color="color" :containerSize="size">
      <SliderThumb
        :modelValue="modelValue"
        :min="min"
        :max="max"
        :step="step"
        :disabled="disabled"
        :color="color"
        :variant="variant"
        :size="size"
        @update:modelValue="handleInput"
      />
    </SliderTrack>
    <div v-if="$slots.default" class="slider-ticks-container" :class="ticksContainerClasses" :disabled="disabled">
      <slot />
    </div>
  </Box>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import Box from '../Box/Box.vue'
import SliderTrack from '../SliderBase/SliderTrack.vue'
import SliderThumb from '../SliderBase/SliderThumb.vue'

import { SliderProps } from './types'

const props = withDefaults(defineProps<SliderProps>(), {
  min: 0,
  max: 100,
  step: 1,
  disabled: false,
  variant: 'solid',
  color: 'base',
  size: 'md',
})

const emits = defineEmits(['update:modelValue'])

const ticksContainerClasses = computed(() => ({
  'slider-ticks-container-disabled': props.disabled,
}))

const progress = computed(() => ((+props.modelValue - props.min) / (props.max - props.min)) * 100)

const handleInput = (value: number) => {
  emits('update:modelValue', value)
}
</script>

<style src="./slider.styles.css" scoped />
