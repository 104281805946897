<template>
  <div :class="gridClasses" :style="styles" v-bind="$attrs">
    <slot />
  </div>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'
import { computed } from 'vue'

import { checkForUndefined } from '../../utils'

import type * as Types from './types'
import * as classes from './classes'

const props: Types.GridProps = defineProps({
  container: {
    type: Boolean,
    default: false,
  },
  item: {
    type: Boolean,
    default: false,
  },
  flow: {
    type: String as PropType<Types.GridFlow>,
    default: undefined,
    validator: (flow: Types.GridFlow) => !!classes.flow[flow],
  },
  justifyItems: {
    type: String as PropType<Types.GridJustifyItems>,
    default: undefined,
    validator: (justifyItems: Types.GridJustifyItems) => !!classes.justifyItems[justifyItems],
  },
  justifySelf: {
    type: String as PropType<Types.GridJustifySelf>,
    default: undefined,
    validator: (justifySelf: Types.GridJustifySelf) => !!classes.justifySelf[justifySelf],
  },
  alignContent: {
    type: String as PropType<Types.GridAlignContent>,
    default: undefined,
    validator: (alignContent: Types.GridAlignContent) => !!classes.alignContent[alignContent],
  },
  alignItems: {
    type: String as PropType<Types.GridAlignItems>,
    default: undefined,
    validator: (alignItems: Types.GridAlignItems) => !!classes.alignItems[alignItems],
  },
  alignSelf: {
    type: String as PropType<Types.GridAlignSelf>,
    default: undefined,
    validator: (alignSelf: Types.GridAlignSelf) => !!classes.alignSelf[alignSelf],
  },
  gap: {
    type: String as PropType<Types.GridSpacing>,
    default: undefined,
    validator: (gap: Types.GridSpacing) => !!classes.gap[gap],
  },
  gapX: {
    type: String as PropType<Types.GridSpacing>,
    default: undefined,
    validator: (gapX: Types.GridSpacing) => !!classes.gapX[gapX],
  },
  gapY: {
    type: String as PropType<Types.GridSpacing>,
    default: undefined,
    validator: (gapY: Types.GridSpacing) => !!classes.gapY[gapY],
  },
  colSpan: {
    type: String as PropType<Types.GridSpan>,
    default: undefined,
    validator: (colSpan: Types.GridSpan) => !!classes.colSpan[colSpan],
  },
  rowSpan: {
    type: String as PropType<Types.GridSpan>,
    default: undefined,
    validator: (rowSpan: Types.GridSpan) => !!classes.rowSpan[rowSpan],
  },
  start: {
    type: String as PropType<Types.GridSpan>,
    default: undefined,
    validator: (start: Types.GridSpan) => !!classes.start[start],
  },
  end: {
    type: String as PropType<Types.GridSpan>,
    default: undefined,
    validator: (end: Types.GridSpan) => !!classes.end[end],
  },
  xs: {
    type: String as PropType<Types.GridSize>,
    default: undefined,
    validator: (xs: Types.GridSize) => !!classes.xs[xs],
  },
  sm: {
    type: String as PropType<Types.GridSize>,
    default: undefined,
    validator: (sm: Types.GridSize) => !!classes.sm[sm],
  },
  md: {
    type: String as PropType<Types.GridSize>,
    default: undefined,
    validator: (md: Types.GridSize) => !!classes.md[md],
  },
  lg: {
    type: String as PropType<Types.GridSize>,
    default: undefined,
    validator: (lg: Types.GridSize) => !!classes.lg[lg],
  },
  xl: {
    type: String as PropType<Types.GridSize>,
    default: undefined,
    validator: (xl: Types.GridSize) => !!classes.xl[xl],
  },
  xxl: {
    type: String as PropType<Types.GridSize>,
    default: undefined,
    validator: (xxl: Types.GridSize) => !!classes.xxl[xxl],
  },
  minHeight: {
    type: String,
    default: undefined,
  },
  minWidth: {
    type: String,
    default: undefined,
  },
  maxHeight: {
    type: String,
    default: undefined,
  },
  maxWidth: {
    type: String,
    default: undefined,
  },
  height: {
    type: String,
    default: undefined,
  },
  width: {
    type: String,
    default: undefined,
  },
})

const styles = computed(() => {
  return {
    minHeight: props.minHeight,
    minWidth: props.minWidth,
    maxHeight: props.maxHeight,
    maxWidth: props.maxWidth,
    height: props.height,
    width: props.width,
  }
})

const containerClasses = computed(() => {
  const flow = checkForUndefined(props.flow, classes.flow)
  const justifyItems = checkForUndefined(props.justifyItems, classes.justifyItems)
  const alignContent = checkForUndefined(props.alignContent, classes.alignContent)
  const alignItems = checkForUndefined(props.alignItems, classes.alignItems)
  const gap = checkForUndefined(props.gap, classes.gap)
  const gapX = checkForUndefined(props.gapX, classes.gapX)
  const gapY = checkForUndefined(props.gapY, classes.gapY)
  const xs = checkForUndefined(props.xs, classes.xs)
  const sm = checkForUndefined(props.sm, classes.sm)
  const md = checkForUndefined(props.md, classes.md)
  const lg = checkForUndefined(props.lg, classes.lg)
  const xl = checkForUndefined(props.xl, classes.xl)
  const xxl = checkForUndefined(props.xxl, classes.xxl)

  return {
    grid: props.container,
    [flow]: !!flow,
    [justifyItems]: !!justifyItems,
    [alignContent]: !!alignContent,
    [alignItems]: !!alignItems,
    [gap]: !!gap,
    [gapX]: !!gapX,
    [gapY]: !!gapY,
    [xs]: !!xs,
    [sm]: !!sm,
    [md]: !!md,
    [lg]: !!lg,
    [xl]: !!xl,
    [xxl]: !!xxl,
  }
})

const itemClasses = computed(() => {
  const justifySelf = checkForUndefined(props.justifySelf, classes.justifySelf)
  const alignSelf = checkForUndefined(props.alignSelf, classes.alignSelf)
  const colSpan = checkForUndefined(props.colSpan, classes.colSpan)
  const rowSpan = checkForUndefined(props.rowSpan, classes.rowSpan)
  const start = checkForUndefined(props.start, classes.start)
  const end = checkForUndefined(props.end, classes.end)
  const xs = checkForUndefined(props.xs, classes.xs)
  const sm = checkForUndefined(props.sm, classes.sm)
  const md = checkForUndefined(props.md, classes.md)
  const lg = checkForUndefined(props.lg, classes.lg)
  const xl = checkForUndefined(props.xl, classes.xl)
  const xxl = checkForUndefined(props.xxl, classes.xxl)
  // TODO add responsiveness sm/md/lg/xl prefixes
  return {
    'col-auto': props.item && !xs && !sm && !md && !lg && !xl && !xxl && !colSpan && !start && !end,
    [colSpan]: !!colSpan,
    [rowSpan]: !!rowSpan,
    [start]: !!start,
    [end]: !!end,
    [xs]: !!xs,
    [sm]: !!sm,
    [md]: !!md,
    [lg]: !!lg,
    [xl]: !!xl,
    [xxl]: !!xxl,
    [justifySelf]: justifySelf,
    [alignSelf]: alignSelf,
  }
})

const gridClasses = computed(() => {
  return props.item ? itemClasses.value : containerClasses.value
})
</script>
