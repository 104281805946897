<template>
  <Teleport :to="teleportTarget" :disabled="!teleportTarget">
    <Transition :name="s[`transition-${variant}`]" appear>
      <slot />
    </Transition>
  </Teleport>
</template>

<script setup lang="ts">
import { useCssModule } from 'vue'

const {
  teleportTarget = '#luikit',
} = defineProps<{
  variant: 'modal' | 'drawer'
  teleportTarget?: string
}>()

const s = useCssModule()
</script>

<style module>
/* Transition modal */
.transition-modal:global(-leave-active),
.transition-modal:global(-enter-active) {
  transition-duration: 300ms;
}

.transition-modal:global(-leave-active) [data-role="backdrop"],
.transition-modal:global(-enter-active) [data-role="backdrop"] {
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: opacity;
}

.transition-modal:global(-leave-to) [data-role="backdrop"],
.transition-modal:global(-enter-from) [data-role="backdrop"] {
  opacity: 0;
}

.transition-modal:global(-leave-active) [data-role="content"],
.transition-modal:global(-enter-active) [data-role="content"] {
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.transition-modal:global(-leave-to) [data-role="contentWrapper"],
.transition-modal:global(-enter-from) [data-role="contentWrapper"] {
  overflow: hidden;
}

.transition-modal:global(-leave-to) [data-role="content"],
.transition-modal:global(-enter-from) [data-role="content"] {
  opacity: 0;
  transform: scaleX(0.9) scaleY(0.9);
}

/* Transition drawer */
.transition-drawer:global(-leave-active),
.transition-drawer:global(-enter-active) {
  transition-duration: 300ms;
}

.transition-drawer:global(-leave-active) [data-role="backdrop"],
.transition-drawer:global(-enter-active) [data-role="backdrop"] {
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: opacity;
}

.transition-drawer:global(-leave-to) [data-role="backdrop"],
.transition-drawer:global(-enter-from) [data-role="backdrop"] {
  opacity: 0;
}

.transition-drawer:global(-leave-to) [data-role="contentWrapper"],
.transition-drawer:global(-enter-from) [data-role="contentWrapper"] {
  overflow: hidden;
}

.transition-drawer:global(-leave-active) [data-role="content"],
.transition-drawer:global(-enter-active) [data-role="content"] {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  overflow: hidden;
}

.transition-drawer:global(-leave-to) [data-role="content"],
.transition-drawer:global(-enter-from) [data-role="content"] {
  transform: translateX(100%);
}
</style>
