<template>
  <li class="flex flex-col" :class="listItemClasses">
    <slot />
  </li>
</template>

<script lang="ts" setup>
import { computed, inject } from 'vue'

import type { ListProps } from '../List/types'

import type { ListItemProps } from './types'

const props: ListItemProps = defineProps({
  disablePX: {
    // If true, the left and right padding is removed.
    type: Boolean,
    default: false,
  },
  disablePY: {
    // If true, the left and right padding is removed.
    type: Boolean,
    default: false,
  },
})

const listState = inject<ListProps>('ListState', {})

const listItemClasses = computed(() => {
  const rounded = listState.rounded ? 'rounded-full' : 'rounded'
  return {
    'px-2': !listState.disablePX && !props.disablePX,
    'py-2': !listState.dense && !props.disablePY, // inherited from list state
    [rounded]: true, // inherited from list state
  }
})
</script>
