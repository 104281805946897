<template>
  <tr :class="rowClasses">
    <slot />
  </tr>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'

import { computed } from 'vue'

import { checkForUndefined } from '../../../utils'

import type { TableBaseModifierType, TableBaseRowPropTypes } from './types'

import { modifiers } from './classes'

const props: TableBaseRowPropTypes = defineProps({
  modifier: {
    type: String as PropType<TableBaseModifierType>,
    default: null,
    validator: (modifier: TableBaseModifierType) => !!modifiers[modifier],
  },
  isActive: {
    type: Boolean,
    default: false,
  },
})
const rowClasses = computed(() => {
  const modifier = checkForUndefined(props.modifier, modifiers)
  return {
    [modifier]: props.modifier,
    active: props.isActive,
  }
})
</script>
