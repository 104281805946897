<template>
  <Paper
    rounded="lg"
    variant="outlined"
  >
    <Paper
      flex
      row
      wrap="nowrap"
      justify="between"
      px="5"
      py="2"
      alignItems="center"
      variant="filled"
      color="base-200"
      minHeight="36px"
      :class="{
        'card-header-opened': openedInternal,
        'card-header-collapsed': !openedInternal,
      }"
    >
      <Box flex alignItems="center" spaceX="1">
        <button class="flex items-center" :disabled="!collapse || collapseDisabled" @click="onCollapseClick">
          <slot name="headerTitle">
            <Typography variant="caption2">
              {{ title }}
            </Typography>
          </slot>
          <ButtonIcon
            v-if="collapse"
            icon="keyboard_arrow_up"
            size="xs"
            class="collapse-arrow"
            :class="{
              'collapse-arrow-opened': openedInternal,
            }"
            :disabled="collapseDisabled"
          />
        </button>
      </Box>
      <slot name="headerActions" />
    </Paper>

    <Box v-show="openedInternal" width="100%" px="6" py="4">
      <slot />
    </Box>
  </Paper>
</template>

<script lang="ts" setup>
import { useVModel } from '@vueuse/core'

import Box from '../Box/Box.vue'
import ButtonIcon from '../ButtonIcon/ButtonIcon.vue'
import Paper from '../Paper/Paper.vue'
import Typography from '../Typography/Typography.vue'

const props = withDefaults(defineProps<{
  title?: string
  collapse?: boolean
  collapseDisabled?: boolean
  opened?: boolean
}>(), {
  title: '',
  collapse: false,
  collapseDisabled: false,
  opened: true,
})

const emit = defineEmits<{
  'update:opened': [boolean]
}>()

const openedInternal = useVModel(props, 'opened', emit, { passive: true })

const onCollapseClick = () => {
  if (props.collapseDisabled) {
    return
  }
  openedInternal.value = !openedInternal.value
}
</script>

<style scoped>
.card-header-opened {
  @apply !rounded-t-lg !rounded-b-none;
}

.card-header-collapsed {
  @apply !rounded-lg;
}

.collapse-arrow {
  @apply transition-transform animate-none rotate-90;
}

.collapse-arrow-opened {
  @apply -rotate-180;
}
</style>
