export const variant = {
  solid: 'bg-base-200', // empty class
  outline: 'select-bordered',
}

export const size = {
  xs: 'select-xs',
  sm: 'select-sm',
  md: 'select-md',
  lg: 'select-lg',
}

export const color = {
  ghost: 'select-ghost',
  primary: 'select-primary',
  secondary: 'select-secondary',
  info: 'select-info',
  success: 'select-success',
  warning: 'select-warning',
  error: 'select-error',
}
