<template>
  <select
    v-bind="$attrs"
    :id="id"
    :name="name"
    :value="valueModel[optionValue] || valueModel"
    :data-test-id="id ? `data-test-id-${id}` : null"
    class="select w-full"
    :class="inputClasses"
    @change="handleChange"
  >
    <slot>
      <option
        v-if="placeholder"
        :disabled="!selectablePlaceholder"
        :value="valueModel === undefined ? undefined : ''"
        selected
        default
      >
        {{ placeholder }}
      </option>
      <option
        v-for="option in options"
        :key="option[optionValue] || option"
        :value="option[optionValue] || option"
        :selected="valueModel[optionValue] === option[optionValue] || valueModel === option[optionValue] || false"
      >
        {{ option[optionLabel] || option }}
      </option>
    </slot>
  </select>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'
import { computed } from 'vue'

import { checkForUndefined } from '../../utils'

import * as classes from './classes'

import type {
  SelectFieldColorType,
  SelectFieldPropTypes,
  SelectFieldSizeType,
  SelectFieldVariantType,
} from './types'

const props: SelectFieldPropTypes = defineProps({
  modelValue: {
    type: [Object, String, Number],
    default: undefined,
  },
  name: {
    type: String,
    default: '',
  },
  id: {
    type: String,
    default: '',
  },
  options: {
    type: Array as PropType<readonly any[]>,
    required: true,
  },
  optionValue: {
    type: String,
    default: '',
  },
  optionLabel: {
    type: String,
    default: '',
  },
  returnObject: {
    type: Boolean,
    default: false,
  },
  selectablePlaceholder: {
    type: Boolean,
    default: false,
  },
  variant: {
    type: String as PropType<SelectFieldVariantType>,
    default: 'outline',
    validator: (variant: SelectFieldVariantType) => !!classes.variant[variant],
  },
  color: {
    type: String as PropType<SelectFieldColorType>,
    default: null,
    validator: (color: SelectFieldColorType) => !!classes.color[color],
  },
  size: {
    type: String as PropType<SelectFieldSizeType>,
    default: 'lg',
    validator: (size: SelectFieldSizeType) => !!classes.size[size],
  },
  placeholder: {
    type: String,
    default: '',
  },
})

const emits = defineEmits(['update:modelValue'])

const valueModel = computed(() => {
  if (props.modelValue === null || props.modelValue === undefined) {
    return ''
  }

  return props.modelValue
})

const handleChange = (e: any) => {
  const currentValue = e?.target.value
  if (props.options?.length) {
    if (props.optionValue) {
      const key = props.optionValue
      // eslint-disable-next-line eqeqeq
      props.options?.forEach(option => option.selected == `${option[key]}` === currentValue)
      const option = props.options?.find(option => `${option[key]}` === currentValue)
      return emits('update:modelValue', props.returnObject ? option : option?.[key])
    }
    else {
      // eslint-disable-next-line eqeqeq
      props.options?.forEach(option => option.selected == option === currentValue)
      const option = props.options?.find(option => option === currentValue)
      return emits('update:modelValue', option)
    }
  }
  else {
    emits('update:modelValue', currentValue)
  }
}

const inputClasses = computed(() => {
  const variant = checkForUndefined(props.variant, classes.variant)
  const color = checkForUndefined(props.color, classes.color)
  const size = checkForUndefined(props.size, classes.size)

  return {
    [variant]: props.variant,
    [color]: props.color,
    [size]: props.size,
  }
})
</script>

<style scoped>
.select {
  font-weight: 400;
}
</style>
