<template>
  <Box class="paper" :class="paperClasses" :p="p" :px="px" :py="py">
    <slot />
  </Box>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'

import { computed } from 'vue'

import Box from '../Box/Box.vue'

import { checkForUndefined } from '../../utils'

import * as boxClasses from '../Box/classes'

import { BoxSpacing } from '../Box/types'

import type { PaperColor, PaperProps, PaperRounded, PaperShadow, PaperVariant } from './types'
import * as classes from './classes'

const props: PaperProps = defineProps({
  variant: {
    type: String as PropType<PaperVariant>,
    default: 'contained',
    validator: (variant: PaperVariant) => typeof classes.variant[variant] === 'string',
  },
  color: {
    type: String as PropType<PaperColor>,
    default: 'base',
    validator: (color: PaperColor) => !!classes.color[color],
  },
  rounded: {
    type: String as PropType<PaperRounded>,
    default: 'rounded',
    validator: (rounded: PaperRounded) => !!classes.rounded[rounded],
  },
  shadow: {
    type: String as PropType<PaperShadow>,
    default: 'none',
    validator: (rounded: PaperShadow) => !!classes.shadow[rounded],
  },
  hover: {
    type: Boolean,
    default: false,
  },
  p: {
    type: String as PropType<BoxSpacing>,
    validator: (p: BoxSpacing) => !!boxClasses.p[p],
  },
  px: {
    type: String as PropType<BoxSpacing>,
    validator: (px: BoxSpacing) => !!boxClasses.px[px],
  },
  py: {
    type: String as PropType<BoxSpacing>,
    validator: (py: BoxSpacing) => !!boxClasses.py[py],
  },
})

const paperClasses = computed(() => {
  const variant = checkForUndefined(props.variant, classes.variant)
  const color = checkForUndefined(props.color, classes.color)
  const rounded = checkForUndefined(props.rounded, classes.rounded)
  const shadow = checkForUndefined(props.shadow, classes.shadow)

  return {
    [variant]: !!props.variant,
    [color]: !!props.color,
    [rounded]: !!props.rounded,
    [shadow]: !!props.shadow,
    'paper-hover': props.hover,
  }
})
</script>

<style scoped src="./paper.styles.css"></style>
