<template>
  <progress class="progress progress-lasso-background transition" :class="progressBarClasses" :value="progress" max="100" />
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'
import { computed } from 'vue'

import { checkForUndefined } from '../../utils'

import type { ProgressBarColorType, ProgressBarPropsType } from './types'
import { colors } from './classes'

const props: ProgressBarPropsType = defineProps({
  progress: {
    type: Number,
  },
  color: {
    type: String as PropType<ProgressBarColorType>,
    default: null,
    validator: (color: ProgressBarColorType) => !!colors[color],
  },
})

const progressBarClasses = computed(() => {
  const color = checkForUndefined(props.color, colors)

  return {
    [color]: !!props.color,
  }
})
</script>

<style scoped src="./progressBar.styles.css"> </style>
