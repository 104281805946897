<template>
  <Box class="tabs" :class="tabsClasses">
    <slot />
  </Box>
</template>

<script lang="ts" setup>
import { computed, provide } from 'vue'

import { checkForUndefined } from '../../utils'

import Box from '../Box/Box.vue'

import type { TabsColor, TabsProvidedHandlers, TabsProvidedProps, TabsSize, TabsVariant } from './types'
import * as classes from './classes'

const props = withDefaults(defineProps<{
  active: string | number | symbol | null | undefined
  variant?: TabsVariant
  size?: TabsSize
  color?: TabsColor
}>(), {
  variant: 'outlined',
  size: 'md',
  color: 'primary',
})

const emits = defineEmits(['update:active'])

const tabsClasses = computed(() => {
  const color = checkForUndefined(props.color, classes.color)
  const variant = checkForUndefined(props.variant, classes.variant)
  const size = checkForUndefined(props.size, classes.size)

  return {
    [color]: !!props.color,
    [size]: !!props.size,
    [variant]: !!props.variant,
  }
})

const tabsHandlers: TabsProvidedHandlers = {
  handleActive: ({ event, value }) => {
    emits('update:active', value)
    return event
  },
}

provide<TabsProvidedProps>('tabsProps', props)
provide<TabsProvidedHandlers>('tabsHandlers', tabsHandlers)
</script>

<style scoped src="./tabs.styles.css"></style>
