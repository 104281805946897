<template>
  <div class="badge" :class="badgeClasses">
    <slot>
      {{ label }}
    </slot>
  </div>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'
import { computed } from 'vue'

import { checkForUndefined } from '../../utils'

import type { BadgeColor, BadgeProps, BadgeShape, BadgeSize, BadgeVariant } from './types'
import * as classes from './classes'

const props: BadgeProps = defineProps({
  label: {
    type: [String, Number],
    default: null,
  },
  variant: {
    type: String as PropType<BadgeVariant>,
    default: null,
    validator: (variant: BadgeVariant) => !!classes.variant[variant],
  },
  color: {
    type: String as PropType<BadgeColor>,
    default: null,
    validator: (color: BadgeColor) => !!classes.color[color],
  },
  size: {
    type: String as PropType<BadgeSize>,
    default: 'md',
    validator: (size: BadgeSize) => !!classes.size[size],
  },
  shape: {
    type: String as PropType<BadgeShape>,
    default: null,
    validator: (shape: BadgeShape) => !!classes.shape[shape],
  },
})

const badgeClasses = computed(() => {
  const variant = checkForUndefined(props.variant, classes.variant)
  const color = checkForUndefined(props.color, classes.color)
  const size = checkForUndefined(props.size, classes.size)
  const shape = checkForUndefined(props.shape, classes.shape)
  return {
    [variant]: !!props.variant,
    [color]: !!props.color,
    [shape]: !!props.shape,
    [size]: !!props.size,
  }
})
</script>
