<template>
  <Grid container xs="7">
    <Grid v-for="(day, index) in weekDays" :key="`w${index}`" item justifySelf="center">
      <Typography color="textSecondary" variant="caption1">
        {{ day }}
      </Typography>
    </Grid>
  </Grid>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { Info } from 'luxon'

import Grid from '../../Grid/Grid.vue'
import Typography from '../../Typography/Typography.vue'

const props = withDefaults(defineProps<{
  locale?: string
  firstDayOfTheWeek?: number
}>(), {
  locale: 'en-US',
  firstDayOfTheWeek: 6,
})

const weekDays = computed(() => {
  let localeData
  try {
    localeData = Info.weekdays('short', { locale: props.locale })
  }
  catch {
    return []
  }
  const before = localeData.slice(props.firstDayOfTheWeek)
  const after = localeData.slice(0, props.firstDayOfTheWeek)
  return [...before, ...after]
})
</script>
