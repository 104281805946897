<template>
  <Box flex alignItems="center">
    <button
      type="button"
      :class="getClasses(previousPageValue)"
      :disabled="isPreviousPageButtonDisabled"
      @click="handlePageClick(previousPageValue)"
    >
      <Icon icon="keyboard_arrow_left" size="xs" />
    </button>
    <button
      v-for="(page, index) in paginationButtons"
      :key="`pagination-button-${page}`"
      type="button"
      :class="getClasses(page)"
      :disabled="disabled"
      @click="handlePageClick(page, index)"
    >
      <Typography variant="caption1">
        {{ page }}
      </Typography>
    </button>
    <button
      type="button"
      :class="getClasses(nextPageValue)"
      :disabled="isNextPageButtonDisabled"
      @click="handlePageClick(nextPageValue)"
    >
      <Icon icon="keyboard_arrow_right" size="xs" />
    </button>
  </Box>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import type { PropType } from 'vue'

import { checkForUndefined } from '../../utils'

import Box from '../Box/Box.vue'
import Icon from '../Icon/Icon.vue'
import Typography from '../Typography/Typography.vue'

import type { PaginationColor, PaginationProps } from './types'
import * as classes from './classes'

type PaginationButtonValue = '>' | '<' | '...' | number

const props: PaginationProps = defineProps({
  modelValue: {
    type: Number,
    required: true,
  },
  pagesTotal: {
    type: Number,
    required: true,
  },
  color: {
    type: String as PropType<PaginationColor>,
    default: 'primary',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits<{
  (e: 'update:modelValue', activePage: number): void
}>()

const maxVisiblePages = 7
const maxPagesFrame = 5
const previousPageValue: PaginationButtonValue = '<'
const nextPageValue: PaginationButtonValue = '>'
const dots: PaginationButtonValue = '...'
const firstPage = 1
const lastPage = computed(() => props.pagesTotal)

const showFirstPages = computed(() => props.modelValue <= maxPagesFrame && props.pagesTotal > maxVisiblePages)
const showLastPages = computed(() => props.modelValue >= props.pagesTotal - maxPagesFrame && props.pagesTotal > maxVisiblePages)
const showMiddlePages = computed(() => props.modelValue > maxPagesFrame && props.modelValue <= props.pagesTotal - maxPagesFrame)
const paginationButtons = computed(() => {
  if (showFirstPages.value) {
    const firstPages = new Array(maxPagesFrame)
      .fill(0)
      .map((_, i) => i + 1)

    return [
      ...firstPages,
      dots,
      lastPage.value,
    ]
  }
  else if (showMiddlePages.value) {
    const middlePages = [
      props.modelValue - 1,
      props.modelValue,
      props.modelValue + 1,
    ]

    return [
      firstPage,
      dots,
      ...middlePages,
      dots,
      lastPage.value,
    ]
  }
  else if (showLastPages.value) {
    const lastPages = new Array(maxPagesFrame)
      .fill(0)
      .map((_, i) => props.pagesTotal - maxPagesFrame + i + 1)

    return [
      firstPage,
      dots,
      ...lastPages,
    ]
  }
  else {
    return new Array(props.pagesTotal)
      .fill(0)
      .map((_, i: number) => i + 1)
  }
})
const isPreviousPageButtonDisabled = computed(() => props.disabled || props.modelValue === 1)
const isNextPageButtonDisabled = computed(() => props.disabled || props.modelValue === props.pagesTotal)

const isPageActive = (page: PaginationButtonValue) => typeof page === 'number' && props.modelValue === page
const getClasses = (page: PaginationButtonValue) => {
  const color = checkForUndefined(props.color, classes.color)

  return {
    'pagination-button': true,
    [color]: !!color,
    'pagination-button--active': isPageActive(page),
  }
}
const handlePageClick = (page: PaginationButtonValue, itemIndex?: number) => {
  let pageNumber = null

  if (page === nextPageValue) {
    pageNumber = props.modelValue + 1
  }
  else if (page === previousPageValue) {
    pageNumber = props.modelValue - 1
  }
  else if (page === dots) {
    // the first dots go after the first page that means the first dots have itemIndex === 1
    pageNumber = itemIndex === 1 ? props.modelValue - 1 : props.modelValue + 1
  }
  else {
    pageNumber = page
  }

  emits('update:modelValue', pageNumber)
}
</script>

<style scoped src="./pagination.styles.css" />
