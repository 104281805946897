<template>
  <component :is="component" :class="boxClasses" :style="styles">
    <slot />
  </component>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { checkForUndefined } from '../../utils'

import { BoxProps } from './types'
import * as classes from './classes'

const props = withDefaults(defineProps<BoxProps>(), {
  component: 'div',
  flex: false,
  inlineFlex: false,
  block: false,
  inlineBlock: false,
  row: false,
  col: false,
})

const styles = computed(() => {
  return {
    minHeight: props.minHeight,
    minWidth: props.minWidth,
    maxHeight: props.maxHeight,
    maxWidth: props.maxWidth,
    height: props.height,
    width: props.width,
  }
})

const boxClasses = computed(() => {
  const display = checkForUndefined(props.display, classes.display)
  const direction = checkForUndefined(props.direction, classes.direction)
  const flexGrow = checkForUndefined(props.flexGrow, classes.flexGrow)
  const grow = checkForUndefined(props.grow, classes.grow)
  const wrap = checkForUndefined(props.wrap, classes.wrap)
  const shrink = checkForUndefined(props.shrink, classes.shrink)
  const justify = checkForUndefined(props.justify, classes.justify)
  const alignContent = checkForUndefined(props.alignContent, classes.alignContent)
  const alignItems = checkForUndefined(props.alignItems, classes.alignItems)
  const alignSelf = checkForUndefined(props.alignSelf, classes.alignSelf)
  const space = checkForUndefined(props.space, classes.space)
  const spaceX = checkForUndefined(props.spaceX, classes.spaceX)
  const spaceY = checkForUndefined(props.spaceY, classes.spaceY)
  const p = checkForUndefined(props.p, classes.p)
  const px = checkForUndefined(props.px, classes.px)
  const py = checkForUndefined(props.py, classes.py)
  const pt = checkForUndefined(props.pt, classes.pt)
  const pr = checkForUndefined(props.pr, classes.pr)
  const pb = checkForUndefined(props.pb, classes.pb)
  const pl = checkForUndefined(props.pl, classes.pl)
  const m = checkForUndefined(props.m, classes.m)
  const mx = checkForUndefined(props.mx, classes.mx)
  const my = checkForUndefined(props.my, classes.my)
  const mt = checkForUndefined(props.mt, classes.mt)
  const mr = checkForUndefined(props.mr, classes.mr)
  const mb = checkForUndefined(props.mb, classes.mb)
  const ml = checkForUndefined(props.ml, classes.ml)

  return {
    'flex': !props.display && props.flex,
    'inline-flex': !props.display && props.inlineFlex,
    'block': !props.display && props.block,
    'inline-block': !props.display && props.inlineBlock,
    [display]: Boolean(props.display),
    'flex-row': !props.direction && props.row,
    'flex-col': !props.direction && props.col,
    [direction]: Boolean(props.direction),
    [flexGrow]: Boolean(props.flexGrow),
    [wrap]: Boolean(props.wrap),
    [grow]: Boolean(props.grow),
    [shrink]: Boolean(props.shrink),
    [justify]: Boolean(props.justify),
    [alignContent]: Boolean(props.alignContent),
    [alignItems]: Boolean(props.alignItems),
    [alignSelf]: Boolean(props.alignSelf),
    [space]: Boolean(props.space),
    [spaceX]: Boolean(props.spaceX),
    [spaceY]: Boolean(props.spaceY),
    [p]: Boolean(props.p),
    [px]: Boolean(props.px),
    [py]: Boolean(props.py),
    [pt]: Boolean(props.pt),
    [pr]: Boolean(props.pr),
    [pb]: Boolean(props.pb),
    [pl]: Boolean(props.pl),
    [m]: Boolean(props.m),
    [mx]: Boolean(props.mx),
    [my]: Boolean(props.my),
    [mt]: Boolean(props.mt),
    [mr]: Boolean(props.mr),
    [mb]: Boolean(props.mb),
    [ml]: Boolean(props.ml),
  }
})
</script>
