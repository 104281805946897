<template>
  <component
    :is="component"
    :id="id || null"
    ref="element"
    :data-placeholder="placeholder || null"
    :contenteditable="contenteditableValue"
    class="content-editable"
    @input="handleUpdate"
    @keypress="handleKeypress"
    @blur="handleBlur"
  />
</template>

<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue'
import type { PropType } from 'vue'

import type { ContentEditableComponentType, ContentEditablePropsType } from './types'
import { useSupportsPlaintextOnly } from './useSupportsPlaintextOnly'

const props: ContentEditablePropsType = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  id: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  component: {
    type: String as PropType<ContentEditableComponentType>,
    default: 'div',
    validator: (component: ContentEditableComponentType) => ['div', 'span'].includes(component),
  },
  autofocus: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits(['update:modelValue', 'blur'])
const element = ref<HTMLElement | null>()

const getElementText = () => {
  if (!element.value) {
    return null
  }

  const value = element.value.textContent ?? ''

  return value.replace(/\r?\n/, '')
}

const updateContent = (content = '') => {
  if (!element.value) {
    return
  }

  element.value.textContent = content
}

const handleUpdate = () => {
  const newValue = getElementText()

  if (newValue === null) {
    return
  }

  emits('update:modelValue', newValue)
}

const handleBlur = () => {
  handleUpdate()
  emits('blur')
}

const handleKeypress = (e: KeyboardEvent) => {
  if (e.key === 'Enter') {
    e.preventDefault()
    const target = e.target as HTMLElement | null
    target?.blur()
  }
}

const { contenteditableValue } = useSupportsPlaintextOnly()

onMounted(() => {
  updateContent(props.modelValue)

  if (props.autofocus) {
    element.value!.focus()
  }
})

watch(
  () => props.modelValue,
  (newVal) => {
    if (newVal !== getElementText()) {
      updateContent(newVal)
    }
  },
)
</script>

<style scoped>
.content-editable {
  @apply outline-0;
}

.content-editable:empty:before {
  @apply text-textSecondaryLight content-[attr(data-placeholder)];
}
</style>
