<template>
  <div :class="s.head" :style="{ height: `${rowHeight}px` }">
    <div
      v-for="(column, columnIndex) in columns"
      :key="column.id"
      :ref="setRefByIndex(columnIndex)"
      :class="[s.headCell, {
        [s.sticky!]: isColumnSticky(columnIndex),
        [s.resizable!]: column.resizable,
        [s.lastSticky!]: isLastStickyColumn(columnIndex),
        [s.lastStickyDivider!]: isLastStickyColumn(columnIndex) && lastStickyDivider,
      }]"
      :style="{
        minWidth: `${column.width}px`,
        width: `${getColumnWidth(column, columnIndex)}px`,
        left: getColumnStickyOffset(columnIndex),
      }"
      @dblclick="resetColumnWidth(column)"
    >
      <div v-if="checkbox && columnIndex === 0" :class="s.checkboxWrapper">
        <Checkbox v-model="selectedInternal" :disabled="checkboxDisabled" />
      </div>

      <div
        :class="[s.headCellInner, {
          [s.sortable!]: column.sortable,
        }]"
        @click="onSort(column)"
      >
        <slot :name="column.id">
          <Typography
            display="block"
            variant="caption2"
            color="textSecondaryLight"
            whiteSpace="nowrap"
            uppercase
            truncate
          >
            {{ column.label }}
          </Typography>
        </slot>
      </div>

      <Tooltip
        v-if="column.description"
        :style="{ display: 'inline-flex' }"
        :content="column.description"
        placement="top"
        tag="div"
        withCursor
      >
        <Icon icon="info" variant="outlined" color="textSecondaryLight" />
      </Tooltip>

      <ButtonIcon
        v-if="column.sortable"
        :class="[s.sortButton, {
          [s.active!]: getIsSorted(column),
        }]"
        :icon="getSortingIconName(column)"
        iconVariant="round"
        variant="text"
        @click="onSort(column)"
      />

      <Tooltip
        v-if="column.chip && getIsChipVisible(column, columnIndex)"
        :content="column.chip.tooltip"
        :class="s.columnChip"
        placement="bottom"
        tag="div"
      >
        <Chip variant="filled" color="secondary" size="2xs">
          {{ column.chip.label }}
        </Chip>
      </Tooltip>
    </div>
  </div>
</template>

<script setup lang="ts" generic="T extends Record<string, unknown>">
import { Ref, computed, useCssModule } from 'vue'
import { useVModel } from '@vueuse/core'

import { KeyOf } from '@lasso/shared/types'

import { Typography } from '../Typography'

import Tooltip from '../Tooltip/Tooltip.vue'

import Icon from '../Icon/Icon.vue'

import ButtonIcon from '../ButtonIcon/ButtonIcon.vue'

import Checkbox from '../Checkbox/Checkbox.vue'

import { Chip } from '../Chip'

import { VirtualTableColumn, VirtualTableColumnWidths, VirtualTableSorting } from './types'
import { useColumnsProps } from './useColumnsProps'
import { useColumnsWidth } from './useColumnsWidth'
import { useColumnSorting } from './useColumnSorting'

const props = defineProps<{
  rowHeight: number
  columns: Array<Required<VirtualTableColumn<T>>>
  columnWidths: VirtualTableColumnWidths<T>
  sorting?: VirtualTableSorting<KeyOf<T>>
  defaultSorting?: VirtualTableSorting<KeyOf<T>>
  sticky: number
  checkbox: boolean
  checkboxDisabled: boolean
  selected: boolean
  scrollX: number
}>()

const emit = defineEmits<{
  'update:columnWidths': [Record<string, string>]
  'update:selected': [boolean]
  'update:sorting': [VirtualTableSorting<KeyOf<T>>]
}>()

const s = useCssModule()

const columnWidthsInternal = useVModel(props, 'columnWidths', emit) as Ref<VirtualTableColumnWidths<T>>
const selectedInternal = useVModel(props, 'selected', emit)

const {
  getColumnWidth,
  isColumnSticky,
  getColumnOffset,
  getColumnStickyOffset,
} = useColumnsProps({
  columns: () => props.columns,
  columnWidths: columnWidthsInternal,
  sticky: () => props.sticky,
  checkbox: () => props.checkbox,
})

const {
  setRefByIndex,
  resetColumnWidth,
} = useColumnsWidth({
  columns: () => props.columns,
  columnWidths: columnWidthsInternal,
})

const stickyOffset = computed(() => {
  if (!props.sticky) {
    return null
  }

  const lastStickyColumnIndex = props.sticky - 1
  const lastStickyColumn = props.columns[lastStickyColumnIndex]!

  return getColumnOffset(lastStickyColumnIndex) + getColumnWidth(lastStickyColumn, lastStickyColumnIndex)
})

// TODO: find a better way to make chips show up over regular columns but under sticky columns
const getIsChipVisible = (column: Required<VirtualTableColumn<T>>, columnIndex: number) => {
  if (!column.chip) {
    return false
  }

  if (stickyOffset.value === null) {
    return true
  }

  const columnOffset = getColumnOffset(columnIndex)
  const columnWidth = getColumnWidth(column, columnIndex)
  const chipWidth = column.chip.width ?? 80

  return props.scrollX < (columnOffset - stickyOffset.value + (columnWidth / 2) - (chipWidth / 2))
}

const {
  getIsSorted,
  getSortingIconName,
  onSort,
} = useColumnSorting({
  sorting: useVModel(props, 'sorting', emit),
  defaultSorting: () => props.defaultSorting,
})

const lastStickyDivider = computed(() => props.sticky && props.scrollX > 0)
const isLastStickyColumn = (columnIndex: number) => props.sticky && columnIndex === props.sticky - 1
</script>

<style module>
.head {
  display: flex;
  position: absolute;
}

.head {
  top: 0;
}

.headCell {
  @apply px-4 bg-base-100 border-b border-base-400 gap-1;
  position: relative;
  white-space: nowrap;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.headCellInner {
  overflow: hidden;
  text-overflow: ellipsis;
}

.resizable {
  resize: horizontal;
  /* TODO: add a wrapper if we need a resizable column with a chip - right now the chip will get cut off */
  overflow: hidden;
}

.sticky {
  position: sticky;
  z-index: 1;
}

.sortButton {
  opacity: 0;
}

.headCell:hover .sortButton,
.sortButton:focus-visible,
.sortButton.active {
  opacity: 1;
}

.columnChip {
  position: absolute;
  margin: 0 auto;
  bottom: 0;
  transform: translateY(50%);
  left: 0;
  right: 0;
  text-align: center;
}

.sortable {
  cursor: pointer;
}

.checkboxWrapper {
  @apply mr-2;
  display: flex;
  align-items: center;
}

.lastSticky {
  @apply border-r;
  border-right-color: transparent;
}

.lastStickyDivider {
  @apply border-base-400;
}
</style>
