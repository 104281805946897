<template>
  <ModalWrapper
    :teleportTarget="teleportTarget"
    variant="drawer"
  >
    <ModalBase
      v-if="modelValue"
      v-model="modelValue"
      variant="drawer"
      :teleportTarget="teleportTarget"
      :noSelfClose="noSelfClose"
      :size="size"
      :initialFocus="initialFocus"
      :fitContent="fitContent"
      :noPadding="noPadding"
      :title="title"
      @close="emit('close')"
    >
      <template v-if="$slots.header" #header>
        <slot name="header" />
      </template>
      <template v-if="$slots.default" #default>
        <slot name="default" />
      </template>
      <template v-if="$slots.actions" #actions>
        <slot name="actions" />
      </template>
    </ModalBase>
  </ModalWrapper>
</template>

<script setup lang="ts">
import ModalBase from './ModalBase.vue'
import ModalWrapper from './ModalWrapper.vue'

defineProps<{
  teleportTarget?: string
  noSelfClose?: boolean
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  initialFocus?: string | (() => HTMLElement | false)
  fitContent?: boolean
  noPadding?: boolean
  title?: string
}>()

const emit = defineEmits<{
  close: []
}>()

const modelValue = defineModel<boolean>({ required: true })
</script>
