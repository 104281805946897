<template>
  <TippyComponent
    ref="tippyInstance"
    v-bind="config"
    :delay="[+openDelay, +closeDelay]"
    :offset="[+offsetSkid, +offsetDistance]"
    :placement="placement"
    :trigger="tippyTriggers as TippySingleTrigger"
    :interactive="interactive"
    :zIndex="+zIndex"
    :content="content"
    :popperOptions="popperOptions"
    :style="{ width: containerWidth }"
    :hideOnClick="hideOnClick"
    :maxWidth="maxWidth"
    sticky
    :arrow="arrow"
    @show="emits('show')"
    @hidden="emits('hidden')"
    @clickOutside="emits('clickOutside')"
  >
    <template #default="slotTippyInstance">
      <slot v-bind="slotTippyInstance" />
    </template>
    <template #content="slotTippyInstance">
      <Paper
        :p="p"
        :px="px"
        :py="py"
        :variant="variant"
        :color="color"
        shadow="lg"
        :style="{ minWidth, maxHeight, maxWidth }"
      >
        <slot name="content" v-bind="slotTippyInstance" />
      </Paper>
    </template>
  </TippyComponent>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'

import { computed, ref, watch } from 'vue'

import { Tippy } from 'vue-tippy'

import * as boxClasses from '../Box/classes'
import type { BoxSpacing } from '../Box/types'

import Paper from '../Paper/Paper.vue'
import * as paperClasses from '../Paper/classes'
import type { PaperColor, PaperVariant } from '../Paper/types'

import type { TippyInstance, TippyPlacement, TippyProps, TippySingleTrigger } from './types'

const props: TippyProps = defineProps({
  /**
   * Preferred placement (the "auto" placements will choose the side with most space.)
   */
  placement: {
    type: String as PropType<TippyPlacement>,
    default: 'bottom-start',
    validator(val: string): boolean {
      return [
        'auto',
        'auto-start',
        'auto-end',
        'top',
        'top-start',
        'top-end',
        'bottom',
        'bottom-start',
        'bottom-end',
        'right',
        'right-start',
        'right-end',
        'left',
        'left-start',
        'left-end',
      ].includes(val)
    },
  },
  /**
   * Offset in pixels along the trigger element
   */
  offsetSkid: {
    type: [Number, String],
    default: 0,
  },
  /**
   * Offset in pixels away from the trigger element
   */
  offsetDistance: {
    type: [Number, String],
    default: 12,
  },
  /**
   * Disables the Tippy. If it was already open, it will be closed.
   */
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * Open the Tippy after a delay (ms).
   */
  openDelay: {
    type: [Number, String],
    default: 200,
  },
  /**
   * Close the Tippy after a delay (ms).
   */
  closeDelay: {
    type: [Number, String],
    default: 200,
  },
  /**
   * The z-index of the Tippy.
   */
  zIndex: {
    type: [Number, String],
    default: 99999,
  },
  /**
   * If the Tippy should be interactive, it will close when clicked/hovered if false
   */
  interactive: {
    type: Boolean,
    default: true,
  },
  /**
   * Lock the Tippy into place, it will not flip dynamically when it runs out of space if true
   */
  locked: {
    type: Boolean,
    default: false,
  },
  /**
   * If the content is just a simple string, it can be passed in as a prop
   */
  content: {
    type: String,
    default: '',
  },

  /**
   * Determines the events that cause the tippy to show. Multiple event names are separated by spaces. https://vue-tippy.netlify.app/props#trigger
   */
  trigger: {
    type: [String, Array] as PropType<TippySingleTrigger | TippySingleTrigger[]>,
    default: 'click',
  },

  /**
   * Determines if the tippy hides upon clicking the reference or outside of the tippy. The behavior can depend upon the trigger events used. https://vue-tippy.netlify.app/props#hideonclick
   */
  hideOnClick: {
    type: [Boolean, String] as PropType<boolean | TippySingleTrigger>,
    default: true,
  },

  //#region Paper props
  maxHeight: {
    type: String,
    default: undefined,
  },
  maxWidth: {
    type: String,
    default: undefined,
  },
  minWidth: {
    type: String,
    default: undefined,
  },
  containerWidth: {
    type: String,
    default: undefined,
  },
  variant: {
    type: String as PropType<PaperVariant>,
    default: 'contained',
    validator: (variant: PaperVariant) => typeof paperClasses.variant[variant] === 'string',
  },
  color: {
    type: String as PropType<PaperColor>,
    default: 'base',
    validator: (color: PaperColor) => !!paperClasses.color[color],
  },
  p: {
    type: String as PropType<BoxSpacing>,
    validator: (p: BoxSpacing) => !!boxClasses.p[p],
  },
  px: {
    type: String as PropType<BoxSpacing>,
    default: '3',
    validator: (px: BoxSpacing) => !!boxClasses.px[px],
  },
  py: {
    type: String as PropType<BoxSpacing>,
    default: '3',
    validator: (py: BoxSpacing) => !!boxClasses.py[py],
  },
  arrow: {
    type: Boolean,
    default: false,
  },
  //#endregion Paper props
})

const emits = defineEmits(['show', 'hidden', 'clickOutside', 'afterUpdate'])

const TippyComponent = Tippy as typeof Tippy & {
  $slots: {
    default: (instance: TippyInstance) => any
    content: (instance: TippyInstance) => any
  }
}

const tippyInstance = ref<TippyInstance | null>(null)

const config = {
  onCreate: (instance: TippyInstance) => {
    instance.popper.setAttribute('data-mf', '') // TODO: take attribute name from env variable

    if (props.disabled) {
      instance.disable()
      instance.hide()
    }

    // To make arrow the same color as Paper
    if (props.arrow) {
      const popperChildren = Array.from((instance?.popper?.children ?? []) as unknown as HTMLElement[])
      const arrowContainer = popperChildren.find(child => child.classList.contains('tippy-box'))
      arrowContainer?.querySelector('.tippy-arrow')?.classList.add(`tippy-arrow--${props.color}`)
    }
  },
  onAfterUpdate: () => {
    emits('afterUpdate')
  },
  appendTo: () => document.body, // it's a default value according to docs, but it's not working without this line https://vue-tippy.netlify.app/props#appendto
}

const tippyTriggers = computed(() => {
  if (Array.isArray(props.trigger)) {
    return props.trigger.join(' ')
  }
  return props.trigger
})

const popperOptions = computed(() => {
  return {
    modifiers: [
      {
        name: 'flip',
        enabled: !props.locked,
        options: {
          flipVariations: true,
          flipOnUpdate: !props.locked,
        },
      },
      {
        name: 'preventOverflow',
        enabled: !props.locked,
      },
    ],
  }
})

watch(
  () => props.disabled,
  (newValue) => {
    if (!tippyInstance.value)
      return

    if (newValue) {
      tippyInstance.value.disable()
      tippyInstance.value.hide()
    }
    else {
      tippyInstance.value.enable()
    }
  },
)

defineExpose({
  tippyInstance, // can be used for manual triggering show/hide methods etc. See TippyInstance interface.
})
</script>

<style src="./tippy.styles.css" />
