import IMask from 'imask'

// The Number Mask returns 0 when input is empty.
// Here is workaround from https://github.com/uNmAnNeR/imaskjs/issues/432
export class NullableMaskedNumber extends IMask.MaskedNumber {
  // @ts-expect-error override
  get typedValue() {
    return this.unmaskedValue !== ''
      // @ts-expect-error override
      ? super.typedValue
      : null
  }

  // @ts-expect-error override
  set typedValue(num) {
    // @ts-expect-error override
    super.typedValue = num
  }
}
