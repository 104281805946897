import Sortable from 'sortablejs'

import { defineComponent, h } from 'vue'

import { camelize } from './util/string'

import { ComponentStructure } from './core/componentStructure'

import { insertNodeAt, removeNode } from './util/htmlHelper'

import { events } from './core/sortableEvents'

// Global state
let draggingElement = null

const componentSymbol = Symbol('VueDraggableNext')

// TODO: rewrite in composition API and typescript
const VueDraggableNext = defineComponent({
  name: 'VueDraggableNext',

  inheritAttrs: false,

  props: {
    modelValue: {
      type: Array,
      required: false,
      default: null,
    },
    clone: {
      type: Function,
      default: (original) => {
        return original
      },
    },
    tag: {
      type: [String, Object],
      default: 'div',
    },
    move: {
      type: Function,
      default: null,
    },
    sortableOptions: {
      type: Object,
      default: () => ({}),
    },
    componentData: {
      type: Object,
      required: false,
      default: null,
    },
  },

  emits: [
    'update:modelValue',
    'change',
    ...[...events.manageAndEmit, ...events.emit].map(evt => evt.toLowerCase()),
  ],

  data() {
    return {
      error: false,
      defaultSortableOptions: {
        draggable: '>*',
      },
    }
  },

  computed: {
    realList() {
      return this.modelValue
    },
  },

  watch: {
    sortableOptions: {
      handler(newOptionValue) {
        const { _sortable } = this
        if (!_sortable) {
          return
        }

        const options = Object.entries(this.getSortableOptionsWithDefaults(newOptionValue))

        options.forEach(([key, value]) => {
          _sortable.option(key, value)
        })
      },
      deep: true,
    },
  },

  mounted() {
    if (this.error) {
      return
    }

    const { $el, componentStructure } = this
    componentStructure.updated()

    const sortableOptions = this.getSortableOptionsWithDefaults(this.sortableOptions)
    const targetDomElement = $el.nodeType === 1 ? $el : $el.parentElement
    this._sortable = new Sortable(targetDomElement, sortableOptions)
    this.targetDomElement = targetDomElement
    targetDomElement[componentSymbol] = this
  },

  updated() {
    this.componentStructure.updated()
  },

  beforeUnmount() {
    if (this._sortable !== undefined)
      this._sortable.destroy()
  },

  methods: {
    getUnderlyingVm(domElement) {
      return this.componentStructure.getUnderlyingVm(domElement) || null
    },

    getUnderlyingPotencialDraggableComponent(htmElement) {
      // TODO check case where you need to see component children
      return htmElement[componentSymbol]
    },

    emitChanges(evt) {
      this.$emit('change', evt)
    },

    alterList(onList) {
      const newList = [...this.modelValue]
      onList(newList)
      this.$emit('update:modelValue', newList)
    },

    spliceList(...args) {
      const spliceList = list => list.splice(...args)
      this.alterList(spliceList)
    },

    updatePosition(oldIndex, newIndex) {
      const updatePosition = list =>
        list.splice(newIndex, 0, list.splice(oldIndex, 1)[0])
      this.alterList(updatePosition)
    },

    getRelatedContextFromMoveEvent({ to, related }) {
      const component = this.getUnderlyingPotencialDraggableComponent(to)
      if (!component) {
        return { component }
      }
      const list = component.realList
      const context = { list, component }
      if (to !== related && list) {
        const destination = component.getUnderlyingVm(related) || {}
        return { ...destination, ...context }
      }
      return context
    },

    getVmIndexFromDomIndex(domIndex) {
      return this.componentStructure.getVmIndexFromDomIndex(
        domIndex,
        this.targetDomElement,
      )
    },

    onDragStart(evt) {
      this.context = this.getUnderlyingVm(evt.item)
      evt.item._underlying_vm_ = this.clone(this.context.element)
      draggingElement = evt.item
    },

    onDragAdd(evt) {
      const element = evt.item._underlying_vm_
      if (element === undefined) {
        return
      }
      removeNode(evt.item)
      const newIndex = this.getVmIndexFromDomIndex(evt.newIndex)
      this.spliceList(newIndex, 0, element)
      const added = { element, newIndex }
      this.emitChanges({ added })
    },

    onDragRemove(evt) {
      insertNodeAt(this.$el, evt.item, evt.oldIndex)
      if (evt.pullMode === 'clone') {
        removeNode(evt.clone)
        return
      }
      const { index: oldIndex, element } = this.context
      this.spliceList(oldIndex, 1)
      const removed = { element, oldIndex }
      this.emitChanges({ removed })
    },

    onDragUpdate(evt) {
      removeNode(evt.item)
      insertNodeAt(evt.from, evt.item, evt.oldIndex)
      const oldIndex = this.context.index
      const newIndex = this.getVmIndexFromDomIndex(evt.newIndex)
      this.updatePosition(oldIndex, newIndex)
      const moved = { element: this.context.element, oldIndex, newIndex }
      this.emitChanges({ moved })
    },

    computeFutureIndex(relatedContext, evt) {
      if (!relatedContext.element) {
        return 0
      }
      const domChildren = [...evt.to.children].filter(
        el => el.style.display !== 'none',
      )
      const currentDomIndex = domChildren.indexOf(evt.related)
      const currentIndex = relatedContext.component.getVmIndexFromDomIndex(
        currentDomIndex,
      )
      const draggedInList = domChildren.includes(draggingElement)
      return (draggedInList || !evt.willInsertAfter)
        ? currentIndex
        : currentIndex + 1
    },

    onDragMove(evt, originalEvent) {
      const { move, realList } = this
      if (!move || !realList) {
        return true
      }

      const relatedContext = this.getRelatedContextFromMoveEvent(evt)
      const futureIndex = this.computeFutureIndex(relatedContext, evt)
      const draggedContext = {
        ...this.context,
        futureIndex,
      }
      const sendEvent = {
        ...evt,
        relatedContext,
        draggedContext,
      }
      return move(sendEvent, originalEvent)
    },

    onDragEnd() {
      draggingElement = null
    },

    /** @param group {string | GroupOptions | undefined} */
    getGroupOption(group) {
      if (!group || typeof group === 'string') {
        return group
      }

      const groupCopy = { ...group }

      if (typeof group.put === 'function') {
        groupCopy.put = (...args) => this.handleGroupEvent(group.put, ...args)
      }

      if (typeof group.pull === 'function') {
        groupCopy.pull = (...args) => this.handleGroupEvent(group.pull, ...args)
      }

      return groupCopy
    },

    handleGroupEvent(originalPut, to, from, dragEl, event) {
      const toVm = this.getUnderlyingPotencialDraggableComponent(to.el)
      const toModelValue = toVm?.modelValue
      const { element: toItem, index: toIndex } = this.getUnderlyingVm(dragEl) ?? {}

      const fromVm = this.getUnderlyingPotencialDraggableComponent(from.el)
      const fromModelValue = fromVm?.modelValue
      const { element: fromItem, index: fromIndex } = this.getUnderlyingVm(dragEl) ?? {}

      return originalPut({
        to: toModelValue,
        toItem,
        toIndex,
        from: fromModelValue,
        fromItem,
        fromIndex,
      }, {
        to, from, dragEl, event,
      })
    },

    getSortableOptionsWithDefaults(value) {
      const options = Object.fromEntries(
        Object.entries(value)
          .filter(([_, value]) => value !== undefined)
          .map(([key, value]) => [camelize(key), value]),
      )

      options.group = this.getGroupOption(options.group)

      const emit = (event, data) => this.$emit(event.toLowerCase(), data)

      const manage = function (evtName) {
        return (evtData, originalElement) => {
          if (this.realList !== null) {
            return this[`onDrag${evtName}`](evtData, originalElement)
          }
        }
      }

      const manageAndEmit = function (evtName) {
        const delegateCallBack = manage.call(this, evtName)

        return (evtData, originalElement) => {
          delegateCallBack.call(this, evtData, originalElement)
          emit.call(this, evtName, evtData)
        }
      }

      const eventBuilders = {
        emit: event => emit.bind(this, event),
        manage: event => manage.call(this, event),
        manageAndEmit: event => manageAndEmit.call(this, event),
      }

      Object.entries(eventBuilders).forEach(([eventType, eventBuilder]) => {
        events[eventType].forEach((event) => {
          options[`on${event}`] = eventBuilder(event)
        })
      })

      return {
        ...this.defaultSortableOptions,
        ...options,
      }
    },
  },

  render() {
    try {
      this.error = false
      const { $slots, $attrs, tag, componentData, realList } = this
      const componentStructure = new ComponentStructure({
        $slots,
        tag,
        realList,
      })

      this.componentStructure = componentStructure
      return componentStructure.render(h, { ...$attrs, ...componentData })
    }
    catch (err) {
      this.error = true
      return h('pre', { style: { color: 'red' } }, err.stack)
    }
  },
})

export { VueDraggableNext }
