<template>
  <div class="indicator">
    <slot name="indicatorItem" :classes="indicatorItemClasses">
      <Badge v-bind="$attrs" :class="indicatorItemClasses">
        {{ text }}
      </Badge>
    </slot>
    <slot />
  </div>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'
import { computed } from 'vue'

import { checkForUndefined } from '../../utils'

import Badge from '../Badge/Badge.vue'

import type { IndicatorPosition, IndicatorProps } from './types'
import * as classes from './classes'

const props: IndicatorProps = defineProps({
  text: {
    type: [String, Number],
    default: null,
  },
  position: {
    type: String as PropType<IndicatorPosition>,
    default: null,
    validator: (position: IndicatorPosition) => !!classes.position[position],
  },
})

const indicatorItemClasses = computed(() => {
  const position = checkForUndefined(props.position, classes.position)
  return {
    'indicator-item': true,
    [position]: !!props.position,
  }
})
</script>
