<template>
  <DynamicScrollerItem
    :item="item"
    :active="active"
    :sizeDependencies="sizeDependencies"
    :watchData="watchData"
    :tag="tag"
  >
    <template v-if="slots.default">
      <slot />
    </template>
  </DynamicScrollerItem>
</template>

<script setup lang="ts" generic="T">
import { DynamicScrollerItem } from 'vue-virtual-scroller'

withDefaults(defineProps<{
  item: T
  active: boolean
  sizeDependencies?: any[]
  watchData?: boolean
  tag?: string
}>(), {
  watchData: false,
  tag: 'div',
})

const slots = defineSlots<{
  default?: () => any
}>()
</script>
