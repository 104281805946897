<template>
  <Box
    px="4" py="2" minWidth="374px" maxWidth="374px" minHeight="56px"
    flex alignItems="center" spaceX="4"
    :class="toastClasses"
  >
    <Icon :icon="iconName" :color="iconColor" variant="outlined" />
    <Box flex direction="col" spaceY="2">
      <Typography
        variant="subtitle2"
        color="textPrimary"
      >
        {{ title }}
      </Typography>
      <Typography
        v-if="message"
        variant="body2"
        color="textSecondary"
      >
        {{ message }}
      </Typography>
      <Box v-if="actions.length" flex spaceX="2">
        <Link
          v-for="(action, index) in actions"
          :key="index"
          :variant="action.variant ?? 'underlined'"
          :color="action.color ?? 'secondary'"
          :disabled="action.disabled"
          @click="action.onClick"
        >
          {{ action.title }}
        </Link>
      </Box>
    </Box>
  </Box>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { ToastProps } from '@lasso/shared/hooks'

import Box from '../Box/Box.vue'
import Typography from '../Typography/Typography.vue'
import { checkForUndefined } from '../../utils'
import Icon from '../Icon/Icon.vue'
import Link from '../Link/Link.vue'

import * as classes from './classes'

const props = defineProps<ToastProps>()

const toastClasses = computed(() => {
  const color = checkForUndefined(props.color, classes.colors)

  return {
    [color]: !!props.color,
    'rounded-xl shadow-lg pointer-events-auto cursor-pointer': true,
  }
})
const iconColor = computed(() => classes.iconColors[props.color] || classes.iconColors.base)
const iconName = computed(() => classes.icons[props.color] || classes.icons.base)
</script>
