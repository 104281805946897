<template>
  <div class="divider" :class="dividerClasses">
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { checkForUndefined } from '../../utils'

import type { DividerProps } from './types'
import * as classes from './classes'

const props = withDefaults(defineProps<DividerProps>(), {
  variant: 'solid',
  color: 'default',
  direction: 'horizontal',
  disableMargin: false, // disables default .divider margins
})

const dividerClasses = computed(() => {
  const variant = checkForUndefined(props.variant, classes.variants)
  const color = checkForUndefined(props.color, classes.colors)
  const direction = checkForUndefined(props.direction, classes.directions)

  return {
    [variant]: !!props.variant,
    [color]: !!props.color,
    [direction]: !!props.direction,
    '!m-0': props.disableMargin,
  }
})
</script>

<style src="./divider.styles.css" scoped />
