<template>
  <Box flex mt="2" spaceX="2" justify="between" alignItems="center">
    <TablePaginationSkeleton
      v-if="loading"
      width="100%"
      :pageSizes="isPageSizesSelectorVisible"
    />
    <template v-else>
      <PageRange :pageNumber="modelValue" :pageSize="pageSize" :total="total" />
      <Box flex spaceX="2">
        <Box
          v-if="isPageSizesSelectorVisible"
          width="155px"
        >
          <Select
            v-model="pageSizeInternal"
            width="155px"
            :options="pageSizesOptions"
            size="sm"
          />
        </Box>
        <Pagination v-model="modelValueInternal" :pagesTotal="pagesTotal" :disabled="disabled" />
      </Box>
    </template>
  </Box>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useVModel } from '@vueuse/core'

import { Box } from '../Box'
import { PageRange } from '../PageRange'
import { Select } from '../Select'
import { Pagination } from '../../index'

import TablePaginationSkeleton from './TablePaginationSkeleton.vue'

const props = withDefaults(defineProps<{
  modelValue: number
  pageSize: number
  // eslint-disable-next-line vue/prop-name-casing
  'onUpdate:pageSize'?: (value: number[]) => void
  total: number
  pagesTotal: number
  pageSizes?: number[]
  loading?: boolean
  disabled?: boolean
}>(), {
  loading: false,
  disabled: false,
  pageSizes: () => [10, 25, 50, 75, 100],
})

const emit = defineEmits<{
  'update:modelValue': [number]
  'update:pageSize': [number]
}>()

const modelValueInternal = useVModel(props, 'modelValue', emit)
const pageSizeInternal = useVModel(props, 'pageSize', emit)

const isPageSizesSelectorVisible = computed(() => Boolean(props['onUpdate:pageSize']) && props.pageSizes.length > 0)

const pageSizesOptions = computed(() => {
  return props.pageSizes.map(size => ({ label: `${size} per page`, value: size }))
})
</script>
