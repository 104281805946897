import { FileUploadColor } from './types'

export const colors: Record<FileUploadColor, string> = {
  primary: 'bg-primary-light border-primary',
  secondary: 'bg-secondary-light border-secondary',
  success: 'bg-success-light border-success',
  info: 'bg-info-light border-info',
  warning: 'bg-warning-light border-warning',
  error: 'bg-error-light border-error',
}
