import { ComponentProps, RefOrGetter } from '@lasso/shared/types'

import { Component, Ref, computed, toValue } from 'vue'

import { VirtualTableColumn, VirtualTableColumnSlotProps, VirtualTableColumns } from './types'

/*
 * Some typescript magic to get correct inference for the props of components.
 *
 * The `I in keyof Components` mapping ensures that the `component` and return of `props` match.
 * This maps an array into a different type of array.
 *
 * The `keyof T extends infer K ? K extends any ?` ensures that the `id` and the argument of `props` match.
 * This approach utilizes distributive conditional types: https://www.typescriptlang.org/docs/handbook/2/conditional-types.html#distributive-conditional-types
 *
 * TODO: figure out if this can work with a Record<keyof T, ...> type instead of an array with `id` on items if you're bored.
 */
export type VirtualTableSlots<T extends Record<string, unknown>, Components extends Component[]> = {
  [I in keyof Components]: keyof T extends infer K ? K extends any ? {
    id: K
    component: Components[I]
    props: (props: VirtualTableColumnSlotProps<T, K & string>) => ComponentProps<Components[I]>
  } : never : never
}

/*
 * Returns columns with added components and component props callbacks. This basically acts as slots for the cells
 * within the VirtualTable component. This is needed to improve performance, because vue is unable to optimize regular
 * slots well enough.
 */
export const useVirtualTableColumnsWithSlots = <T extends Record<string, unknown>, Components extends Component[]>(
  columns: RefOrGetter<VirtualTableColumns<T>>,
  slots: VirtualTableSlots<T, Components>,
): Readonly<Ref<Array<VirtualTableColumn<T>>>> => {
  return computed(() => toValue(columns).map((column): VirtualTableColumn<T> => {
    const slot = slots.find(slot => slot.id === column.id)

    if (!slot) {
      return column
    }

    return {
      ...column,
      component: slot.component,
      componentProps: slot.props,
    }
  }))
}
