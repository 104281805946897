import { Ref } from 'vue'

import { track } from '../classes'
import { SliderTrackColor } from '../types/SliderTrackTypes'

type Options = {
  color: SliderTrackColor
  lowerProgress: number | undefined
  upperProgress: number
  isDisabled: boolean
}

const setTrackColor = (ref: Ref<HTMLDivElement | null>, options: Options) => {
  if (ref.value !== null) {
    const startFill = options.isDisabled ? track.startFill.disabled : track.startFill[options.color]
    const endFill = options.isDisabled ? track.endFill.disabled : track.endFill[options.color]

    if (options.lowerProgress !== undefined) {
      ref.value?.style.setProperty(
        'background',
        `linear-gradient(to right,
        ${endFill} ${options.lowerProgress}% ,
        ${startFill} ${options.lowerProgress}% ,
        ${startFill} ${options.upperProgress}%,
        ${endFill} ${options.upperProgress}%)`,
      )
    }
    else {
      ref.value?.style.setProperty(
        'background',
        `linear-gradient(to right, ${startFill} ${options.upperProgress}%, ${endFill} ${options.upperProgress}%)`,
      )
    }
  }
}

export default setTrackColor
