import { DividerColor, DividerDirection, DividerVariant } from './types'

export const variants: Record<DividerVariant, string> = {
  solid: 'divider-variant-solid',
  dashed: 'divider-variant-dashed',
}

export const directions: Record<DividerDirection, string> = {
  vertical: 'divider-direction-vertical',
  horizontal: 'divider-direction-horizontal',
}

export const colors: Record<DividerColor, string> = {
  default: 'divider-color-default',
  primary: 'divider-color-primary',
  secondary: 'divider-color-secondary',
  success: 'divider-color-success',
  warning: 'divider-color-warning',
  error: 'divider-color-error',
  info: 'divider-color-info',
}
