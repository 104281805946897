import type { BoxAlignContent, BoxAlignItems, BoxAlignSelf, BoxDisplay, BoxFlexDirection, BoxFlexGrow, BoxGrow, BoxJustify, BoxShrink, BoxSpacing, BoxSpacingAuto, BoxWrap } from './types'

export const display: Record<BoxDisplay, string> = {
  flex: 'flex',
  inlineFlex: 'inline-flex',
  block: 'block',
  inlineBlock: 'inline-block',
}

export const direction: Record<BoxFlexDirection, string> = {
  'row': 'flex-row',
  'row-reverse': 'flex-row-reverse',
  'col': 'flex-col',
  'col-reverse': 'flex-col-reverse',
}

export const flexGrow: Record<BoxFlexGrow, string> = {
  'initial': 'flex-initial',
  'flex-1': 'flex-1',
  'auto': 'flex-auto',
  'none': 'flex-none',
}

export const wrap: Record<BoxWrap, string> = {
  'wrap': 'flex-wrap',
  'nowrap': 'flex-nowrap',
  'wrap-reverse': 'flex-wrap-reverse',
}

export const grow: Record<BoxGrow, string> = {
  'grow-0': 'flex-grow-0',
  'grow': 'flex-grow',
}

export const shrink: Record<BoxShrink, string> = {
  'shrink-0': 'flex-shrink-0',
  'shrink': 'flex-shrink',
}

export const justify: Record<BoxJustify, string> = {
  start: 'justify-start',
  end: 'justify-end',
  center: 'justify-center',
  between: 'justify-between',
  around: 'justify-around',
  evenly: 'justify-evenly',
}

export const alignContent: Record<BoxAlignContent, string> = {
  start: 'content-start',
  end: 'content-end',
  center: 'content-center',
  between: 'content-between',
  around: 'content-around',
  evenly: 'content-evenly',
}

export const alignItems: Record<BoxAlignItems, string> = {
  start: 'items-start',
  end: 'items-end',
  center: 'items-center',
  baseline: 'items-baseline',
  stretch: 'items-stretch',
}

export const alignSelf: Record<BoxAlignSelf, string> = {
  auto: 'self-auto',
  start: 'self-start',
  end: 'self-end',
  center: 'self-center',
  baseline: 'self-baseline',
  stretch: 'self-stretch',
}

export const space: Record<BoxSpacing, string> = {
  px: 'gap-px',
  0: 'gap-0',
  0.5: 'gap-0.5',
  1: 'gap-1',
  1.5: 'gap-1.5',
  2: 'gap-2',
  2.5: 'gap-2.5',
  3: 'gap-3',
  3.5: 'gap-3.5',
  4: 'gap-4',
  5: 'gap-5',
  6: 'gap-6',
  7: 'gap-7',
  8: 'gap-8',
  9: 'gap-9',
  10: 'gap-10',
  11: 'gap-11',
  12: 'gap-12',
  14: 'gap-14',
  16: 'gap-16',
  24: 'gap-24',
  32: 'gap-32',
  48: 'gap-48',
}

export const spaceX: Record<BoxSpacing, string> = {
  px: 'gap-x-px',
  0: 'gap-x-0',
  0.5: 'gap-x-0.5',
  1: 'gap-x-1',
  1.5: 'gap-x-1.5',
  2: 'gap-x-2',
  2.5: 'gap-x-2.5',
  3: 'gap-x-3',
  3.5: 'gap-x-3.5',
  4: 'gap-x-4',
  5: 'gap-x-5',
  6: 'gap-x-6',
  7: 'gap-x-7',
  8: 'gap-x-8',
  9: 'gap-x-9',
  10: 'gap-x-10',
  11: 'gap-x-11',
  12: 'gap-x-12',
  14: 'gap-x-14',
  16: 'gap-x-16',
  24: 'gap-x-24',
  32: 'gap-x-32',
  48: 'gap-x-48',
}

export const spaceY: Record<BoxSpacing, string> = {
  px: 'gap-y-px',
  0: 'gap-y-0',
  0.5: 'gap-y-0.5',
  1: 'gap-y-1',
  1.5: 'gap-y-1.5',
  2: 'gap-y-2',
  2.5: 'gap-y-2.5',
  3: 'gap-y-3',
  3.5: 'gap-y-3.5',
  4: 'gap-y-4',
  5: 'gap-y-5',
  6: 'gap-y-6',
  7: 'gap-y-7',
  8: 'gap-y-8',
  9: 'gap-y-9',
  10: 'gap-y-10',
  11: 'gap-y-11',
  12: 'gap-y-12',
  14: 'gap-y-14',
  16: 'gap-y-16',
  24: 'gap-y-24',
  32: 'gap-y-32',
  48: 'gap-y-48',
}

export const p: Record<BoxSpacing, string> = {
  px: 'p-px',
  0: 'p-0',
  0.5: 'p-0.5',
  1: 'p-1',
  1.5: 'p-1.5',
  2: 'p-2',
  2.5: 'p-2.5',
  3: 'p-3',
  3.5: 'p-3.5',
  4: 'p-4',
  5: 'p-5',
  6: 'p-6',
  7: 'p-7',
  8: 'p-8',
  9: 'p-9',
  10: 'p-10',
  11: 'p-11',
  12: 'p-12',
  14: 'p-14',
  16: 'p-16',
  24: 'p-24',
  32: 'p-32',
  48: 'p-48',
}

export const px: Record<BoxSpacing, string> = {
  px: 'px-px',
  0: 'px-0',
  0.5: 'px-0.5',
  1: 'px-1',
  1.5: 'px-1.5',
  2: 'px-2',
  2.5: 'px-2.5',
  3: 'px-3',
  3.5: 'px-3.5',
  4: 'px-4',
  5: 'px-5',
  6: 'px-6',
  7: 'px-7',
  8: 'px-8',
  9: 'px-9',
  10: 'px-10',
  11: 'px-11',
  12: 'px-12',
  14: 'px-14',
  16: 'px-16',
  24: 'px-24',
  32: 'px-32',
  48: 'px-48',
}

export const py: Record<BoxSpacing, string> = {
  px: 'py-px',
  0: 'py-0',
  0.5: 'py-0.5',
  1: 'py-1',
  1.5: 'py-1.5',
  2: 'py-2',
  2.5: 'py-2.5',
  3: 'py-3',
  3.5: 'py-3.5',
  4: 'py-4',
  5: 'py-5',
  6: 'py-6',
  7: 'py-7',
  8: 'py-8',
  9: 'py-9',
  10: 'py-10',
  11: 'py-11',
  12: 'py-12',
  14: 'py-14',
  16: 'py-16',
  24: 'py-24',
  32: 'py-32',
  48: 'py-48',
}

export const pt: Record<BoxSpacing, string> = {
  px: 'pt-px',
  0: 'pt-0',
  0.5: 'pt-0.5',
  1: 'pt-1',
  1.5: 'pt-1.5',
  2: 'pt-2',
  2.5: 'pt-2.5',
  3: 'pt-3',
  3.5: 'pt-3.5',
  4: 'pt-4',
  5: 'pt-5',
  6: 'pt-6',
  7: 'pt-7',
  8: 'pt-8',
  9: 'pt-9',
  10: 'pt-10',
  11: 'pt-11',
  12: 'pt-12',
  14: 'pt-14',
  16: 'pt-16',
  24: 'pt-24',
  32: 'pt-32',
  48: 'pt-48',
}

export const pr: Record<BoxSpacing, string> = {
  px: 'pr-px',
  0: 'pr-0',
  0.5: 'pr-0.5',
  1: 'pr-1',
  1.5: 'pr-1.5',
  2: 'pr-2',
  2.5: 'pr-2.5',
  3: 'pr-3',
  3.5: 'pr-3.5',
  4: 'pr-4',
  5: 'pr-5',
  6: 'pr-6',
  7: 'pr-7',
  8: 'pr-8',
  9: 'pr-9',
  10: 'pr-10',
  11: 'pr-11',
  12: 'pr-12',
  14: 'pr-14',
  16: 'pr-16',
  24: 'pr-24',
  32: 'pr-32',
  48: 'pr-48',
}

export const pb: Record<BoxSpacing, string> = {
  px: 'pb-px',
  0: 'pb-0',
  0.5: 'pb-0.5',
  1: 'pb-1',
  1.5: 'pb-1.5',
  2: 'pb-2',
  2.5: 'pb-2.5',
  3: 'pb-3',
  3.5: 'pb-3.5',
  4: 'pb-4',
  5: 'pb-5',
  6: 'pb-6',
  7: 'pb-7',
  8: 'pb-8',
  9: 'pb-9',
  10: 'pb-10',
  11: 'pb-11',
  12: 'pb-12',
  14: 'pb-14',
  16: 'pb-16',
  24: 'pb-24',
  32: 'pb-32',
  48: 'pb-48',
}

export const pl: Record<BoxSpacing, string> = {
  px: 'pl-px',
  0: 'pl-0',
  0.5: 'pl-0.5',
  1: 'pl-1',
  1.5: 'pl-1.5',
  2: 'pl-2',
  2.5: 'pl-2.5',
  3: 'pl-3',
  3.5: 'pl-3.5',
  4: 'pl-4',
  5: 'pl-5',
  6: 'pl-6',
  7: 'pl-7',
  8: 'pl-8',
  9: 'pl-9',
  10: 'pl-10',
  11: 'pl-11',
  12: 'pl-12',
  14: 'pl-14',
  16: 'pl-16',
  24: 'pl-24',
  32: 'pl-32',
  48: 'pl-48',
}

export const m: Record<BoxSpacingAuto, string> = {
  auto: 'm-auto',
  px: 'm-px',
  0: 'm-0',
  0.5: 'm-0.5',
  1: 'm-1',
  1.5: 'm-1.5',
  2: 'm-2',
  2.5: 'm-2.5',
  3: 'm-3',
  3.5: 'm-3.5',
  4: 'm-4',
  5: 'm-5',
  6: 'm-6',
  7: 'm-7',
  8: 'm-8',
  9: 'm-9',
  10: 'm-10',
  11: 'm-11',
  12: 'm-12',
  14: 'm-14',
  16: 'm-16',
  24: 'm-24',
  32: 'm-32',
  48: 'm-48',
}

export const mx: Record<BoxSpacingAuto, string> = {
  auto: 'mx-auto',
  px: 'mx-px',
  0: 'mx-0',
  0.5: 'mx-0.5',
  1: 'mx-1',
  1.5: 'mx-1.5',
  2: 'mx-2',
  2.5: 'mx-2.5',
  3: 'mx-3',
  3.5: 'mx-3.5',
  4: 'mx-4',
  5: 'mx-5',
  6: 'mx-6',
  7: 'mx-7',
  8: 'mx-8',
  9: 'mx-9',
  10: 'mx-10',
  11: 'mx-11',
  12: 'mx-12',
  14: 'mx-14',
  16: 'mx-16',
  24: 'mx-24',
  32: 'mx-32',
  48: 'mx-48',
}

export const my: Record<BoxSpacingAuto, string> = {
  auto: 'my-auto',
  px: 'my-px',
  0: 'my-0',
  0.5: 'my-0.5',
  1: 'my-1',
  1.5: 'my-1.5',
  2: 'my-2',
  2.5: 'my-2.5',
  3: 'my-3',
  3.5: 'my-3.5',
  4: 'my-4',
  5: 'my-5',
  6: 'my-6',
  7: 'my-7',
  8: 'my-8',
  9: 'my-9',
  10: 'my-10',
  11: 'my-11',
  12: 'my-12',
  14: 'my-14',
  16: 'my-16',
  24: 'my-24',
  32: 'my-32',
  48: 'my-48',
}

export const mt: Record<BoxSpacingAuto, string> = {
  auto: 'mt-auto',
  px: 'mt-px',
  0: 'mt-0',
  0.5: 'mt-0.5',
  1: 'mt-1',
  1.5: 'mt-1.5',
  2: 'mt-2',
  2.5: 'mt-2.5',
  3: 'mt-3',
  3.5: 'mt-3.5',
  4: 'mt-4',
  5: 'mt-5',
  6: 'mt-6',
  7: 'mt-7',
  8: 'mt-8',
  9: 'mt-9',
  10: 'mt-10',
  11: 'mt-11',
  12: 'mt-12',
  14: 'mt-14',
  16: 'mt-16',
  24: 'mt-24',
  32: 'mt-32',
  48: 'mt-48',
}

export const mr: Record<BoxSpacingAuto, string> = {
  auto: 'mr-auto',
  px: 'mr-px',
  0: 'mr-0',
  0.5: 'mr-0.5',
  1: 'mr-1',
  1.5: 'mr-1.5',
  2: 'mr-2',
  2.5: 'mr-2.5',
  3: 'mr-3',
  3.5: 'mr-3.5',
  4: 'mr-4',
  5: 'mr-5',
  6: 'mr-6',
  7: 'mr-7',
  8: 'mr-8',
  9: 'mr-9',
  10: 'mr-10',
  11: 'mr-11',
  12: 'mr-12',
  14: 'mr-14',
  16: 'mr-16',
  24: 'mr-24',
  32: 'mr-32',
  48: 'mr-48',
}

export const mb: Record<BoxSpacingAuto, string> = {
  auto: 'mb-auto',
  px: 'mb-px',
  0: 'mb-0',
  0.5: 'mb-0.5',
  1: 'mb-1',
  1.5: 'mb-1.5',
  2: 'mb-2',
  2.5: 'mb-2.5',
  3: 'mb-3',
  3.5: 'mb-3.5',
  4: 'mb-4',
  5: 'mb-5',
  6: 'mb-6',
  7: 'mb-7',
  8: 'mb-8',
  9: 'mb-9',
  10: 'mb-10',
  11: 'mb-11',
  12: 'mb-12',
  14: 'mb-14',
  16: 'mb-16',
  24: 'mb-24',
  32: 'mb-32',
  48: 'mb-48',
}

export const ml: Record<BoxSpacingAuto, string> = {
  auto: 'ml-auto',
  px: 'ml-px',
  0: 'ml-0',
  0.5: 'ml-0.5',
  1: 'ml-1',
  1.5: 'ml-1.5',
  2: 'ml-2',
  2.5: 'ml-2.5',
  3: 'ml-3',
  3.5: 'ml-3.5',
  4: 'ml-4',
  5: 'ml-5',
  6: 'ml-6',
  7: 'ml-7',
  8: 'ml-8',
  9: 'ml-9',
  10: 'ml-10',
  11: 'ml-11',
  12: 'ml-12',
  14: 'ml-14',
  16: 'ml-16',
  24: 'ml-24',
  32: 'ml-32',
  48: 'ml-48',
}
