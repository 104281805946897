import { useTemplateRefsArray } from '@lasso/shared/hooks'
import { Ref, onBeforeUnmount, onMounted, toValue, watch } from 'vue'
import { RefOrGetter } from '@lasso/shared/types'

import { VirtualTableColumn, VirtualTableColumnWidths } from './types'

export const useColumnsWidth = <T extends Record<string, unknown>>({ columns, columnWidths }: {
  columns: RefOrGetter<Array<Required<VirtualTableColumn<T>>>>
  columnWidths: Ref<VirtualTableColumnWidths<T>>
}) => {
  const { refs: headRefs, setRefByIndex } = useTemplateRefsArray<Element>()

  const observer = new ResizeObserver((mutations) => {
    mutations.forEach((mutation) => {
      const index = headRefs.indexOf(mutation.target)

      if (index === -1) {
        return
      }

      const width = mutation.borderBoxSize[0]?.inlineSize
      const column = toValue(columns)[index]

      if (!column || !column.resizable || width === undefined) {
        return
      }

      const actualWidth = Math.max(column.width, Math.round(width))

      if (columnWidths.value[column.id] !== actualWidth) {
        columnWidths.value = {
          ...columnWidths.value,
          [column.id]: actualWidth,
        }
      }
    })
  })

  onMounted(() => {
    watch(() => headRefs, (curValue, prevValue) => {
      if (prevValue) {
        prevValue.forEach(elRef => observer.unobserve(elRef))
      }

      curValue.forEach(elRef => observer.observe(elRef))
    }, { immediate: true, deep: true })
  })

  onBeforeUnmount(() => {
    observer.disconnect()
  })

  const resetColumnWidth = (column: Required<VirtualTableColumn<T>>) => {
    const value = { ...columnWidths.value }
    delete value[column.id]
    columnWidths.value = value
  }

  return {
    setRefByIndex,
    resetColumnWidth,
  }
}
