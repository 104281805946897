import { DateTime } from 'luxon'

import { DateFormatTypes } from '../DatePicker/types'

export const getHour = (date: DateTime | null) => {
  return date?.toFormat('HH') || '00'
}

export const getMinute = (date: DateTime | null) => {
  return date?.toFormat('mm') || '00'
}

export const isEstDateBeforeToday = (date: DateTime | null) => {
  if (date) {
    return (
      date.startOf('day') < DateTime.local().startOf('day')
    )
  }

  return false
}

export const isInRange = (day: DateTime, minDate?: DateTime, maxDate?: DateTime) => {
  if (minDate && day < minDate.startOf('day')) {
    return false
  }
  if (maxDate && day > maxDate.endOf('day')) {
    return false
  }

  return true
}

export const isLowerBound = (newMonth: DateTime, min: DateTime, dateFormat: DateFormatTypes) => {
  const sameYearAndMonth = newMonth.hasSame(min, 'year') && newMonth.hasSame(min, 'month')
  if (dateFormat === 'MM/dd/yyyy' && sameYearAndMonth) {
    return newMonth.day <= min.day
  }
  else {
    return sameYearAndMonth
  }
}

export const isUpperBound = (newMonth: DateTime, max: DateTime, dateFormat: DateFormatTypes) => {
  const sameYearAndMonth = newMonth.hasSame(max, 'year') && newMonth.hasSame(max, 'month')
  if (dateFormat === 'MM/dd/yyyy' && sameYearAndMonth) {
    return newMonth.day >= max.day
  }
  else {
    return sameYearAndMonth
  }
}
