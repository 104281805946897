<template>
  <Draggable
    :modelValue="items as any[]"
    v-bind="draggableProps"
    :emptyInsertThreshold="emptyInsertThreshold"
    :group="{ name: group, put: onPut }"
    @change="handleChange($event as any)"
  >
    <Component
      :is="itemComponent"
      v-for="(id, index) in items"
      :key="id"
      v-bind="{ ...itemProps, ...getItemProps(id, index as number) }"
      @removeItem="handleRemoveItem(id)"
      @update:item="handleUpdateItem(id, $event)"
      @update:parentItem="handleUpdateItem(itemId, $event)"
    >
      <DraggableNestedBase
        :itemId="id"
        :itemComponent="itemComponent"
        :itemProps="itemProps"
        :group="group"
        :maxDepth="maxDepth"
        :draggableProps="draggableProps"
        :depth="depth + 1"
        :convertItem="convertItem"
        :emptyInsertThreshold="emptyInsertThreshold"
      />
    </Component>
  </Draggable>
</template>

<script setup lang="ts" generic="T extends { items?: T[]; }">
import { computed } from 'vue'

import { NormalizedItem, NormalizedItemAdd, NormalizedItemId } from '@lasso/shared/hooks'

import { Draggable, DraggableChangeEvent, DraggableGroupsOptionsEvent } from '../Draggable'

import { useDraggableNestedState } from './useDraggableNestedState'
import { DraggableNestedBaseProps, DraggableNestedItemProps } from './types'

const props = withDefaults(defineProps<DraggableNestedBaseProps<T>>(), {
  depth: 0,
  maxDepth: Infinity,
})

const {
  rootId,
  getItem,
  getItemParentId,
  updateItemChildren,
  deleteItem,
  addItem,
  moveItem,
  updateItem,
  getMaxDepth,
} = useDraggableNestedState<T>()!

const itemId = computed(() => props.itemId ?? rootId.value!)
const item = computed(() => getItem(itemId.value))
const items = computed(() => (item.value?.items ?? []).filter(id => getItem(id)))
const parentItemId = computed(() => getItemParentId(itemId.value))
const parentItem = computed<NormalizedItem<T> | null>(() => parentItemId.value ? getItem(parentItemId.value) : null)

const getItemProps = (id: NormalizedItemId, index: number): DraggableNestedItemProps<any> => {
  return {
    item: getItem(id)!,
    parentItem: getItem(itemId.value)!,
    index,
    isFirst: index === 0,
    isLast: index === items.value.length - 1,
    depth: props.depth,
  }
}

const handleChange = (event: DraggableChangeEvent<NormalizedItemId>) => {
  if (!item.value) {
    return
  }

  if ('added' in event) {
    const addedItem = event.added.element
    // Handle items added from another list
    if (addedItem && typeof itemId.value !== typeof addedItem && typeof addedItem === 'object') {
      event.added.element = addItem({ ...(addedItem as any), items: [] } as NormalizedItemAdd<T>)
    }

    const convertedItem = props.convertItem?.(item.value)

    // A new parent item was created for the added item
    if (convertedItem && parentItem.value) {
      const index = parentItem.value.items.indexOf(itemId.value)
      const newItemId = addItem(
        { ...(convertedItem as NormalizedItem<T>), items: [event.added.element] },
        parentItemId.value!,
        index,
      )

      moveItem(itemId.value, newItemId, 0)
    }
    // Add item - it was removed from the other list already
    else {
      const items = item.value.items.slice()
      items.splice(event.added.newIndex, 0, event.added.element)

      updateItemChildren(itemId.value, items)
    }
  }
  else if ('removed' in event) {
    const newItems = item.value.items.filter(otherId => otherId !== event.removed.element)

    // Lift the item in place of its parent because it is the last in its parent
    if (newItems.length === 1 && parentItem.value) {
      const newParentItems = parentItem.value.items.map(id => id === itemId.value ? newItems[0]! : id)
      updateItemChildren(parentItemId.value!, newParentItems)
    }
    else {
      updateItemChildren(itemId.value, newItems)
    }
  }
  // Move item in the same array
  else if ('moved' in event) {
    moveItem(event.moved.element, itemId.value, event.moved.newIndex)
  }
}

const handleRemoveItem = (id: NormalizedItemId) => {
  if (!item.value) {
    return
  }

  const updatedItems = item.value.items.filter(otherId => otherId !== id)

  // Delete the whole parent if it doesn't have items
  if (updatedItems.length === 0) {
    deleteItem(itemId.value)
  }
  // Lift the item in place of its parent because it is the last in its parent
  else if (updatedItems.length === 1 && parentItem.value) {
    const newParentItems = parentItem.value.items.map(id => id === itemId.value ? updatedItems[0]! : id)
    updateItemChildren(parentItemId.value!, newParentItems)
  }

  deleteItem(id)
}

const handleUpdateItem = (id: NormalizedItemId, data: Exclude<T, 'items'>) => {
  updateItem(id, data)
}

const onPut = ({ fromItem: fromId }: DraggableGroupsOptionsEvent) => {
  const isFromOwnState = Boolean(getItem(fromId as NormalizedItemId))
  const maxDepth = isFromOwnState ? getMaxDepth(fromId as NormalizedItemId) : 0

  return props.depth <= props.maxDepth - maxDepth
}
</script>
