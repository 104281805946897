<template>
  <div class="avatar" :class="avatarClasses">
    <div :class="avatarItemClasses" :style="avatarStyles">
      <img v-if="src" :src="src" :alt="alt">
      <Typography v-else :color="props.textColor">
        {{ initials }}
      </Typography>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'
import { computed } from 'vue'

import { abbreviate, checkForUndefined } from '../../utils'

import Typography from '../Typography/Typography.vue'

import { TypographyColor } from '../Typography/types'

import { color as textColors } from '../Typography/classes'

import type { AvatarColor, AvatarProps, AvatarSize } from './types'

import { clickableClass, colors, sizes } from './classes'

const props: AvatarProps = defineProps({
  // variant: {
  //   type: String,
  //   default: 'filled',
  //   validator: (val: string): boolean => ['solid', 'outline', 'ghost'].includes(val),
  // },
  textColor: {
    type: String as PropType<TypographyColor>,
    default: 'inherit',
    validator: (color: TypographyColor) => !!textColors[color],
  },
  color: {
    type: String as PropType<AvatarColor>,
    default: 'primary',
    validator: (color: AvatarColor) => !!colors[color],
  },
  customColor: {
    type: String,
    default: null,
  },
  size: {
    type: String as PropType<AvatarSize>,
    default: 'md',
    validator: (size: AvatarSize) => !!sizes[size],
  },
  src: {
    type: String,
    default: '',
  },
  alt: {
    type: String,
    required: true,
  },
  rounded: {
    type: Boolean,
    default: false,
  },
  clickable: {
    type: Boolean,
    default: false,
  },
})

const avatarItemClasses = computed(() => {
  const size = checkForUndefined(props.size, sizes)
  const color = checkForUndefined(props.color, colors)
  const rounded = props.rounded ? 'rounded-full' : 'rounded'

  return {
    [color]: !props.src && !props.customColor && props.color,
    'ring-1': props.customColor,
    [size]: props.size,
    [rounded]: true,
  }
})

const avatarClasses = computed(() => {
  const rounded = props.rounded ? 'rounded-full' : 'rounded'

  return {
    [rounded]: true,
    placeholder: !props.src,
    [clickableClass]: props.clickable,
  }
})

const avatarStyles = computed(() => {
  return {
    '--tw-ring-color': props.customColor,
  }
})

const initials = computed(() => abbreviate(props.alt))
</script>
